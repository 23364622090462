import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import DailyLoginRewards from "../../Profile/DailyLoginRewards";
import { useProfileDataContext } from "../ProfileContext";

const DailyLoginRewardModal = ({ showDLR, setShowDLR }) => {
  const [show, setShow] = useState(showDLR);

  const handleClose = () => {
    setShowDLR(false);
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="auth-modal modal fade "
      centered
    >
      <Modal.Body style={{minHeight:'31.25rem'}}>
        <button
          type="button"
          className="btn-close"
          onClick={handleClose}
          aria-label="Close"
        ></button>

        <div className="row justify-content-center">
          <div class="col-9 border-gray-50">
            <DailyLoginRewards handleClose={handleClose} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DailyLoginRewardModal;
