import React, { useState, useEffect } from "react";

import Sad from "../../../assets/images/sad-icon-svg.svg";
import NotGood from "../../../assets/images/notGood-icon-svg.svg";
import Ok from "../../../assets/images/ok-icon-svg.svg";
import Happy from "../../../assets/images/happy-icon-svg.svg";
import VeryHappy from "../../../assets/images/veryHappy-icon-svg.svg";

import Friends from "../../../assets/images/shareFriends-svg.svg";
import UserName from "../../../assets/new-images/gamer.svg";
import Kills from "../../../assets/new-images/gun.svg";
import Points from "../../../assets/new-images/list.svg";
import Rank from "../../../assets/new-images/podium.svg";
import ArrowCircle from "../../../assets/images/arrowCircle-svg.svg";
import Tournament from "../../../assets/images/tournament-solo-img.jpg";
import TournamentStructure from "./TournamentStructure";
import WinningBreakUp from "./WinningBreakUp";
import { func } from "../../../Utilities/logFunc";
import QualificationModal from "./QualificationModal";
import RulesModal from "./RulesModal";
import DownArrow from "../../../assets/images/downArrow-svg.svg";
import Champion from "../../../assets/images/championIcon.png";
import Master from "../../../assets/images/GoldPremium.png";
import {
  gamerjiTournamentGet,
  gamerjiTournamentLeaderBoard,
  addContestRate,
  getContestRate,
  gamerjiTournamentGetSummery,
  gamerjiTournamentRoundMatch,
  gamerjiTournamentRules,
} from "../../../Utilities/controller";
import { Link, useParams } from "react-router-dom";
import { url } from "../../../Utilities/url";
import moment from "moment";
import { toast } from "react-toastify";
import { errors } from "../../../Utilities/error";
import CopyIcon from "../../../assets/images/copy-icon-svg.svg";
import FriendsShare from "../PrivateContest/FriendsShare";
import { Accordion } from "react-bootstrap";
import { useProfileDataContext } from "../ProfileContext";
import { useTranslation } from "react-i18next";
import OtherUserProfileModal from "../../Profile/OtherUserProfile";

import { eventTracking } from "../../../firebaseAnalytics";
import { events } from "../../../Utilities/appEvents";


const TournamentDetails = () => {
  const { t } = useTranslation();
  const [showWinningModal, setShowWinningModal] = useState(false);
  const [showQualificationModal, setShowQualificationModal] = useState(false);
  const [showRulesModal, setShowRulesModal] = useState(false);
  const [tournaments, setTournament] = useState(null);
  const [tournamentShortInfo, setTournamentShortInfo] = useState(null);
  const [tournamentRulesResponse, setTournamentRulesResponse] = useState(null);
  const [captainData, setCaptainData] = useState(null);
  const [captainDataPlayer, setCaptainDataPlayer] = useState(null);
  const [tournamentLeaderBoard, setTournamentLeaderBoard] = useState([]);
  const [tournamentLeaderBoardCount, setTournamentLeaderBoardCount] = useState(0);
  const [contests, setContests] = useState([]);
  const [eventRate, setEventRate] = useState(null);
  const [qualificationData, setQualificationData] = useState(null);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const { profileData, updateProfileData,premiumUser } = useProfileDataContext();
  const [gameType, setGameType] = useState('')
  const [index, setIndex] = useState(false);
  let { id } = useParams();
  // console.log(localStorage.getItem("premiumUser"), localStorage.getItem("premiumUser") !== undefined || localStorage.getItem("premiumUser") !== 'undefined');
  // const premiumUser = (localStorage.getItem("premiumUser") !== undefined && localStorage.getItem("premiumUser") !== 'undefined')? JSON.parse(localStorage.getItem("premiumUser")) : false;'
  const [otherProfileModal, setOtherProfileModal] = useState(false);
  const [otherProfileUserId, setOtherProfileUserId] = useState(null);
  const [loader, setLoader] = useState(false);
  const [pages, setPages] = useState(1);
  const [limit, setLimit] = useState(10);

  const handlerWinning = (data) => {
    func("clicked");
    console.log("data",data);
    //setSelectedTournament(data);
    //updated response object
    setSelectedTournament(tournamentShortInfo);
    
    eventTracking(events.Tournamnet_Winners, {
      GJ_GameName: localStorage.getItem("activeGame"),
      GJ_GameID: localStorage.getItem("activeGameId"),
      GJ_ContestID: data._id,
      GJ_GameTypeID:data.gameTypeAry._id,
    });
    setShowWinningModal((prev) => true);
  };
  const handlerQualification = (data) => {
    func("clicked");
    setQualificationData(data);
    setShowQualificationModal((prev) => true);
  };
  const handlerRules = (data) => {
    func("clicked");
    setSelectedTournament(data);
    setShowRulesModal((prev) => true);
  };
  const getTournamentSummery=async ()=>{
    try{ 
      let data = {
        filter: {
          user: profileData?.item?.user_id,
        },
      };
      const res = await gamerjiTournamentGetSummery(data, id);
      if (res?.data?.item) {
        setTournamentShortInfo(res?.data?.item);
        console.log(res?.data?.contests)
      }
      func(res);
    }catch(e){

    }
  }

  const getTournamentMatches=async ()=>{
    try{ 
      let data = {
        user: profileData?.item?.user_id,
        event: id,
      };
      const res = await gamerjiTournamentRoundMatch(data);
      if (res?.data?.contests) {
        setContests(res?.data?.contests)
        setCaptainData(res?.data?.contests?.[0])
      }
      func(res);
    }catch(e){

    }
  }

  const getTournament = async () => {
    try {
      let data = {
        filter: {
          // tournamentType: "gamerji",
          user: profileData?.item?.user_id,
        },
      };

      const res = await gamerjiTournamentGet(data, id);
      // console.log("res?.data", res?.data);
      if (res?.data?.item) {
        setTournament(res?.data?.item);
        // setContests(res?.data?.contests);
        // console.log(res?.data?.contests)
        // setCaptainData(res?.data?.contests?.[0]?.contestUserItem)
      }
      func(res);
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };

  const getTournamentLeaderBoard = async (pages) => {
    try {
      let data = {
        skip: limit * (pages - 1),
        limit: limit,
        event: id,
        user: profileData?.item?.user_id,
        sortBy: "createdAt",
        sort: "desc",
      };

      const res = await gamerjiTournamentLeaderBoard(data);
      // setTournamentLeaderBoard(res?.data?.list);
      setTournamentLeaderBoard([...tournamentLeaderBoard, ...res?.data?.list]);
      setCaptainDataPlayer(res?.data?.captainData)
      setTournamentLeaderBoardCount(res?.data?.count)
      // setCaptainData(res?.data?.captainData);
      func(res);
      setLoader(false);
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
      setLoader(false);
    }
  };

  const getLoadMore = async () => {
    setLoader(true);
    setPages(pages + 1)
    getTournamentLeaderBoard(pages + 1)
  };

  const getTournamentRate = async () => {
    try {
      let data = {
        contest: id,
      };

      const res = await getContestRate(data, id);
      setEventRate(res?.data?.data);
      func(res);
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };
  const getTournamentRules=async ()=>{
    try{ 
      if(tournamentRulesResponse?.rules){
        return;
      }
      let data = {  };
      const res = await gamerjiTournamentRules(data, id);
      if (res?.data?.success) {
        setTournamentRulesResponse(res?.data);
      }
      func(res);
    }catch(e){

    }
  }

  useEffect(() => {
    //getTournament();
    getTournamentSummery();
    getTournamentMatches();
    getTournamentRules();
    getTournamentLeaderBoard(pages);
    getTournamentRate();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const remainingPlayers = (tournaments) => {
    return tournaments?.totalPlayers - tournaments?.totalJoinedPlayers;
  };
  const findPercentage = (data) => {
    let totalPlayer = data?.totalPlayers || 0;
    let totalPlayerJoined = data?.totalJoinedPlayers || 0;

    let percentage = (totalPlayerJoined / totalPlayer) * 100;

    return percentage + "%";
  };

  const rating = async (rate) => {
    try {
      let data = {
        rate: rate,
        contest: id,
      };
      eventTracking(events.Tournament_Ratings, {
        GJ_GameName: tournamentShortInfo.gameAry.name, GJ_GameID: tournamentShortInfo.gameAry._id, GJ_TournamentID: tournamentShortInfo._id
      });
      const res = await addContestRate(data);
      toast.success(t('success_rating_successfully'))
      getTournamentRate();
      // console.log("res?.data", res?.data);
      // if (res?.data?.item) {
      //   setTournament(res?.data?.item);
      //   setContests(res?.data?.contests);
      // }
      func(res);
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };

  let getRoundStatus = (contest, ind) => {
    
    if (contest?.contest.isFinalRound) {
      return undefined;
    }
    if (contest?.contest.status == "waiting") {
      return t("contest_details.pending");
    }
    if (contest?.contest.status != "completed") {
      return t("contest_details.pending");
    }
    if (contest?.rank > 0 && (parseInt(contest?.contest?.winnerCount || 0) >= parseInt(contest?.rank || 0)) && !contest?.isHacking) {
      return t("contest_details.qualified");
    }
    return t("contest_details.notQualified");
  };
  let getRoundStatusDesc = (contest, ind) => {
    console.log("*************",getRoundStatus(contest, ind))
    if (contest?.contest?.isFinalRound) {
      return undefined;
    }
    // if (getRoundStatus(contest, ind) == t("contest_details.pending")) {
    //   return t("contest_details.pending");
    // }
    // if (getRoundStatus(contest, ind) == t("contest_details.qualified")) {
    //   return t("contest_details.qualified_for_next_round");
    // }
    if (getRoundStatus(contest, index) == "Pending") {
      return t('contest_details.pending');
    }
    if (getRoundStatus(contest, index) == "Qualified") {
      return t('contest_details.qualified_for_next_round');
    }
    return t("contest_details.sorry_you_are_not_qualified");
  };

  return (
    <>
      <div class="content">
        <div className="content-inner">
          <div class="row gy-4">
            <div class="col-lg-6">
              <div class="title">
                <h3 class="bodyBoldFont mb-3">
                  {t("contest_details.tournament_details")}
                </h3>
              </div>
              <div className="card tournametParticipationCard">
                <div className="card-header ">
                  <h4 className="card-title mb-0">
                    {tournamentShortInfo?.title.length > 50 ? (
                      <marquee>{tournamentShortInfo?.title}</marquee>
                    ) : (
                      tournamentShortInfo?.title
                    )}
                  </h4>
                 
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-4">
                      <div
                        className="card-img"
                        style={{ width: "100%", height: "100%" }}
                      >
                        <img
                          style={{ height: "100%" }}
                          src={
                            url.imageUrl + tournamentShortInfo?.featuredImage?.default
                          }
                          alt=""
                          className="img-fluid rounded-1"
                        />
                        <div className="img-title text-uppercase bodyHeavyFont w-100 text-center h4 mb-0">
                          {tournamentShortInfo?.gameTypeAry?.name}
                        </div>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="card-content">
                        <div className="row justify-content-between py-2 ps-3">
                          <div className="col-4">
                            <label for="" className="d-block h5">
                              {t("contest_details.date")}
                            </label>
                            <h5 className="bodyBoldFont mb-0">
                              {moment(tournamentShortInfo?.startDate).format(
                                "DD/MM/YYYY"
                              )}
                            </h5>
                          </div>
                          <div className="col-4 dividersX">
                            <label for="" className="d-block h5">
                              {t("contest_details.rounds")}
                            </label>
                            <h5 className="bodyBoldFont mb-0">
                              {tournamentShortInfo?.rounds}
                            </h5>
                          </div>
                          <div className="col-4">
                            <label for="" className="d-block h5">
                              {t("contest_details.id")}
                            </label>
                            <h5
                              className="bodyBoldFont mb-0"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  tournamentShortInfo?.shortCode
                                );
                                toast.info(t("info_Copied"), {
                                  // position: "top-left",
                                  autoClose: 2000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                });
                              }}
                            >
                              {tournamentShortInfo?.shortCode}
                            </h5>
                          </div>
                        </div>
                        <div className="bg-accent-color-2 color-primary-dark-blue py-2 mt-1">
                          <div className="row">
                            {tournamentShortInfo?.titles[0]?.isSelection && tournamentShortInfo?.titles[0]?.name && tournamentShortInfo?.titles[0]?.value &&
                              <div className={`col-6 ${tournamentShortInfo?.titles?.[1]?.isSelection && tournamentShortInfo?.titles?.[1]?.name && tournamentShortInfo?.titles?.[1]?.value ? "dividersRight" : ""}  ps-5`} >
                                <label
                                  for=""
                                  className="d-block h5 text-ellipsis_contest"
                                >
                                  {/* {tournaments?.titles[0]?.name} */}
                                  {
                                    // tournaments?.titles[0]?.name?.length > 15
                                    //   ? tournaments?.titles[0]?.name?.substring(
                                    //       0,
                                    //       15 - 3
                                    //     ) + "..."
                                    //   :
                                    tournamentShortInfo?.titles[0]?.name
                                  }
                                </label>
                                <h5 className="bodyBoldFont mb-0 text-ellipsis_contest">
                                  {/* {tournaments?.titles[0]?.value} */}
                                  {
                                    // tournaments?.titles[0]?.value?.length > 15
                                    //   ? tournaments?.titles[0]?.value?.substring(
                                    //       0,
                                    //       15 - 3
                                    //     ) + "..."
                                    //   :
                                    tournamentShortInfo?.titles[0]?.value
                                  }
                                </h5>
                              </div>
                            }
                            {tournamentShortInfo?.titles[1]?.isSelection && tournamentShortInfo?.titles[1]?.name && tournamentShortInfo?.titles[1]?.value &&
                              <div className="col-6 ps-5">
                                <label
                                  for=""
                                  className="d-block h5 text-ellipsis_contest"
                                >
                                  {/* {tournaments?.titles[1]?.name} */}
                                  {
                                    // tournaments?.titles[1]?.name?.length > 15
                                    //   ? tournaments?.titles[1]?.name?.substring(
                                    //       0,
                                    //       15 - 3
                                    //     ) + "..."
                                    //   :
                                    tournamentShortInfo?.titles[1]?.name
                                  }
                                </label>
                                <h5 className="bodyBoldFont mb-0 text-ellipsis_contest">
                                  {/* {tournaments?.titles[1]?.value} */}
                                  {
                                    // tournaments?.titles[1]?.value?.length > 15
                                    //   ? tournaments?.titles[1]?.value?.substring(
                                    //       0,
                                    //       15 - 3
                                    //     ) + "..."
                                    //   :
                                    tournamentShortInfo?.titles[1]?.value
                                  }
                                </h5>
                              </div>
                            }
                          </div>
                        </div>
                        <div className="row justify-content-between py-2 ps-3 mt-1">
                          <div className="col-4">
                            <label for="" className="d-block h5">
                              {t("tournaments.prize_pool")}
                            </label>
                            <div className="d-flex align-items-center poolPrizeBox">
                              {tournamentShortInfo?.prizePoolType == "rewards" ? (
                                <></>
                              ) : (
                                <span className="icon me-2 img-center">
                                  <img
                                    // style={{ height: "100%", width: "100%" }}
                                    className="icon-18 me-2"
                                    src={
                                      url?.imageUrl +
                                      tournamentShortInfo?.currency?.[0]?.outCurrency
                                        ?.img?.default
                                    }
                                    alt=""
                                  />
                                </span>
                              )}
                              <h5 className="bodyBoldFont mb-0">
                                {/* {console.log(tournaments?.prizePoolType)} */}
                                {tournamentShortInfo?.prizePoolType == "rewards"
                                  ? tournamentShortInfo?.rewardDisplayText
                                  : tournamentShortInfo?.prizePool}
                              </h5>
                            </div>
                          </div>
                          <div
                            className="col-4 dividersX"
                            style={{ cursor: "pointer" }}
                            onClick={() => handlerWinning(tournamentShortInfo)}
                          >
                            <label for="" className="d-block h5">
                              {t("tournaments.winners")}
                            </label>

                            <div className="d-flex align-items-center useCoinBox">
                              <h5 className="bodyBoldFont mb-0 me-2">
                                {tournamentShortInfo?.totalWinners}
                              </h5>
                              {/* <span className="icon me-2">
                              <img src={DownArrow} alt="" />
                            </span>
                             */}
                              <i className="fas fa-chevron-down mx-2 mb-1"></i>
                            </div>
                          </div>
                          <div className="col-4">
                            <label for="" className="d-block h5">
                              {t("tournaments.join_using")}
                            </label>

                            <div
                              className="d-flex align-items-center poolPrizeBox"
                              style={{ cursor: "pointer" }}
                            >
                              {tournamentShortInfo?.entryFee > 0 ? (
                                <span className="icon me-2 img-center">
                                  <img
                                    className="icon-18"
                                    src={
                                      url?.imageUrl +
                                      tournamentShortInfo?.currency?.[0]?.inCurrency
                                        ?.img?.default
                                    }
                                    alt=""
                                  />
                                </span>
                              ) : (
                                ""
                              )}

                              <h5 className="bodyBoldFont mb-0">
                                {tournamentShortInfo?.entryFee > 0
                                  ? tournamentShortInfo?.entryFee
                                  : "Free"}
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-2">
                          <div className="col-12">
                            <div
                              className="progress"
                              role="progressbar"
                              aria-label="Basic example"
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              <div
                                className="progress-bar bg-accent-color-2"
                                style={{ width: findPercentage(tournamentShortInfo) }}
                              ></div>
                            </div>
                            <div className="d-flex justify-content-between mt-2">
                              <h5>
                                {t("tournaments.players_remaining", {
                                  players: `${remainingPlayers(tournamentShortInfo)}`,
                                })}
                              </h5>
                              <h5>
                                {t("tournaments.players_joined", {
                                  players: tournamentShortInfo?.totalJoinedPlayers,
                                })}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {contests.length > 0 && (
                <>
                  <div class="title pt-4 mt-4">
                    <h3 class="bodyBoldFont mb-3">
                      {t("contest_details.tournament_rounds")}
                    </h3>
                  </div>
                  {/* <div class="card tournamentRoundCard mb-4">
              <div class="card-header">
                <h4 class="card-title mb-0">Round 1</h4>
                <a href="#" class="qulifiedLink">
                  Qualified
                  <span class="icon ms-2">
                    <img src={ArrowCircle} alt="" class="icon-24" />
                  </span>
                </a>
              </div>
            </div> */}

                  {/* {contests.map((data, indp) => (
                    <div class="card tournamentRoundCard mb-4 mt-5" key={indp}>
                      <div class="card-header">
                        <h4 class="card-title mb-0">{data?.title}</h4>
                        <Link class="qulifiedLink">
                          {getRoundStatusDesc(data, indp)}
                          <span class="icon ms-2">
                            <img src={ArrowCircle} alt="" class="icon-24" />
                          </span>
                        </Link>
                      </div>
                      <div class="card-body">
                        <div class="row justify-content-between py-2">
                          <div class="col-3 dividersRight">
                            <label for="" class="d-block h6">
                              Date
                            </label>
                            <h6 class="bodyBoldFont mb-0">
                              {moment(data?.date).format("DD/MM/Y")}
                            </h6>
                          </div>
                          <div class="col-3 d-flex flex-column align-items-center justify-content-center">
                            <div>
                              <label for="" class="d-block h6">
                                Time
                              </label>
                              <h6 class="bodyBoldFont mb-0">
                                {moment(data?.time).format("hh:mm A")}
                              </h6>
                            </div>
                          </div>
                          <div class="col-3 dividersX d-flex flex-column align-items-center justify-content-center">
                            {console.log(data)}
                            {data?.status != "completed" ? (
                              <div
                                onClick={() => handlerQualification(data)}
                                style={{ cursor: "pointer" }}
                              >
                                <label for="" class="d-block h6">
                                  Qualification
                                </label>

                                <div className="d-flex align-items-center useCoinBox">
                                  <h6 className="bodyBoldFont mb-0">
                                    {data?.winnerCount}
                                  </h6>

                                  <i className="fas fa-chevron-down mx-2"></i>
                                </div>
                              </div>
                            ) : (
                              <div
                                onClick={() => handlerWinning(tournaments)}
                                style={{ cursor: "pointer" }}
                              >
                                <label for="" className="d-block h5">
                                  Winners
                                </label>

                                <div className="d-flex align-items-center useCoinBox">
                                  <h5 className="bodyBoldFont mb-0 me-2">
                                    {tournaments?.totalWinners}
                                  </h5>

                                  <i className="fas fa-chevron-down mx-2"></i>
                                </div>
                              </div>
                            )}
                          </div>
                          <div class="col-3 d-flex flex-column align-items-center justify-content-center">
                            <div>
                              <label for="" class="d-block h6">
                                ID
                              </label>
                              <h6
                                class="bodyBoldFont mb-0"
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    tournaments?.shortCode
                                  );
                                  toast.info(errors.info_Copied, {
                                    // position: "top-left",
                                    autoClose: 2000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  });
                                }}
                              >
                                {tournaments?.shortCode}
                              </h6>
                            </div>
                          </div>
                        </div>

                        <div class="row mt-2">
                          <div class="col-12">
                            <div
                              class="progress"
                              role="progressbar"
                              aria-label="Basic example"
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              <div
                                class="progress-bar bg-info"
                                style={{ width: findPercentage(data) }}
                              ></div>
                            </div>
                            <div class="d-flex justify-content-between mt-2">
                              <h6>
                                {remainingPlayers(data)} players remaining
                              </h6>
                              <h6>{data?.totalJoinedPlayers} players joined</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer text-white">
                        <div class="bg-secondary-subtle pt-2 pb-1 box">
                          <div class="row justify-content-center py-2 mt-1">
                            <div class="col-4 text-center dividersRight">
                              <label for="" class="d-block h6 mb-0">
                                Room ID
                              </label>
                              <h6 class="bodyBoldFont mb-0">
                                {data?.roomId || "-"}
                                {data?.roomId ? (
                                  <span class="icon ms-2" onclick="copyText();">
                                    <img
                                      src={CopyIcon}
                                      alt=""
                                      className="cursor"
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          data?.roomId
                                        );
                                        toast.info(errors.info_Copied, {
                                          autoClose: 2000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                        });
                                      }}
                                    />
                                  </span>
                                ) : (
                                  ""
                                )}
                              </h6>
                            </div>
                            <div class="col-4 text-center">
                              <label for="" class="d-block h6 mb-0">
                                Password
                              </label>
                              <h6 class="bodyBoldFont mb-0">
                                {data?.roomPassword || "-"}
                                {data?.roomPassword ? (
                                  <span class="icon ms-2" onclick="copyText();">
                                    <img
                                      src={CopyIcon}
                                      alt=""
                                      className="cursor"
                                      onClick={() => {
                                        navigator.clipboard.writeText(
                                          data?.roomPassword
                                        );
                                        toast.info(errors.info_Copied, {
                                          // position: "top-left",
                                          autoClose: 2000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                        });
                                      }}
                                    />
                                  </span>
                                ) : (
                                  ""
                                )}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))} */}

                  {contests.map((data, indp) => (
                    <>
                      <div
                        class="card tournamentRoundCard mb-4 tournament-accordion" style={{ border: 'none' }}
                        key={indp}
                      >
                        <Accordion defaultActiveKey={contests.length - 1}>
                          <Accordion.Item eventKey={indp}>
                            <Accordion.Header>
                              <div
                                class="card-header"
                                style={{ width: "100%" }}
                              >
                                <h4 class="card-title mb-0">{data?.contest?.title}</h4>
                                <span class="qulifiedLink">
                                  {console.log('ITEMFINELA',data)}
                                  {getRoundStatusDesc(data, indp)}
                                  <span class="icon ms-2">
                                    {/* <img src={ArrowCircle} alt="" class="icon-24" /> */}
                                  </span>
                                </span>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div
                                class="card-body pb-5"
                                style={{
                                  backgroundColor: "var(--primary-color-dark-blue)",
                                }}
                              >
                                <div class="row justify-content-between py-2 bg-accent-color-2 color-primary-dark-blue contestWarningDiv">
                                  <div class="col-3 dividersRightBlue dividerBlue">
                                    <label for="" class="d-block h6">
                                      {t("contest_details.date")}
                                    </label>
                                    <h6 class="bodyBoldFont mb-0">
                                      {moment(data?.contest?.date).format("DD/MM/Y")}
                                    </h6>
                                  </div>
                                  <div class="col-3 dividersRightBlue dividerBlue d-flex flex-column align-items-left justify-content-left">
                                    <div>
                                      <label for="" class="d-block h6">
                                        {t("contest_details.time")}
                                      </label>
                                      <h6 class="bodyBoldFont mb-0">
                                        {moment(data?.contest?.time).format("hh:mm A")}
                                      </h6>
                                    </div>
                                  </div>
                                  <div class="col-3 dividersRightBlue dividerBlue d-flex flex-column align-items-left justify-content-left">
                                    {!data?.contest?.isFinalRound  ? (
                                      <div
                                        onClick={() =>
                                          handlerQualification(data?.contest)
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <label for="" class="d-block h6">
                                          {t("contest_details.qualification")}
                                        </label>

                                        <div className="d-flex align-items-center useCoinBox">
                                          <h6 className="bodyBoldFont mb-0">
                                            {data?.contest?.winnerCount}
                                          </h6>

                                          <i className="fas fa-chevron-down mx-2"></i>
                                        </div>
                                      </div>
                                      ) : (
                                      <div
                                        onClick={() =>
                                          handlerWinning(tournamentShortInfo)
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <label for="" className="d-block h6">
                                          {t("tournaments.winners")}
                                        </label>
                                        <div className="d-flex align-items-center useCoinBox">
                                          <h5 className="bodyBoldFont mb-0 me-2">
                                            {tournamentShortInfo?.totalWinners}
                                          </h5>
                                          {/* <span className="icon me-2">
                                          <img src={DownArrow} alt="" />
                                        </span> */}
                                          <i className="fas fa-chevron-down mx-2"></i>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  <div class="col-3 d-flex flex-column align-items-left justify-content-left">
                                    <div>
                                      <label for="" class="d-block h6">
                                        {t("tournaments.id")}
                                      </label>
                                      <h6
                                        class="bodyBoldFont mb-0"
                                        onClick={() => {
                                          navigator.clipboard.writeText(
                                            tournamentShortInfo?.shortCode
                                          );
                                          toast.info(t("info_Copied"), {
                                            // position: "top-left",
                                            autoClose: 2000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            progress: undefined,
                                          });
                                        }}
                                      >
                                        {tournamentShortInfo?.shortCode}
                                      </h6>
                                    </div>
                                  </div>
                                </div>

                                <div class="row mt-2">
                                  <div class="col-12">
                                    <div
                                      class="progress"
                                      role="progressbar"
                                      aria-label="Basic example"
                                      aria-valuenow="25"
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    >
                                      <div
                                        class="progress-bar bg-accent-color-2"
                                        style={{ width: findPercentage(data?.contest) }}
                                      ></div>
                                    </div>
                                    <div class="d-flex justify-content-between mt-2">
                                      <h6>
                                        {t("tournaments.players_remaining", {
                                          players: `${remainingPlayers(data?.contest)}`,
                                        })}
                                      </h6>
                                      <h6>
                                        {t("tournaments.players_joined", {
                                          players: data?.contest?.totalJoinedPlayers,
                                        })}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class="card-footer text-white"
                                style={{ padding: "0 0" }}
                              >
                                <div class="bg-primary-color-red pt-2 pb-1 box">
                                  <div class="row justify-content-center py-2 mt-1 ">
                                    <div class="col-6 text-center dividersRightBlue px-4">
                                      <label for="" class="d-block h6 mb-10">
                                        {t("tournaments.room_id")}
                                      </label>
                                      <h6 class="bodyBoldFont d-block mb-0 align-items-center">
                                        {/* {data?.roomId || "-"} */}
                                        {data?.contest?.roomId?.length > 25 ? (
                                          <marquee>{data?.contest?.roomId}</marquee>
                                        ) : (
                                          data?.contest?.roomId || "-"
                                        )}
                                        {data?.contest?.roomId ? (
                                          <span
                                            class="icon ms-2"
                                            onclick="copyText();"
                                          >
                                            <img
                                              src="/assets/images/copy-icon-svg.svg"
                                              alt=""
                                              className="cursor icon-18"
                                              onClick={() => {
                                                navigator.clipboard.writeText(
                                                  data?.contest?.roomId
                                                );

                                                eventTracking(events.Tournament_Room_ID, {
                                                  GJ_GameName: tournamentShortInfo.gameAry.name,
                                                  GJ_GameID: tournamentShortInfo.gameAry._id,
                                                  GJ_TournamentID: tournamentShortInfo._id,
                                                  GJ_TournamentDate:tournamentShortInfo.startDate, GJ_TournamentTime:tournamentShortInfo.startDate,
                                                  GJ_ContestID:contests?._id
                                                });

                                                toast.info(t("info_Copied"), {
                                                  autoClose: 2000,
                                                  hideProgressBar: false,
                                                  closeOnClick: true,
                                                  pauseOnHover: true,
                                                  draggable: true,
                                                  progress: undefined,
                                                });
                                              }}
                                            />
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </h6>
                                    </div>
                                    <div class="col-6 text-center px-4">
                                      <label for="" class="d-block h6 mb-10">
                                        {t("tournaments.password")}
                                      </label>
                                      <h6 class="bodyBoldFont d-block mb-0 align-items-center">
                                        {/* {data?.roomPassword || "-"} */}
                                        {data?.contest?.roomPassword?.length > 25 ? (
                                          <marquee>{data?.contest?.roomPassword}</marquee>
                                        ) : (
                                          data?.contest?.roomPassword || "-"
                                        )}
                                        {data?.contest?.roomPassword ? (
                                          <span
                                            class="icon ms-2"
                                            onclick="copyText();"
                                          >
                                            <img
                                              src="/assets/images/copy-icon-svg.svg"
                                              alt=""
                                              className="cursor icon-18"
                                              onClick={() => {
                                                navigator.clipboard.writeText(
                                                  data?.contest?.roomPassword
                                                );

                                                eventTracking(events.Tournament_Passsword, {
                                                  GJ_GameName: tournamentShortInfo.gameAry.name,
                                                  GJ_GameID: tournamentShortInfo.gameAry._id,
                                                  GJ_TournamentID: tournamentShortInfo._id,
                                                  GJ_TournamentDate:tournamentShortInfo.startDate, GJ_TournamentTime:tournamentShortInfo.startDate,
                                                  GJ_ContestID:contests?._id
                                                });
                                                toast.info(t("info_Copied"), {
                                                  // position: "top-left",
                                                  autoClose: 2000,
                                                  hideProgressBar: false,
                                                  closeOnClick: true,
                                                  pauseOnHover: true,
                                                  draggable: true,
                                                  progress: undefined,
                                                });
                                              }}
                                            />
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* {console.log(contests[indp])} */}
                              <div class="card userProfileCard mt-5">
                                <div class="card-header px-0">
                                  <div class="row justify-content-between align-items-center">
                                    <div class="col-lg-5 col-4 d-flex">
                                      <div class="text-center ps-lg-5 ps-4">
                                        <span class="icon mb-2 d-block">
                                          <img src={UserName} alt="" />
                                        </span>
                                        <h5 class="bodyNormalFont mb-0 color-primary-dark-blue">
                                          {t("contest_details.gamerji_username")}
                                        </h5>
                                      </div>
                                    </div>
                                    <div class="col-lg-6 col-8">
                                      <div class="d-flex justify-content-between">
                                        <div class="col text-center dividersRightBlue">
                                          <span class="icon mb-2 d-block">
                                            <img src={Kills} alt="" />
                                          </span>
                                          <h5 class="bodyNormalFont mb-0  color-primary-dark-blue">
                                            {t("contest_details.kills")}
                                          </h5>
                                        </div>
                                        <div class="col text-center dividersRightBlue">
                                          <span class="icon mb-2 d-block">
                                            <img src={Rank} alt="" />
                                          </span>
                                          <h5 class="bodyNormalFont mb-0 color-primary-dark-blue">
                                            {t("contest_details.rank")}
                                          </h5>
                                        </div>
                                        <div class="col text-center">
                                          <span class="icon mb-2 d-block">
                                            <img src={Points} alt="" />
                                          </span>
                                          <h5 class="bodyNormalFont mb-0 color-primary-dark-blue">
                                            {t("contest_details.points")}
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* background: var(--primary-color-light) !important; */}
                                <div class="card-body pt-2">
                                  <div class="row justify-content-between align-items-center">
                                    <div class="col-lg-5 col-4">
                                      <div class="d-flex align-items-center ps-lg-5 ps-4" onClick={(e) => {
                                        setOtherProfileUserId({
                                          user_id: data?.user?.user_id,
                                          user: data?.user?.user
                                        })
                                        setOtherProfileModal(true)
                                      }
                                      }>
                                        <span class="icon me-2 d-block">
                                          <img
                                            class="icon-50"
                                            src={
                                              url.imageUrl +
                                              data?.user?.level?.featuredImage
                                                ?.default
                                            }
                                            alt=""
                                          />
                                        </span>

                                        <div
                                          className="d-flex justify-content-between w-100 align-items-center"
                                        // style={{
                                        //   display: "flex",
                                        //   justifyContent: "space-between",
                                        //   width: "100%",
                                        // }}
                                        >
                                          <h5
                                            class="bodyNormalFont mb-0"
                                            style={{
                                              display: "block",
                                              width: "10rem",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                            }}
                                          >
                                            {data?.user?.gamerjiName}
                                          </h5>
                                          <span className="ms-1">
                                            {data?.reward}
                                          </span>
                                          {/* {console.log(captainData?.planName)} */}
                                          {premiumUser ? (
                                            <img
                                              className="icon-premium ms-1"
                                              // style={{
                                              //   width: "30px",
                                              //   height: "30px",
                                              //   marginLeft: "20px",
                                              // }}
                                              src={
                                                localStorage.getItem("planName") ==
                                                  "Champion"
                                                  ? Champion
                                                  : Master
                                              }
                                              alt="Master"
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-lg-6 col-8">
                                      <div class="d-flex justify-content-between">
                                        <div class="col text-center">
                                          <h5 class="bodyNormalFont mb-0">
                                            {data?.kills || "-"}
                                          </h5>
                                        </div>
                                        <div class="col text-center">
                                          <h5 class="bodyNormalFont mb-0">
                                            {data?.rank || "-"}
                                          </h5>
                                        </div>
                                        <div class="col text-center">
                                          <h5 class="bodyNormalFont mb-0">
                                            {data?.totalPoint || "-"}
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </>
                  ))}

                  {/* <div class="card userProfileCard mt-5">
                    <div class="card-header p-0">
                      <div class="row justify-content-between align-items-center">
                        <div class="col-lg-5 col-4 d-flex">
                          <div class="text-center ps-lg-5 ps-4">
                            <span class="icon mb-2 d-block">
                              <img src={UserName} alt="" />
                            </span>
                            <h5 class="bodyNormalFont mb-0">
                              {t("contest_details.mobily_username")}
                            </h5>
                          </div>
                        </div>
                        <div class="col-lg-6 col-8">
                          <div class="d-flex justify-content-between">
                            <div class="col text-center">
                              <span class="icon mb-2 d-block">
                                <img src={Kills} alt="" />
                              </span>
                              <h5 class="bodyNormalFont mb-0">
                                {t("contest_details.kills")}
                              </h5>
                            </div>
                            <div class="col text-center">
                              <span class="icon mb-2 d-block">
                                <img src={Rank} alt="" />
                              </span>
                              <h5 class="bodyNormalFont mb-0">
                                {t("contest_details.rank")}
                              </h5>
                            </div>
                            <div class="col text-center">
                              <span class="icon mb-2 d-block">
                                <img src={Points} alt="" />
                              </span>
                              <h5 class="bodyNormalFont mb-0">
                                {t("contest_details.points")}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row justify-content-between align-items-center">
                        <div class="col-lg-5 col-4">
                          <div class="d-flex align-items-center ps-lg-5 ps-4">
                            <span class="icon me-2 d-block">
                              <img
                                class="icon-50"
                                src={
                                  url.imageUrl +
                                  captainData?.level?.featuredImage?.default
                                }
                                alt=""
                              />
                            </span>
                            {func("premi*********", captainData)}
                            <div
                              className="d-flex justify-content-between w-100 align-items-center"
                              // style={{
                              //   display: "flex",
                              //   justifyContent: "space-between",
                              //   width: "100%",
                              // }}
                            >
                              <h5
                                class="bodyNormalFont mb-0"
                                style={{
                                  display: "block",
                                  width: "150px",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {captainData?.user?.gamerjiName}
                              </h5>
                              <span className="ms-1">
                                {captainData?.reward}
                              </span>
                              {console.log(captainData?.planName)}
                              {premiumUser ? (
                                <img
                                  className="icon-30 ms-3"
                                  // style={{
                                  //   width: "30px",
                                  //   height: "30px",
                                  //   marginLeft: "20px",
                                  // }}
                                  src={
                                    localStorage.getItem("planName") ==
                                    process.env.REACT_APP_BRONZE
                                      ? Casual
                                      : localStorage.getItem("planName") ==
                                        process.env.REACT_APP_SILVER
                                      ? Pro
                                      : localStorage.getItem("planName") ==
                                        process.env.REACT_APP_GOLD
                                      ? Master
                                      : ""
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-8">
                          <div class="d-flex justify-content-between">
                            <div class="col text-center">
                              <h5 class="bodyNormalFont mb-0">
                                {captainData?.kills || "-"}
                              </h5>
                            </div>
                            <div class="col text-center">
                              <h5 class="bodyNormalFont mb-0">
                                {captainData?.rank || "-"}
                              </h5>
                            </div>
                            <div class="col text-center">
                              <h5 class="bodyNormalFont mb-0">
                                {captainData?.points || "-"}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <FriendsShare code={tournamentShortInfo?.shortCode} onEvent={() => {
                    eventTracking(events.Tournament_Share_With_Friends, {
                      GJ_GameName: tournamentShortInfo.gameAry.name, GJ_GameID: tournamentShortInfo.gameAry._id, GJ_TournamentID: tournamentShortInfo._id
                    });
                  }} />
                  <div class="card feedbackCard mt-4">
                    <div class="card-title text-center mb-5">
                      <h4 class="m-0">
                        {eventRate?.rate
                          ? t("contest_details.thanks_for_the_rating")
                          : t("contest_details.rate_gamerji")}
                      </h4>
                    </div>
                    <ul class="d-flex align-items-center justify-content-center list-unstyled m-0">
                      {eventRate?.rate ? (
                        eventRate?.rate === 1 ? (
                          <li class="mx-2">
                            <img className="icon-50" src={Sad} alt="" />
                          </li>
                        ) : (
                          <></>
                        )
                      ) : (
                        <li class="mx-2">
                          <a href="#">
                            <img
                              className="icon-50"
                              src={Sad}
                              alt=""
                              onClick={() => rating(1)}
                            />
                          </a>
                        </li>
                      )}

                      {eventRate?.rate ? (
                        eventRate?.rate === 2 ? (
                          <li class="mx-2">
                            <img className="icon-50" src={NotGood} alt="" />
                          </li>
                        ) : (
                          <></>
                        )
                      ) : (
                        <li class="mx-2">
                          <a href="#">
                            <img
                              className="icon-50"
                              src={NotGood}
                              alt=""
                              onClick={() => rating(2)}
                            />
                          </a>
                        </li>
                      )}

                      {eventRate?.rate ? (
                        eventRate?.rate === 3 ? (
                          <li class="mx-2">
                            <img className="icon-50" src={Ok} alt="" />
                          </li>
                        ) : (
                          <></>
                        )
                      ) : (
                        <li class="mx-2">
                          <a href="#">
                            <img
                              className="icon-50"
                              src={Ok}
                              onClick={() => rating(3)}
                            />
                          </a>
                        </li>
                      )}

                      {eventRate?.rate ? (
                        eventRate?.rate === 4 ? (
                          <li class="mx-2">
                            <img className="icon-50" src={Happy} alt="" />
                          </li>
                        ) : (
                          <></>
                        )
                      ) : (
                        <li class="mx-2">
                          <a href="#">
                            <img
                              className="icon-50"
                              src={Happy}
                              alt=""
                              onClick={() => rating(4)}
                            />
                          </a>
                        </li>
                      )}

                      {eventRate?.rate ? (
                        eventRate?.rate === 5 ? (
                          <li class="mx-2">
                            <img className="icon-50" src={VeryHappy} alt="" />
                          </li>
                        ) : (
                          <></>
                        )
                      ) : (
                        <li class="mx-2">
                          <a href="#">
                            <img
                              className="icon-50"
                              src={VeryHappy}
                              alt=""
                              onClick={() => rating(5)}
                            />
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </>
              )}
            </div>

            <div class="col-lg-6 ps-xxl-5">
              {contests.length > 0 && (
                <>
                  <div class="title">
                    <h3 class="bodyBoldFont mb-3">
                      {t("contest_details.players")}
                    </h3>
                  </div>
                  <div class="card playersCard">
                    <div class="card-header ">
                      <div class="row justify-content-between align-items-center">
                        <div class="col-lg-6 col-4 d-flex">
                          <div class="text-center ps-lg-5 ps-4">
                            <span class="icon mb-2 d-block">
                              <img src={UserName} alt="" />
                            </span>
                            <h5 class="bodyNormalFont mb-0">
                              {t("contest_details.gamerji_username")}
                            </h5>
                          </div>
                        </div>
                        <div class="col-lg-6 col-8">
                          <div class="d-flex justify-content-between">
                            <div class="col text-center dividersRightBlue">
                              <span class="icon mb-2 d-block">
                                <img src={Kills} alt="" />
                              </span>
                              <h5 class="bodyNormalFont mb-0">
                                {t("contest_details.kills")}
                              </h5>
                            </div>
                            <div class="col text-center dividersRightBlue">
                              <span class="icon mb-2 d-block">
                                <img src={Rank} alt="" />
                              </span>
                              <h5 class="bodyNormalFont mb-0">
                                {t("contest_details.rank")}
                              </h5>
                            </div>
                            <div class="col text-center">
                              <span class="icon mb-2 d-block">
                                <img src={Points} alt="" />
                              </span>
                              <h5 class="bodyNormalFont mb-0">
                                {t("contest_details.points")}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card playersDetailCard mt-1 mb-4">
                    <div class="card-body">
                      <ul class="list-unstyled mb-0">

                        <li class="row justify-content-between align-items-center">
                          <div class="col-lg-6 col-4">
                            <div class="d-flex align-items-center ps-4 " 
                            // onClick={(e) => {
                            //   // console.log("captaiin data", captainData)
                            //   setOtherProfileUserId({
                            //     user_id: captainDataPlayer?.user?.user_id,
                            //     user: captainDataPlayer?.user?.user
                            //   })
                            //   setOtherProfileModal(true)
                            // }
                            // }
                            >
                              <span class="icon me-2 d-block">
                                <img
                                  class="icon-50"
                                  src={
                                    url.imageUrl +
                                    captainDataPlayer?.user?.featured_image
                                  }
                                  alt=""
                                />
                              </span>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: 'center',
                                  width: "100%",
                                }}
                              >
                                <h5
                                  class="bodyNormalFont mb-0"
                                  style={{
                                    display: "block",
                                    width: "10rem",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {captainDataPlayer?.user.gamerjiName}
                                </h5>
                                {premiumUser ? (
                                  <img
                                    // style={{
                                    //   width: "30px",
                                    //   height: "30px",
                                    //   marginLeft: "20px",
                                    // }}
                                    className="icon-premium ms-1"
                                    src={
                                      localStorage.getItem("planName") ==
                                        "Champion"
                                        ? Champion
                                        : Master
                                    }
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-8">
                            <div class="d-flex justify-content-between">
                              <div class="col text-center">
                                <h5 class="bodyNormalFont mb-0">
                                  {captainDataPlayer?.kills || "-"}
                                </h5>
                              </div>
                              <div class="col text-center">
                                <h5 class="bodyNormalFont mb-0">
                                  {captainDataPlayer?.rank || "-"}
                                </h5>
                              </div>
                              <div class="col text-center">
                                <h5 class="bodyNormalFont mb-0">
                                  {captainDataPlayer?.points || "-"}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </li>


                        {tournamentLeaderBoard?.length > 0 &&
                          tournamentLeaderBoard.map((data, indp) => (
                            data?.user?.user_id != captainData?.user?.user ?
                              <li
                                class="row justify-content-between align-items-center"
                                key={indp}
                              >
                                <div class="col-lg-6 col-4">
                                  <div class="d-flex align-items-center ps-4" 
                                  // onClick={(e) => {
                                  //   // console.log("datata**********",  data?.user?._id, data?.user?.user_id)
                                  //   setOtherProfileUserId({
                                  //     user_id: data?.user?.user_id,
                                  //     user: data?.user?.user
                                  //   })
                                  //   setOtherProfileModal(true)
                                  // }
                                  // }
                                  >
                                    <span class="icon me-2 d-block">
                                      <img
                                        class="icon-50"
                                        src={
                                          url.imageUrl +
                                          data?.user?.level?.featuredImage
                                            ?.default
                                        }
                                        alt=""
                                      />
                                    </span>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: 'center',
                                        width: "100%",
                                      }}
                                    >
                                      <h5
                                        class="bodyNormalFont mb-0"
                                        style={{
                                          display: "block",
                                          width: "10rem",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                      >
                                        {data?.user?.gamerjiName}
                                      </h5>
                                      {data?.isPremium ? (
                                        // <img className="icon-premium ms-1" src={ data?.planName == "D11_CHAMPION" ? Champion : Master }/>
                                        <img className="icon-premium ms-1" src={Master} alt="Master" />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-6 col-8">
                                  <div class="d-flex justify-content-between">
                                    <div class="col text-center">
                                      <h5 class="bodyNormalFont mb-0">
                                        {data?.kills || "-"}
                                      </h5>
                                    </div>
                                    <div class="col text-center">
                                      <h5 class="bodyNormalFont mb-0">
                                        {data?.rank || "-"}
                                      </h5>
                                    </div>
                                    <div class="col text-center">
                                      <h5 class="bodyNormalFont mb-0">
                                        {data?.points || "-"}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              : ""
                          ))}
                        
                        {limit * pages < tournamentLeaderBoardCount && (
                          <div className="load_more text-center">
                            <button
                              className="btn btn-primary"
                              onClick={() => getLoadMore()}
                              disabled={loader === true ? true : false}
                            >
                              {loader === true ? "Loading ..." : t('tournaments.load_more')}
                            </button>
                          </div>
                        )}
                      </ul>
                    </div>
                  </div>
                </>
              )}
              {tournamentRulesResponse?.rules? <><div class="title">
                <h3 class="bodyBoldFont mb-3">{t("contest_details.rules")}</h3>
              </div>
              <div class="card rulesCard">
                <div class="card-body">
                  <div
                    dangerouslySetInnerHTML={{ __html: tournamentRulesResponse?.rules }}
                  />
                </div>
              </div></>:<></> }
              
            </div>
          </div>
        </div>
      </div>
      {showRulesModal && (
        <RulesModal
          showRulesModal={showRulesModal}
          setShowRulesModal={setShowRulesModal}
          selectedTournament={selectedTournament}
        />
      )}
      {showQualificationModal && (
        <QualificationModal
          showQualificationModal={showQualificationModal}
          setShowQualificationModal={setShowQualificationModal}
          qualificationData={qualificationData}
        />
      )}
      {showWinningModal && (
        <WinningBreakUp
          showWinningModal={showWinningModal}
          setShowWinningModal={setShowWinningModal}
          selectedTournament={selectedTournament}
        />
      )}
      {otherProfileModal && (
        <OtherUserProfileModal otherProfileModal={otherProfileModal} setOtherProfileModal={setOtherProfileModal} otherProfileUserId={otherProfileUserId} />
      )}
    </>
  );
};

export default TournamentDetails;
