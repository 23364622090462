import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { func } from "../../../Utilities/logFunc";
import moment from "moment";
import { Formik, Form, Field } from "formik";

import {
  addContestRate,
  appliedPromoCode,
} from "../../../Utilities/controller";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CoinTransaction from "./CoinTransaction";
import DiamondTransaction from "./DiamondTransaction";

import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import {eventTracking} from "../../../firebaseAnalytics"; 
import {events} from "../../../Utilities/appEvents"; 
const MyTransactionModal = ({ showMTM, setShowMTM }) => {
  const [show, setShow] = useState(showMTM);
  const [type, setType] = useState("coin");
  const { t } = useTranslation();

  const handleClose = () => {
    setShowMTM(false);
    setShow(false);
  };

  const handleType = (data) => {
    setType(data);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="authModal_transaction  modal fade "
      id="authModal_transaction"
      style={{minHeight:'18.75rem'}}
      centered
    >
      <Modal.Body>
        <button
          type="button"
          className="btn-close"
          onClick={handleClose}
          aria-label="Close"
        ></button>
        <div class="row justify-content-center text-black">
          <div class="col-xl-8 col-lg-10 col-12">
            <h3 class="text-center text-dark  mb-4"> {t("coin_store.my_recent_transactions")}</h3>

            <ul
              class="nav nav-pills  d-flex justify-content-between auth-nav"
              id="myTab"
              role="tablist"
            >
              <li class="nav-item" role="presentation" style={{ width: '50%' }}>
                <button
                  style={{ width: '100%' }}
                  class="nav-link active"
                  id="login-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#login-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="login-tab-pane"
                  aria-selected="true"
                  onClick={(e) => {handleType("coin");
                  eventTracking(events.Coin_Transaction);
                  }}
                >
                  {t("coin_store.coin")}
                </button>
              </li>
              <li class="nav-item" role="presentation" style={{ width: '50%' }}>
                <button
                  style={{ width: '100%' }}
                  class="nav-link"
                  id="register-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#register-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="register-tab-pane"
                  aria-selected="false"
                  onClick={(e) => {handleType("diamond");
                  eventTracking(events.Diamond_Transaction);
                  }}
                >
                   {t("coin_store.diamond")}
                </button>
              </li>
            </ul>
            {/* <div class="tab-content auth-content"> */}
            {/* <h3 class="text-center">My Recent Transactions</h3>
              <div class="row memberCard justify-content-center">
                <div class="col-xl-12 col-lg-10 col-12"> */}

            <div class="tab-content auth-content">
              <div
                class="tab-pane fade show active"
                id="login-tab-pane"
                role="tabpanel"
                aria-labelledby="login-tab"
                tabindex="0"
              >
                <CoinTransaction type={type} />
              </div>
              <div
                class="tab-pane fade"
                id="register-tab-pane"
                role="tabpanel"
                aria-labelledby="register-tab"
                tabindex="0"
              >
                <DiamondTransaction type={type} />
              </div>
            </div>
          </div>
        </div>
        {/* </div>
          </div> */}
        {/* </div> */}
      </Modal.Body>
    </Modal>
  );
};

export default MyTransactionModal;
