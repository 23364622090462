import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { func } from "../../../Utilities/logFunc";
import moment from "moment";
import { Formik, Form, Field } from "formik";
import {eventTracking} from "../../../firebaseAnalytics"; 
import {events} from "../../../Utilities/appEvents";
import {
  addContestRate,
  appliedPromoCode,
  getAccountProfile,
  getHeaderApi,
} from "../../../Utilities/controller";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as Yup from "yup";
import { useProfileDataContext } from "../ProfileContext";
import { useTranslation } from "react-i18next";
import Loader from "../../Common/Loader";

const ApplyPromoCodeModel = ({ showAPC, setShowAPC, setShowSPC }) => {
  const [show, setShow] = useState(showAPC);
  const { profileData, updateProfileData } = useProfileDataContext()
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);

  const handleClose = () => {
    setShowAPC(false);
    setShow(false);
  };

  const handleOnSubmit = () => {
    setShowSPC(true);
    setShowAPC(false);
  };

  const [frmInitialValues, setFrmInitialValues] = useState({
    code: "",
  });

  const applyPromoCodeSchema = Yup.object().shape({
    code: Yup.string().min(5).required(t("promo_code_is_require")),
  });

  const getTotalCoin = (wallet) => {
    let index = wallet.findIndex((obj) => obj.currencyData?.code == "D11");

    if (index > -1) {
      return wallet[index].winningAmount ? wallet[index].winningAmount : 0;
    }
    return 0;
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    console.log("data");
    setShowSPC(false);
    setShowAPC(true);
  
    try {
      let data = values;
  
      const res = await appliedPromoCode(data);
      if (res?.data?.success) {
        setShowSPC(true);
        setShowAPC(false);
  
        // Use setTimeout to delay the state update
        setTimeout(() => {
          setLoading(false);
        }, 1000); // Adjust the delay time as needed
  
        const resProfile = await getHeaderApi();
        if (resProfile?.data) {
          updateProfileData(resProfile?.data);
          eventTracking(events.APPLY_PROMO_CODE, { GJ_PromoCodeID: values });
  
          // Uncomment these lines if you want to use localStorage
          // localStorage.setItem("profile", JSON.stringify(resProfile?.data));
          // localStorage.setItem(
          //   "currentD11",
          //   getTotalCoin(resProfile?.item?.wallet)
          // );
        }
  
        // toast.success("Promo code applied successfully.")
      } else {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
  
        toast.error(res?.response?.data?.errors?.[0]?.msg);
      }
  
      func(res);
    } catch (error) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
  
      toast.error(error?.response?.data?.errors?.[0]?.msg);
    }
  };
  

  return (
    <>
      {isLoading    ? <Loader />:<></>}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="auth-modal modal fade "
        centered
      >
        <Modal.Body>
          <button
            type="button"
            className="btn-close"
            onClick={handleClose}
            aria-label="Close"
          ></button>

          {/* <form className="row justify-content-center"> */}
          <Formik
            enableReinitialize={true}
            initialValues={frmInitialValues}
            validationSchema={applyPromoCodeSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({
              // handleSubmit,
              errors,
              values,
              // setFieldValue,
              // validateField,
              // validateForm,
            }) => (
              <Form>
                <div
                  class="col-12 justify-content-center align-items-center"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <h3 class="bodyBoldFont mb-5 text-center text-dark">{t("right_hand_drop_down.apply_promo_code")}</h3>
                  <div className="col-sm-6 col-10 mb-3 text-dark">
                    <label for="inputPromoCode" className="form-label">
                    {t("right_hand_drop_down.promo_code")}
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      id="inputPromoCode"
                      placeholder={t("right_hand_drop_down.enter_promo_code")}
                      name="code"
                      maxLength={10}
                      
                    />
                    {errors.code ? <div className="error">{errors.code}</div> : null}
                  </div>

                  <div className="col-sm-6 col-10">
                    <button
                      className="btn btn-primary btn-arrow"
                      style={{ width: "100%" }}
                      disabled={isLoading}
                    >
                      {t("right_hand_drop_down.submit")}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ApplyPromoCodeModel;
