import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ContestStructure from "./ContestStructure";
import { func } from "../../../Utilities/logFunc";

const JoinModal = ({
  showJoinModal,
  setShowJoinModal,
  handleJoinContest,
  handlerWinning,
  tournaments,
  setShowRulesModal,
  setselected,
  setShowJVC,
}) => {
  const [show, setShow] = useState(showJoinModal);

  const handleClose = () => {
    if(setShowJVC )
    setShowJVC(false);
    setShowJoinModal((prev) => false);
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="winningBreakupModal modal fade"
      centered
    >
      <Modal.Body>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <div class="row justify-content-center">
          <div class="col-11">
            <ContestStructure
              handleJoinContest={() => handleJoinContest(tournaments)}
              handlerWinning={handlerWinning}
              tournaments={tournaments}
              setselected={setselected}
              setShowJoinModal = {setShowJoinModal}
              setShowJVC = {setShowJVC}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default JoinModal;
