export const errors = {
  // Success Strings
  // edit-profile
  success_editprofile_updated_successfully: "Profile successfully updated!",
error_Select_your_Gamerji_Avatar:"Select your D11 Avatar.",
error_select_country_code : 'Please select country code',
  // error
   error_enter_phone: "Please enter a mobile number",
   error_enter_valid_phone: "Please enter a valid mobile number",
   error_enter_code: "Please enter your D11 verification code",
   error_enter_valid_code: "Please enter a valid verification code",
  error_Select_date_of_birth: "Select your date of birth",
  error_Please_add_Gamerji_name: "Please add your D11 name",
  error_Please_enter_a_valid_email_address:
    "Please enter a valid email address",
  error_Please_enter_a_valid_mobile_number:
    "Please enter a valid mobile number",
  error_Please_enter_invite_code: "Please enter an invite code",
error_Add_your_Gamerji_name:"Add your D11 name.",
  // withdrawal
  error_Please_Enter_Valid_Bank_Account_Details:"Please enter valid bank details",
  error_Please_enter_a_valid_withdrawal_amount:
    "Please enter a valid withdrawal amount.",
  error_Minimum_withdrawal_limit: "Minimum withdrawal limit:",
  error_Enter_IFSC_code: "Enter IFSC code",
  error_Enter_bank_name: "Enter bank name",
  error_Account_Number_is_Mismatched: "Account Number is Mismatched",
  error_Please_enter_your_account_number: "Please enter your account number",
  error_Please_enter_a_valid_number_while_confirming_account:
    "Please enter a valid number while confirming account",
  error_Please_Select_withdrawal_method: "Please Select withdrawal method.",
  error_Please_Link_your_UPI_Account: "Please Link your UPI Account.",
  error_Oops_something_went_wrong_please_try_again:
    "Oops something went wrong please try again!",
  error_Please_Link_your_Bank_Account: "Please Link your Bank Account.",
  error_Please_Enter_Maximum_Amount: "Please Enter Maximum Amount ",
  error_Please_Enter_Minimum_Amount_then: "Please Enter Minimum Amount then",
  error_Please_Select_At_leaset_one_Match: "Please select at least one match!",
  error_Please_add_required_player: "Please add required players",
  error_Promo_Code_is_Empty: "Promo Code is empty!",
  error_Please_select_at_least_one_more_game:
    "Please select at least one more game",
  error_Select_atleast_one_game: "Select at least one game",
  error_Please_enter_a_valid_UPI_ID: "Please enter a valid UPI ID",
  error_Enter_UPI_ID: "Enter UPI ID",
  error_Enter_account_holders_name: "Enter account holders name",
  error_Please_Enter_Withdraw_Amount: "Please Enter Withdraw Amount",
  error_Please_Enter_Minimum_Amount: "Please Enter Minimum Amount",
  error_Please_confirm_the_account_number: "Please confirm the account number",
success_Promo_code_applied_successfully:"Promo code applied successfully",
  success_UPI_Details_successfully_Linked: "UPI Details successfully Linked!",
  success_Bank_Account_Details_successfully_Linked:
    "Bank Account Details successfully Linked!",
  success_Withdrawal_request_submitted_successfully:
    "Withdrawal request submitted successfully",
  success_Favourite_Game_updated_successfully:
    "Favourite Game updated successfully",
  success_Payment_successfull: "Payment successful",
  info_Copied: "Copied!",
  error_User_not_found:"User not found!",
  error_Please_enter_the_phone_number:"Please enter the phone number!",
  success_Avatar_buy_successfully: "Avatar acquired!",
  success_rating_successfully: "Rating submitted successfully!",
  success_Redeem_Reward_successfully: "Reward redemption successful!",
  success_Please_enter_Gamerji_username: "Please enter game name",
  //  tournament
  error_Please_ask_user_to_add_game_name:"Please ask the user to add a game name!",
success_Game_Username_updated_successfully:"Game Username updated successfully!",
  error_Oops_This_tournament_has_already_started:
    "Oops! This tournament has already started. Please try to join another tournament.",
   error_enter_other_than_yours: 'These are your details, please enter relevant details apart from yours',
  error_enter_already_added: 'Already added this number',
  error_enter_already_added_email: 'Already added this email',
  error_duo_squad_tournament_in_maintenance: 'Duo/Squad tournaments are currently under maintenance. Stay tuned for updates!',
  error_enter_coupon_code : 'Please enter coupon code',
  error_enter_gamerji_username : 'Please enter D11 username',
  error_agree_terms_and_conditions: 'Please agree to the terms and conditions',
  //scrim
  error_select_game :'Please enter a contest name',
  error_select_match :'Please select a match',
  error_describe_your_issue :'Please tell us more about the problem you are facing',
  error_enter_game_name :'Please enter your in-game name',
  error_enter_handle_id :'Please enter your ID',
  error_enter_invite_code :'Please enter invite code',
  error_enter_search_text :"Please enter what you'd like to search for",
  error_enter_valid_youtube_channel_link :'Please enter a valid YouTube channel link',
  error_you_already_have_submitted_issue :'You have already submitted a complaint',
  error_select_issue :'Please select issue',
  error_enter_valid_card_details :'Please enter valid card details',
  error_selecr_timeslot :'Please select a timeslot',
  error_enter_tournament_is_already_started :'Oops! This tournament has already started. Please try to join another tournament.',
  error_you_dont_have_sufficient_coins_to_purchase_avtar :"Oops! You don't have a sufficient coin balance to purchase this avatar!.",
  error_no_users_found_for_search_name :'No records found',
  error_insufficient_balance_in_your_wallet :'Oops! You have insufficient balance in your wallet for this purchase!',
  error_please_select_game_first :'Please select a game to continue',
  error_please_enter_scrim_name :'Please enter a contest name',
  error_please_select_scrime_type :'Please select a contest type',
  error_please_select_date :'Please select date',
  error_please_select_time :'Please select contest start time',
  error_please_select_title :'Please select',
  error_please_select_perspective :'Please select perspective',
  error_please_enter_player :'Please enter number of players ',
  error_please_enter_winner :'Please enter number of winners',
  error_please_winner_not_more_then_player :'Winners cannot exceed number of players',
  error_special_characters_are_not_allowed :'Special characters are not allowed',
  error_please_game_rules :'Please enter rules',
  error_please_game_rules_min_characters :'Rules must be within 50-2000 characters',
  error_scrim_not_create_for_past_date_time :'Contest can not be created for a previous date or time',
  error_screenshot_upload :'Please upload screenshot',
  error_please_enter_roomID :'Please enter Room ID',
  error_please_enter_password :'Please enter a valid password',
  error_please_enter_rank :'Please enter at least one rank',
  error_please_enter_unique_rank :'Please enter unique ranks',
  error_please_select_futur_time :'Please select a future time',
};