import axios from "axios";
import { url } from "./url";
import Cookies from 'js-cookie'
import * as jose from "jose";

// localStorage.setItem('token','7dXCaHTxirvcmTeR8eahXFiskV859Getdz/OP5uw521gvttyuvDnQ+M2W+AKZRU7+5nUzawbt+1KwBTR9xLodg==')

var header = localStorage.getItem('token') ? {'x-access-token':  localStorage.getItem('token') } : {"user-type": "appUser"};
// header['company-code'] = 'GJ';
// header['language'] = localStorage.getItem("GJLangCode")?.toUpperCase() || Cookies.get("GJLangCode")?.toUpperCase()  ||  "EN"
let countryId = localStorage.getItem('country') ? localStorage.getItem('country') : JSON.parse(localStorage.getItem("profile"))?.country?._id;
let stringWithoutQuotes = countryId?.replace(/"/g, '');
if(stringWithoutQuotes && stringWithoutQuotes != undefined && stringWithoutQuotes != "undefined" && stringWithoutQuotes != null && stringWithoutQuotes != "null" && stringWithoutQuotes != ""){
    // header['country'] = stringWithoutQuotes
}
const headers = {
    "user-type": "appUser",
    'company-code':'GJ',
    'language':localStorage.getItem("GJLangCode")?.toUpperCase() || Cookies.get("GJLangCode")?.toUpperCase()  ||  "EN",
    'country':stringWithoutQuotes
}
const secret = new TextEncoder().encode(process.env.REACT_APP_SECRET_KEY);


const jwt = await new jose.SignJWT(headers)
        .setProtectedHeader({ alg: "HS256" })
        .setExpirationTime(2000)
        .sign(secret);
// header['credentials'] = jwt;

const instance = axios.create({
    baseURL: url.apiUrl,
    timeout: 40000,
    headers: header,
});

instance.interceptors.request.use(function (config) {
    if (!!config.data) {
        for (let key of Object.keys(config?.data)) {
            if (config.data[key] === "")
                config.data[key] = null
        }
    }
    return config;
}, function (error) {
    return error //Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log("response===axi", response);

    if (response.status === 200) {
        return response;
    }
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // console.log("error===axi", error);

    if (error?.response?.status === 401) {
        localStorage.clear();
        window.location.href = '/login';
    }
    return error;
});

export default instance;
