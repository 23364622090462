import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { func } from "../../../Utilities/logFunc";
import { url } from "../../../Utilities/url";
import {
  getHtml5GameCategoryList,
  getHtml5GameList,
} from "../../../Utilities/controller";
import GamePlayed from "../../../assets/images/gamesPlayed-icon-svg.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { kFormatter } from "../../../Utilities/kFormatter";
import GameIcon from '../../../assets/images/game-icon-svg.svg'
import { useTranslation } from "react-i18next";
import { eventTracking } from "../../../firebaseAnalytics";
import { events } from "../../../Utilities/appEvents";
import JoinFreeGamesModal from "./JoinFreeGamesModal";
import FreeGameModal from "./FreeGameModal";
const FreeGames = () => {
  const [showJoinModal, setShowJoinModal] = useState(false);
  const [showGameModal, setShowGameModal] = useState(false);
  const [allowToView, setAllowToViewGame] = useState(false);
  const [joinId, setJoinId] = useState("");

  const [games, setgames] = useState([]);
  const [subGames, setSubGames] = useState([]);
  const [gameName, setGamename] = useState("");
  const [gameId, setGameId] = useState("");
  const [selectedGame, setSelectedGame] = useState("");
  const [selectedSubGame, setSelectedSubGame] = useState("");
  const [index, setIndex] = useState(null);
  const { t } = useTranslation()
  const fetchGames = async () => {
    try {
      let payload = { sortby: "order", sort: "asc" };
      const res = await getHtml5GameCategoryList(payload);
      func("freee games", res?.data);
      setgames(res?.data?.list);
      setSubGames([]);
    } catch (error) {
      func("freee games", error?.response?.data?.errors[0]?.msg);
    }
  };

  const fetchSubGames = async (game) => {
    try {
      let payload = {
        skip: 0,
        limit: 100,
        html5Category: game,
        sortby: "order",
        sort: "asc",
      };
      const res = await getHtml5GameList(payload);
      func("freee games", res?.data);
      setSubGames(res?.data?.list);
    } catch (error) {
      func("freee games", error?.response?.data?.errors[0]?.msg);
    }
  };

  useEffect(() => {
    fetchGames();
  }, []);

  const handleGameSelect = (item) => {
    setGameId(item?._id);
    eventTracking(events.games, {
      // GJ_CategoryName:"",
      // GJ_CategoryID:"",
      GJ_GameName: item?.name,
      GJ_GameID: item?._id
    });
    setAllowToViewGame(false);

    if (!item?.isDirect) {
      setSelectedGame(item);
      fetchSubGames(item?._id);

    } else {
      setSelectedGame(item);
      setSubGames([]);
      setSelectedSubGame("");
    }


  };

  const handleSubGameSelect = (item) => {
    eventTracking(events.game_sub_types, {
      GJ_GameName: selectedGame.name,
      GJ_GameID: selectedGame._id,
      GJ_Game_SubTypeID: item?._id
    });
    setSelectedSubGame(item);
    setAllowToViewGame(false);
    if (!selectedGame?.isDirect) {

      setShowJoinModal(true);
    }

  };

  useEffect(() => {
    if(gameName && !selectedGame?.isDirect && selectedSubGame && allowToView)
    {
      setShowGameModal(true)
    }
    else
    {
      setShowGameModal(false)
    }
  }, [allowToView])

  return (
    <>
      <div class="content">
        <div className="content-inner">

          <div class="row gy-5">
            <div class="col-12">
              <div class="title mb-3">
                <h2 class="h3 d-flex align-items-center">
                  <span class="titleIcon me-3">
                    <img src={GameIcon} alt="" />
                  </span>
                  <span>{t('home.all_games')}</span>
                </h2>
              </div>

              <div className="col-12">
                <Swiper
                  className="dashBoardClubSlider commonSlider"
                  spaceBetween={20}
                  modules={[Navigation, Pagination]}
                  slidesPerView={2}
                  // onSlideChange={() => console.log("slide change")}
                  // onSwiper={(swiper) => console.log(swiper)}
                  navigation
                  breakpoints={{
                    576: {
                      slidesPerView: 3,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                    992: {
                      slidesPerView: 4,
                    },
                    1200: {
                      slidesPerView: 3,
                    },
                    1400: {
                      slidesPerView: 4,
                    },
                  }}
                  pagination={{ clickable: true, el: ".swiper-pagination" }}

                >
                  {games.length > 0 &&
                    games.map((data, indp) => (
                      <SwiperSlide
                        class="swiper-slide"
                        key={indp}
                        onClick={() => {
                          setShowJoinModal(false);
                          setGamename(data?.name);
                          setIndex(indp);
                          handleGameSelect(data);
                        }}
                      >
                        <div
                          class="card gameCard"
                        // onClick={() => clickFreeHandler(data?.title)}
                        >
                          <div class="card-body" style={{ paddingTop: '1rem' }}>
                            <div class="card-title text-center">

                              <h4 class="bodyExtraBoldFont" style={{
                                marginBottom: '1rem', display: 'block', width: '100%', whiteSpace: "nowrap", overflow: "hidden",
                                textOverflow: "ellipsis"
                              }}>{data?.name} </h4>
                            </div>
                            <div class="card-img" style={{ cursor: 'pointer', borderRadius: '10px' }}>
                              <img
                                class="w-100"
                                style={{ borderRadius: '10px' }}
                                src={url.imageUrl + data?.featuredImage?.default}
                                alt={data?.title}
                              />
                            </div>
                            <div class="card-content" style={{ padding: '8px' }}>
                              <div class="d-flex align-items-center">
                                <span class="me-2 card-icon">
                                  <img src={GamePlayed} alt="" />
                                </span>
                                <h4 class="mb-0">
                                  {" "}
                                  {kFormatter(data?.totalJoinedPlayers)} {t('home.played')}
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  <div class="swiper-pagination"></div>
                </Swiper>
              </div>

              {subGames.length > 0 && (
                <>
                  <div class="title mb-3 ">
                    <h2 class="h3 d-flex align-items-center">
                      <span class="titleIcon me-3">
                        <img src={GamePlayed} alt="" />
                      </span>
                      <span className="h3 mb-0">{gameName}</span>
                    </h2>
                  </div>
                  <div className="col-12 mt-3">
                    <Swiper
                      className="dashBoardClubSlider commonSlider"
                      spaceBetween={20}
                      modules={[Navigation, Pagination]}
                      slidesPerView={2}

                      // onSlideChange={() => console.log("slide change")}
                      // onSwiper={(swiper) => console.log(swiper)}
                      navigation
                      breakpoints={{
                        576: {
                          slidesPerView: 3,
                        },
                        768: {
                          slidesPerView: 3,
                        },
                        992: {
                          slidesPerView: 4,
                        },
                        1200: {
                          slidesPerView: 3,
                        },
                        1400: {
                          slidesPerView: 4,
                        },
                      }}
                      pagination={{ clickable: true, el: ".swiper-pagination" }}
                    >
                      {subGames.map((data, indp) => (
                        <SwiperSlide
                          class="swiper-slide"
                          key={indp}
                          onClick={() => handleSubGameSelect(data)}
                        >
                          <div
                            class="card gameCard"
                          // onClick={() => clickFreeHandler(data?.title)}
                          >
                            <div class="card-body" style={{ paddingTop: '1rem' }}>
                              <div class="card-title text-center">
                                {func("lengh", data?.name?.length > 5)}

                                <h4 class="bodyExtraBoldFont" style={{
                                  marginBottom: '1rem', display: 'block', width: '100%', whiteSpace: "nowrap", overflow: "hidden",
                                  textOverflow: "ellipsis"
                                }} >{data?.name}</h4>
                              </div>
                              <div class="card-img" style={{ cursor: 'pointer', borderRadius: '10px' }}>
                                <img
                                  class="w-100"
                                  style={{ borderRadius: '10px' }}

                                  src={url.imageUrl + data?.thumbImage?.default}
                                  alt={data?.title}
                                />
                              </div>
                              <div class="card-content" style={{ padding: '8px' }}>
                                <div class="d-flex align-items-center">
                                  <span class="me-2 card-icon">
                                    <img src={GamePlayed} alt="" />
                                  </span>
                                  <h4 class="mb-0">
                                    {" "}
                                    {kFormatter(data?.totalJoinedPlayers)}  {t('home.played')}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </SwiperSlide>
                      ))}
                      <div class="swiper-pagination"></div>
                    </Swiper>
                  </div>
                </>
              )}
              {showJoinModal ? (
                <JoinFreeGamesModal
                  selectedGame={selectedGame}
                  selectedSubGame={selectedSubGame}
                  showJoinModal={showJoinModal}
                  setShowJoinModal={setShowJoinModal}
                  setAllowToViewGame={setAllowToViewGame}
                  setJoinId={setJoinId}
                />
              ) : <></>}
              {gameName && selectedGame?.isDirect && (
                <div className="mt-5">
                  <div class="title">
                    <h2 class="h3 d-flex align-items-center mt-1">
                      <span class="titleIcon me-3">
                        <img src={GamePlayed} alt="" />
                      </span>
                      <span className="h3 mb-0">{gameName}</span>
                    </h2>
                  </div>
                  <div className="col-12 d11-freeGame-rectangle p-3">
                    <iframe
                      className="h-100 w-100"
                      src={selectedGame?.link}
                    ></iframe>
                  </div>
                </div>
              )}

              {/* {gameName && !selectedGame?.isDirect && selectedSubGame && allowToView && (
                <div className="mt-5">
                  <div class="title mb-3 ">
                    <h2 class="h3 d-flex align-items-center">
                      <span class="titleIcon me-3">
                        <img src="/assets/images/game-icon-svg.svg" alt="" />
                      </span>
                      <span className="h3 mb-0">
                        {selectedSubGame?.name}
                      </span>
                    </h2>
                  </div>

                  <div className="col-12 d11-freeGame-rectangle p-3">
                    <iframe
                      className="h-100 w-100"
                      src={selectedSubGame?.url}
                    ></iframe>
                  </div>
                </div>
              )} */}

              {showGameModal && (
                <FreeGameModal
                  selectedGame={selectedGame}
                  selectedSubGame={selectedSubGame}
                  showGameModal={showGameModal}
                  setShowGameModal={setShowGameModal}
                  joinId={joinId}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FreeGames;
