import React, { useEffect, useState } from "react";
import BaseAuth from "./BaseAuth";
import { useLocation } from "react-router";
import { func } from "../../Utilities/logFunc";
import { errors } from "../../Utilities/error";
import { useNavigate } from "react-router";
import { routes } from "../../Utilities/routesFb";
import { userRequestOTP } from "../../Utilities/controller";
import { useTheme } from "../../ThemeContext";
import { toast } from "react-toastify";
import { logout } from "../../Utilities/logout";
import LoginLandingPage from "../LandingPage/LoginLandingPage";
import { useTranslation } from "react-i18next";

import {eventTracking} from "../../firebaseAnalytics"; 
import {events} from "../../Utilities/appEvents";

const Otp = () => {
  const globalValues = useTheme();
  const location = useLocation();
  const data = location?.state;
  const navigate = useNavigate();

  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [timerCount, setTimer] = useState(30);
  const { t } = useTranslation();
  const [loginData, setLoginData] = useState(null);

  useEffect(() => {
    let loginInfo = localStorage.getItem("loginInfo");
    loginInfo = JSON.parse(loginInfo);
    setLoginData(loginInfo);
  }, []);

  // This function should set the OTP Timer of 30 seconds.
  const setCountDownTimer = () => {
    setTimer(timerCount - 1);
  };

  // This function should show/hide Resend OTP Button Visibility.
  const showHideResendOTP = () => {
    setTimer(30);
  };

  useEffect(() => {
    if (timerCount !== 0) {
      setTimeout(() => {
        setCountDownTimer();
      }, 1000);
    }
  }, [timerCount]);

  const submitHandler = (e) => {
    // setLoading(true)
    e.preventDefault();
    if (!otp || otp?.length == 0) {
      setError(t('error_enter_code'));
    } else if (otp.length != 6) {
      setError(t('error_enter_valid_code'));
    } else {
      func(otp);
      setError("");
      callToOTPVerficationAPI(otp);
    }
  };

  const callToOTPVerficationAPI = async (otp) => {
    let loginInfo = localStorage.getItem("loginInfo");
    loginInfo = JSON.parse(loginInfo);

    if (otp.length >= 6) {
      let payload = {
        type: "validateOTP",
        username: loginInfo?.username,
        otp: otp,
      };
      setLoading(true);
      try {
        const res = await userRequestOTP(payload);
        func("otp response:", res);
        if (res?.data?.success) {
          eventTracking(events.otp_verified_login,{
            GJ_MobileNumber:loginInfo?.username,
            GJ_VerificationCode :otp,
            GJ_CountryCode :loginInfo?.country
          });

           

          setLoading(false);
          globalValues.fetchUserData(res?.data);
          localStorage.setItem('user-id', res?.data?.item?.id);
          localStorage.setItem("token", res?.data?.token);
          localStorage.setItem("country", res?.data?.item?.address?.country);
          localStorage.setItem('isDetailsFilled',res?.data?.item?.isDetailsFilled)
          // localStorage.setItem(
          //   "country",
          //   JSON.stringify(res?.item?.address?.country)
          // );
          // if (res?.data?.item?.isDetailsFilled) window.location.href = "/" + routes.home;
          // console.log(res?.item?.isDetailsFilled)
          if (res?.data?.item?.isDetailsFilled)
            {
              // console.log(localStorage.getItem('code'))
              if (localStorage.getItem('code'))
              {
                window.location.href = "/" + routes.home + `?code=${localStorage.getItem('code')}`;
                
              }
               
                else 
                window.location.href = "/" + routes.home ;
            } 
          else window.location.href = "/avatar";
        }
        else {
          setError(res?.response?.data?.errors?.[0]?.msg)
          setLoading(false)
        }
      } catch (error) {

        setLoading(false);
        if (
          error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_invalid" ||
          error?.response?.data?.errors?.[0]?.code ==
          "middlewares.token_parser.validation.token_expired"
        )
          logout();
        toast.error(error?.response?.data?.errors?.[0]?.msg);
        func("error", error);
        setError(error?.response?.data?.errors?.[0]?.msg);
      }
      // showHideResendOTP();

    }
  };

  // This function should call the Resend OTP API
  const callToResendOTPAPI = async (otp) => {
    let loginInfo = localStorage.getItem("loginInfo");
    loginInfo = JSON.parse(loginInfo);
     
    let payload = {
      type: "otpRequest",

      phoneCode: loginInfo?.phoneCode,
      country: loginInfo?.country,
    };
    if (loginInfo?.username != null && loginInfo?.username != "") {
      payload.username = loginInfo?.username;
    } else {
      payload.email = loginInfo?.email;
    }

    setLoading(true);
    try {
      const res = await userRequestOTP(payload);
      func("otp response:", res);
      eventTracking(events.resend_button_login,{
        GJ_MobileNumber:loginInfo?.phoneCode,
        GJ_CountryCode :loginInfo?.country
      });


      if (res?.data?.success) {
        setTimer(30);
      }
    } catch (error) {
      func("error", error);
      if (
        error?.response?.data?.errors?.[0]?.code ==
        "middlewares.token_parser.validation.token_invalid" ||
        error?.response?.data?.errors?.[0]?.code ==
        "middlewares.token_parser.validation.token_expired"
      )
        logout();
      toast.error(error?.response?.data?.errors?.[0]?.msg);
    }
    setLoading(false);
  };

  return (
    <LoginLandingPage>
      <BaseAuth>
        <div class="row justify-content-center">
          <div class="col-xl-6 col-lg-7 col-9">
            <div class="tab-content auth-content">
              <form class="row justify-content-center d11-codecpro ">
                <h1 class="text-nowrap verification_heading d11-codecpro d11-otp-text text-center lh-1 h2">
                  {t("login.enter_verification_code")}
                </h1>
                <h2
                  class="form-label text-wrap d1-otp-subText d11-codecpro"
                  style={{ marginBottom: "5px" }}
                >
                  {t("login.sent_verification_code")}
                </h2>
                <h1 class="Verification_number text-nowrap d11-codecpro mt-1">
                  {loginData?.phoneCode} - {loginData?.username}
                </h1>
                <h4
                  class="form-label text-nowrap d1-otp-subText d11-codecpro"
                  style={{ marginBottom: "0.5rem", marginTop: "1rem" }}
                >
                  {t("login.verification_code_received")}
                </h4>
                <div
                  class="col-12 justify-content-center align-items-center"
                // style={{ marginTop: "2px" }}
                >
                  <input
                    type="number"
                    class="form-control"
                    id="inputCouponCode"
                    placeholder={t("login.enter_verification_code")}
                    value={otp.slice(0, 6)}
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-","."].includes(e.key) &&
                      e.preventDefault()
                    }
                    maxLength="6"
                    onChange={(e) => { setError(""); setLoading(false); setOtp((prev) => e.target.value) }}
                  />
                  {error && <p className="error" style={{ textAlign: "center" }}>{error}</p>}
                </div>
                <div class="d-grid">
                  {/* {console.log(isLoading)} */}
                  <button
                    class="btn btn-primary btn-arrow mb-3"
                    onClick={(e) => submitHandler(e)}
                    disabled={isLoading}
                  >
                    {t("login.submit")}
                  </button>
                  <div class="links">
                    <h5 className="d11-text-center">
                      <span class="link-offset-2 link-underline link-underline-opacity-0 verification-link d11-otp-resend">
                        {" "}
                        {t("login.didnt_receive_code")}
                      </span>
                    </h5>
                  </div>

                  <div class="links">
                    <h5 className="d11-text-center">
                      {timerCount == 0 ? (
                        <span
                          class="link-offset-2 link-underline link-underline-opacity-0 verification-link"
                          style={{
                            color: "var(--primary-color-red)",
                            cursor: "pointer",
                            textDecoration: 'underline !important'
                          }}
                          onClick={(e) => callToResendOTPAPI(e)}
                        >
                          <b>{t("login.resend")}</b>
                        </span>
                      ) : null}
                    </h5>
                    <h5 className="d11-text-center">
                      {timerCount != 0 ? (
                        <span
                          class="link-offset-2 link-underline link-underline-opacity-0 verification-link d11-otp-resend d11-otp-resend-timer"
                          // onClick={(e) => callToResendOTPAPI(e)}
                        >
                          {t("login.request_one", { n: timerCount })}
                        </span>
                      ) : null}
                    </h5>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </BaseAuth>
    </LoginLandingPage>
  );
};

export default Otp;
