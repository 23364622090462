import React from 'react'
import { useTheme } from '../../ThemeContext'
import Header from '../Header/Header'
import Main from '../Main/Main'
import Login from '../Auth/Login'
import { Route, Routes } from 'react-router'
import { routes } from '../../Utilities/routesFb'
import Otp from '../Auth/Otp'
import Avartar from '../Auth/Avartar'
import  HomePage from '../HomePage/HomePage'
const LoginLandingPage = ({children}) => {
    const globalvalues = useTheme()
    
  return (
    <div className='authModule'>
      <Header />
      <Main />
      {children}
    </div>
  )
}

export default LoginLandingPage