import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import EditCalendarRoundedIcon from '@mui/icons-material/EditCalendarRounded';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { color } from '@mui/system';
import { hover } from '@testing-library/user-event/dist/hover';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';
import { func } from '../../Utilities/logFunc';
import moment from 'moment';

dayjs.extend(utc);
dayjs.extend(timezone);
const StyledButton = styled(IconButton)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  color: 'var(--color-white)',
border:'none',
outline:'none',
"& hover":{
    border:'none'
}
}));


const StyledDay = styled(PickersDay)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  color: 'var(--color-white)',
  backgroundColor:'var(--secondary-color)'
}));

export default function CustomSlots({handlerChange, value, disabled}) {
  func("date 39", value)
  let d = new Date()
  let da = d.setFullYear(d.getFullYear() - 14)
  const timeElapsed = Date.now();
const today = new Date(timeElapsed);
  // console.log( dayjs.utc(value),value)
  let date = moment().subtract(14, "years").format("DD-MM-YYYY")
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}  inputFormat="DD/MM/YYYY">
        <DesktopDatePicker
      maxDate={dayjs.utc(new Date(d.getFullYear(), d.getMonth(), d.getDate()).toISOString())}
        value={value ? dayjs.utc(value) : null}
        disabled = {disabled}
        className='form-control'
          inputFormat="DD/MM/YYYY"
          slots={{
            openPickerIcon: EditCalendarRoundedIcon,
            openPickerButton: StyledButton,
            day: StyledDay,
          }}
          onChange={(e)=> {
            handlerChange(e)}}
          timezone="system"

       
        
          InputProps={{sx: { 
            "& .MuiSvgIcon":{color:"var(--color-white)"}, 
            "& .MuiInputBase-input":{color:"var(--color-white)"},
            "& .MuiPickersLayout-contentWrapper":{backgroundColor:"var(--secondary-color)"},
            
         
           
        }}}
        // views={["day","month"]}
          co
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}