import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { func } from '../../../Utilities/logFunc';
import TicketNumber from "../../../assets/images/ticketNimber-icon-svg.svg";

import Calender from "../../../assets/images/calendar-icon-svg.svg";
import Status from "../../../assets/images/status-icon-svg.svg";
import moment from 'moment';
import LoginJpg from '../../../assets/images/login-img.jpg'
import SendIcon from '../../../assets/images/send-icon-svg.svg'
import { async } from 'q';
import { getTicketDetails, updateTicketDetails } from '../../../Utilities/controller';
import { toast } from 'react-toastify';
import { url } from '../../../Utilities/url';
import Error from '../../Common/Error';
import TelephoneIcon from '../../../assets/images/telephone-svgrepo-com.svg'
import { useTranslation } from "react-i18next";
import {eventTracking} from "../../../firebaseAnalytics"; 
import {events} from "../../../Utilities/appEvents"; 
const TicketDetails = () => {
    const param = useParams()
    const location = useLocation()
    const {state} = location
  const {t} = useTranslation()
  const id = param?.id
    func("param id 17", id)
    const [data, setData] = useState([])
    const [chat, setChat] = useState('');
    const [error, setError] = useState('')
    const [conversations, setConversations] = useState([])
    const getTicketStatus = (ticket) => {
        if (ticket?.status) {
          switch (ticket?.status) {
            case "1":
              return "PENDING";
            case "2":
              return "REPLIED";
            case "3":
              return "IN PROGRESS";
            case "4":
              return "APPROVED";
            case "5":
              return "REJECTED";
            case "6":
              return "AUTO APPROVED";
            default:
              break;
          }
        } else {
          return "PENDING";
        }
      };
      const fetchTicketDetail = async() =>{
        try{
            const res = await getTicketDetails(id)
            func(res?.data)
            setData(res?.data?.item)
            setConversations(res?.data?.item?.conversations)
        }
        catch(error){
            func("error crm 44", error?.response?.data?.errors[0]?.msg)
            toast.error(error?.response?.data?.errors[0]?.msg)
        }
      }
      const handlerSend = async(e) =>{
        e.preventDefault()
        if(chat.length <=0){
          setError("Enter message")
          return
        }
        try{
          setError('')
            let chats = [...data?.conversations];
            eventTracking(events.Chat,{GJ_TicketID:id});

    chats.push({ message: chat, from: "appUser", dateAndTime: new Date() });
            const res = await updateTicketDetails(id,{
                conversations: chats,
                status: 3,
              })
            if(res?.data){
               
                setChat('')
                fetchTicketDetail()
                
            }
        }
        catch(error){
            func("error crm 58", error?.response?.data?.errors[0]?.msg)
            toast.error(error?.response?.data?.errors[0]?.msg)
        }
      }
      useEffect(()=>{
        fetchTicketDetail()
      },[])
  return (
    
        <div class="row gy-5">
          <div class="col-md-8 pe-lg-4">
            <div class="mb-4 d-flex align-items-center justify-content-between">
              <h2 class="h3 bodyBoldFont mb-0">{t('crm.ticket_details')}</h2>
            </div>
            <div class="card ticketDetailCard">
              <div class="card-body">
                {
                  state?
                  <div class="row g-3">
                  <div class="col-3">
                    <div class="card ticketInnerCard">
                      <div class="card-body">
                        <div class="card-icon mb-3">
                          <img class="" src={TicketNumber} alt="" />
                        </div>
                        <p class="bodyNormalFont mb-1 opacity-75">{t('crm.ticket_number')}</p>
                        <h5 class="bodyNormalFont mb-0">{data?.code}</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="card ticketInnerCard">
                      <div class="card-body">
                        <div class="card-icon mb-3">
                          <img class="" src={Calender} alt="" />
                        </div>
                        <p class="bodyNormalFont mb-1 opacity-75">{t('crm.date')}</p>
                          {moment(data?.createdAt).format("DD/MM/YYYY")}
                        <h5 class="bodyNormalFont mb-0"></h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="card ticketInnerCard">
                      <div class="card-body">
                        <div class="card-icon mb-3">
                          <img class="" src={TelephoneIcon} alt="" />
                        </div>
                        <p class="bodyNormalFont mb-1 opacity-75">{t('crm.call_back')}</p>
                          {state}
                        <h5 class="bodyNormalFont mb-0"></h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <div class="card ticketInnerCard">
                      <div class="card-body">
                        <div class="card-icon mb-3">
                          <img class="" src={Status} alt="" />
                        </div>
                        <p class="bodyNormalFont mb-1 opacity-75">{t('crm.status')}</p>
                        <h5 class="bodyNormalFont mb-0">
                        {getTicketStatus(data)?.length > 8 ? (
                            <marquee>{getTicketStatus(data)}</marquee>
                          ) : (
                            getTicketStatus(data)
                          )}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="" >
                    <div class="card ticketInnerCard" >
                      <div class="card-body bg-primary-color-dark-blue text-light" style={{textAlign:'left', alignItems:'flex-start'}}>
                        <p class="bodyNormalFont mb-1 ">{t('crm.subject')} : {data?.category?.name}</p>
                        <h5 class="bodyNormalFont mb-0">{data?.description}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                :
                <div class="row g-3">
                  <div class="col-4">
                    <div class="card ticketInnerCard">
                      <div class="card-body">
                        <div class="card-icon mb-3">
                          <img class="" src={TicketNumber} alt="" />
                        </div>
                        <p class="bodyNormalFont mb-1 opacity-75">{t('crm.ticket_number')}</p>
                        <h5 class="bodyNormalFont mb-0">{data?.code}</h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="card ticketInnerCard">
                      <div class="card-body">
                        <div class="card-icon mb-3">
                          <img class="" src={Calender} alt="" />
                        </div>
                        <p class="bodyNormalFont mb-1 opacity-75">{t('crm.date')}</p>
                          {moment(data?.createdAt).format("DD/MM/YYYY")}
                        <h5 class="bodyNormalFont mb-0"></h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="card ticketInnerCard">
                      <div class="card-body">
                        <div class="card-icon mb-3">
                          <img class="" src={Status} alt="" />
                        </div>
                        <p class="bodyNormalFont mb-1 opacity-75">{t('crm.status')}</p>
                        <h5 class="bodyNormalFont mb-0">
                        {getTicketStatus(data)?.length > 8 ? (
                            <marquee>{getTicketStatus(data)}</marquee>
                          ) : (
                            getTicketStatus(data)
                          )}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="card ticketInnerCard">
                      <div class="card-body bg-primary-color-dark-blue text-light" style={{textAlign:'left', alignItems:'flex-start'}}>
                        <p class="bodyNormalFont mb-1 ">{t('crm.subject')} : {data?.category?.name}</p>
                        <h5 class="bodyNormalFont mb-0">{data?.description}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                }
              </div>
            </div>
            {
              data?.screenShot ? <div class="card ticketDetailCard mt-5">
              <div class="card-body" style={{height:'350px'}}>
                <img class="w-100 rounded-3" style={{ height: "100%", objectFit: "contain"}} src={url.imageUrl + data?.screenShot?.default} alt="" /> 
              </div>
            </div>
            : ""
            }
            
          </div>
          <div class="col-md-4 border-start border-secondary-subtle noBorder-md-left">
            <div class="mb-4 d-flex align-items-center justify-content-between">
              <h2 class="h3 bodyBoldFont mb-0">{t('crm.chat')}</h2>
            </div>
            <div class="chatBox">
              <div class="chatConversation">
                <ul>
                    {
                        conversations?.map((ele,ind)=> 
                        // (
                            // (ele?.from = "appUser") ?
                            // <li className='reply'>{ele?.message}</li>
                            // :
                            // <li>{ele?.message}</li>
                        
                        // )
                        {
                            return ele?.from == "appUser" ? 
                           <>
                            <li className='reply mb-1' style={{textAlign:'right'}}>
                              {ele?.message}
                              </li>
                              <p style={{fontSize:'12px', textAlign:'right'}}>{moment(ele?.dateAndTime).format("DD/MM/YYYY hh:mm A")}</p>
                              </>
                            :
                            <>
                            <li className='mb-1'>{ele?.message}</li>
                            <p style={{fontSize:'12px' ,textAlign:'left'}}>{moment(ele?.dateAndTime).format("DD/MM/YYYY hh:mm A")}</p>
                            </>
                        }
                        )
                    }
                  
                
                </ul>
              </div>
              {data?.status != 4 && data?.status != 5 && data?.status != 6 && data?.status != "4" && data?.status != "5" && data?.status != "6" && (
                <form class="chatInput">
                  <input class="form-control" type="text" placeholder={t('crm.message')} onChange={(e) => setChat(e.target.value)} value={chat} /> 
                  <button class="send-btn" onClick={(e)=> handlerSend(e)}><img src={SendIcon} alt="" /></button>
                  {
                    error && <Error error={error} />
                  }
                </form>
              )}
            </div>
          </div>
        </div>
     
  )
}

export default TicketDetails
