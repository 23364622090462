import React from "react";
import MyContestTab from "./MyContestTab";

const MyContest = () => {
  return (
    <div class="content">
      <div className="content-inner">

      <div class="row gy-4">
        <MyContestTab />
      </div>
      </div>
    </div>
  );
};

export default MyContest;
