export const INITIAL_STATE = {
    title: '',
    type:'',
    date:'',
    time:'',
    noOfPlayerCapacity:'',
    noOfWinner:'',
    currency:'',
    feeAmount:'',
    title1:{},
    title2:{},
    rules:'',
    country:'',
    platform:'webapp',
    updatedBy:'',
    createdBy:'',
    game:'',
    gameType:'',
    hostedBy:''


}

export const createContestReducer = (state, action) =>{
    switch(action.type){
        case 'CHANGE_NAME':
            return{
                ...state,
                [action.payload.name]:action.payload.value
            }
        case 'CHANGE_RULES':
            return {
                ...state,
                rules: action.payload
            }
            case 'CHANGE_TITLE':
                return {
                    ...state,
                    [action.payload.name]: action.payload.value
                }
            default:
                return state
    }
}