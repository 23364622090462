import { func } from "../../Utilities/logFunc"

export const INITIAL_STATE = {
    phoneCode:'',
    phone:'',
    dateOfBirth:'',
    avatar:{},
    email:'',
    gamerjiName:'',
    address:{},
    mobileNumber:''
}
export const profileEditReducer = (state, action) =>{
    func("action",action)
    switch(action.type){
       
        case 'CHANE_NAME':
            return{
                ...state,
                [action.payload.name]: action.payload.value
                
            }
            case 'CHANE_STATE':
                return{
                    ...state,
                    address : {...action.payload}
                    
                }
            default:
                return state
    }
}