import React, { useState, useEffect } from "react";
import { gamerjiTournamentGet } from "../../Utilities/controller";
import { func } from "../../Utilities/logFunc";
import ReactQuill from "react-quill";
import 'quill/dist/quill.snow.css'
import { useTranslation } from "react-i18next";

const Rules = ({value, setRulesData,readOnly} ) => {
  // const [tournaments, setTournament] = useState(null);
  const {t} = useTranslation()

  // const getTournament = async () => {
  //   try {
  //     let userData = localStorage.getItem("userData");
  //     userData = JSON.parse(userData);

  //     let data = {
  //       filter: {
  //         tournamentType: "gamerji",
  //         user: userData?.user_id,
  //       },
  //     };

  //     const res = await gamerjiTournamentGet(data, selectedTournament?._id);
  //     // console.log("res?.data", res?.data);
  //     if (res?.data?.item) {
  //       setTournament(res?.data?.item);
  //     }
  //     func(res);
  //   } catch (error) {
  //     func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
  //   }
  // };

  // useEffect(() => {
  //   getTournament();
  // }, []);
  var modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] }
      ],
      [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
    ]
  };

  var formats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "bullet", "indent",
    "link", "image", "align", "size",
  ];
  const handleProcedureContentChange = (content) => {
    setRulesData(content)
  };

  return (
    <div style={{width:'100%',  display:'flex', flexDirection:'column',color:'var(--color-heading)'}}>
    <ReactQuill
       
    theme="snow"
    modules={modules}
    formats={formats}
    placeholder={t('private_contest.write_content')}
    value={value}
    onChange={handleProcedureContentChange}
    style={{ height: "60vh" , color:'var(--color-heading)'}}
    readOnly={false}
  >
  </ReactQuill>
  </div>
  );
};

export default Rules;
