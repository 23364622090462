import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import ContestList from "./ContestList";
import TournamentList from "./TournamentList";
import Private from "./Private";
import { useTranslation } from "react-i18next";

import { eventTracking } from "../../../firebaseAnalytics";
import { events } from "../../../Utilities/appEvents";

const MyContestTab = () => {
  const {t} = useTranslation();
  const [active, setActive] = useState(0)

  return (
    <div class="col-12 commonTab contestTab">
      <ul class="nav nav-pills  d-flex justify-content-between auth-nav" id="contestTab" role="tablist">
        <li class="nav-item col d-grid" role="presentation">
          <button
            class="nav-link active"
            id="contest-tab"
            data-bs-toggle="tab"
            data-bs-target="#contest-tab-pane"
            type="button"
            role="tab"
            aria-controls="contest-tab-pane"
            aria-selected="true"
            style={{width:'100%'}}
            onClick={()=>setActive(0)}
            
          >
            {t("myContest.contest")}
          </button>
        </li>
        <li class="nav-item col d-grid" role="presentation">
          <button
            class="nav-link"
            id="tournaments-tab"
            data-bs-toggle="tab"
            data-bs-target="#tournaments-tab-pane"
            type="button"
            role="tab"
            aria-controls="tournaments-tab-pane"
            aria-selected="false"
            style={{width:'100%'}}
            onClick={()=>setActive(1)}

          >
            {t("myContest.tournament")}
          </button>
        </li>
        <li class="nav-item col d-grid" role="presentation">
          <button
            class="nav-link"
            id="private-tab"
            data-bs-toggle="tab"
            data-bs-target="#private-tab-pane"
            type="button"
            role="tab"
            aria-controls="private-tab-pane"
            aria-selected="false"
            style={{width:'100%'}}
            onClick={()=>setActive(2)}

          >
            {t("myContest.private")}
          </button>
        </li>
      </ul>
      <div class="tab-content pt-4" id="contestTabContent">
      <div
        class="tab-pane fade show active"
        id="contest-tab-pane"
        role="tabpanel"
        aria-labelledby="contest-tab"
        tabindex="0"
      
      // style={{paddingBottom:'40px'}}

      >
        {
        active == 0 &&  <ContestList />
      }
     
         
        </div>
        <div
      class="tab-pane fade"
      id="tournaments-tab-pane"
      role="tabpanel"
      aria-labelledby="tournaments-tab"
      tabindex="0"
      style={{paddingBottom:'40px'}}
     


    >
      {/* {console.log(active)} */}
      {
        
        active == 1 &&  <TournamentList />
      }
     
      
      </div>
         <div
      class="tab-pane fade"
      id="private-tab-pane"
      role="tabpanel"
      aria-labelledby="private-tab"
      tabindex="0"
    

      // style={{ paddingBottom: "40px" }}
    >
      {
        active == 2 &&   <Private />
      }
     </div>
        
      </div>
    </div>
  );
};

export default MyContestTab;
