import { singularSdk, SingularConfig } from "singular-sdk";

export const initSingular = () => {
    const config = new SingularConfig("gamerji_e4acabae", "f9208e300d9309cde0c0b9140752faf7", "webapp-testing")
        .withInitFinishedCallback(initParams => {
            // This callback will be called when the init method has finished
            // You can get Singular Device id here
            const singularDeviceId = initParams.singularDeviceId;
            console.log("singularDeviceId", singularDeviceId);
        });
    singularSdk.init(config);
}