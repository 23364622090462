import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { getTimeSlots } from '../../Utilities/controller';
import moment from 'moment';
import { toast } from 'react-toastify';
import { func } from '../../Utilities/logFunc';
import Error from '../Common/Error';
import { useTranslation } from 'react-i18next';

import { eventTracking } from "../../firebaseAnalytics";
import { events } from "../../Utilities/appEvents";
import { addTicket } from '../../Utilities/withJwtController';
import { useProfileDataContext } from '../HomePage/ProfileContext';

const TimeSlotModal = ({showTimeSlot,setShowTimeSlot,requestPayload,setShowCallbackConfirmation,mobileNo,setDate,setTime}) => {
    const [show, setShow] = useState(showTimeSlot);
    const [times, setTimes] = useState([])
    const [dates, setDates] = useState([])
    const [activeDate, setActiveDate] = useState()
    const [activeTime, setActiveTime] = useState()
    const [index, setIndex] = useState(0)
    const [error, setError] = useState('')
    const [isBusy, setIsBusy] = useState(false)
    const {premiumUser} = useProfileDataContext()
    // console.log(premiumUser)
  // let premiumUser = //JSON.parse(localStorage.getItem("premiumUser"))

  const {t} = useTranslation()

    const onClickDate = (ind) => {
      setActiveDate(dates[ind])
      setActiveTime('')
      // setIndex(ind)
      setActiveTime(times?.[ind]?.[0])
    }
    const nextHandler = async () => {
      // console.log("called", activeDate, activeTime)
      setIsBusy(true)
      setDate(activeDate)
      setTime(activeTime)
      if(!activeTime || activeTime==undefined)
      {
        setError("Select one time slot")
      }
      if (activeTime) {
       
        
         let payload = {
          ...requestPayload,
          phone:mobileNo,
          isActive: true,
          isCallRequest: true,
          callTime: activeTime?.startTime + " - " + activeTime?.endTime,
          callDate: moment(activeDate).format("YYYY-MM-DD"),
          timeSlotId: activeTime?._id,
        }
        // console.log(payload, mobileNo)
        // setTime(moment(activeDate).format("YYYY-MM-DD"))
        try{
        const res = await addTicket(payload)
       
        if (res) {
          eventTracking(events.SELECT_CALL_BACK_TIME, {
            GJ_CallTime: activeTime?.startTime + " - " + activeTime?.endTime
          });
          eventTracking(events.SCHEDULE_CALL, {
            GJ_CallTime: activeTime?.startTime + " - " + activeTime?.endTime
          });
          
          setShowTimeSlot(false)
          setShowCallbackConfirmation(true)
        }
      }
      catch(error){
        func("error", error?.response?.data?.errors[0]?.msg)
        toast.error(error?.response?.data?.errors[0]?.msg)
      }
      }
  
    }
    const fetchTimeSlot = async () => {
      try {
        const res = await getTimeSlots()
        // let date1 =getHTMLdateFormat(res?.data[0]?.param[0]?.date)
        // let date2 =getHTMLdateFormat(res?.data[0]?.param[1]?.date) 
        let datess = []
        let timess = []
  
        for (let i = 0; i < res?.data?.data[0]?.param.length; i++) {
          datess.push(res?.data?.data[0]?.param[i]?.date)
        
  
          timess.push(res?.data?.data[0]?.param[i]?.data)
        }
  
        setDates([...datess])
        setTimes([...timess])
        setActiveDate(datess[0])
        setActiveTime(timess[0][0])
        setIndex(0)
  
      }
      catch (error) {
        toast.error(error?.response?.data?.errors[0]?.msg)
      }
    }
    const handleClose = () => {
    //   setShowWinningModal((prev) => false);
    setShowTimeSlot(false)
      setShow(false);
    };
    useEffect(()=>{
      fetchTimeSlot()
    },[])
  return (
    <Modal
    show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="winningBreakupModal modal fade subscriptionModalPopup"
      centered
    >
        <Modal.Body className={`${premiumUser ? "bg-premium text-light": ""}`}>
        <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          ></button>
          {func("timeslot", dates[0])}
        <div class="title text-center">
            <h1 class="h3 mb-4 pb-1 bodyBoldFont text-light">
            {t('crm.select_time_slot')}
            </h1>
          </div>
          <div class={`row justify-content-center ${premiumUser ? "bg-premium text-light": ""}`}>
            <div class="col-xl-6 col-lg-7 col-md-9 col-12 px-lg-0">
              <ul class="nav  d-flex justify-content-between  golden-nav shadow-customer-care mb-4" id="myTab"
                role="tablist" style={{width:'100%'}}>
                <li class="nav-item" role="presentation" style={{width:'50%'}} onClick={()=>onClickDate(0)}>
                  <button class="nav-link active" id="date1-tab" data-bs-toggle="tab" data-bs-target="#date1-tab-pane" style={{width:'100%'}}
                    type="button" role="tab" aria-controls="date1-tab-pane" aria-selected="true">
                   {moment(dates[0]).format('DD/MM/YYYY')}
                  </button>
                </li>
                <li class="nav-item" role="presentation" style={{width:'50%'}} onClick={()=>onClickDate(1)}>
                  <button class="nav-link" id="date2-tab" data-bs-toggle="tab" data-bs-target="#date2-tab-pane"
                    type="button" role="tab" aria-controls="date2-tab-pane" aria-selected="false" style={{width:'100%'}}>
                                       {moment(dates[1]).format('DD/MM/YYYY')}

                  </button>
                </li>
              </ul>
              <div class="tab-content golden-scroll">
                <div class="tab-pane fade show active" id="date1-tab-pane" role="tabpanel" aria-labelledby="date1-tab"
                  tabindex="0">
                  <div >
                    <div class="row justify-content-center">
                      <div class="col-6">
                        {func("timess 127", times[0])}
                        <div class="timeslotRadio-outer">
                          {
                            times?.[0]?.map((ele,ind) =>(
                              <div class="form-check mb-3 premium-circle"   key={ind}>
                            <input class="form-check-input" type="radio" name="flexRadioDefault1" id={`radio${ind}`} checked={activeTime === ele}/>
                            <label class="form-check-label bodyBoldFont text-uppercase" for={`radio${ind}`} onClick={() =>
                               {setActiveTime(ele)
                                setError('')
                               }
                               }>
                            {/* {moment(ele?.startTime).format("hh:mm A")} - {moment(ele?.endTime).format("hh:mm A")} */}
                              {ele?.startTime} {"-"} {ele?.endTime}
                            </label>
                          </div>
                            ))
                          }
                        
                        </div>
                        {
                  error && <Error error={error} />
                }
                      </div>
                      
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade l" id="date2-tab-pane" role="tabpanel" aria-labelledby="date2-tab" tabindex="0">
                <div >
                    <div class="row justify-content-center">
                      <div class="col-6">
                        <div class="timeslotRadio-outer">
                        {
                            times?.[1]?.map((ele,ind) =>(
                              <div class="form-check mb-3 premium-circle"  >
                            <input class="form-check-input" type="radio" name="flexRadioDefault2" id={`radio${ind}`} checked={activeTime === ele}/>
                            <label class="form-check-label bodyBoldFont text-uppercase" for={`radio${ind}`} 
                            onClick={() =>
                              {setActiveTime(ele)
                               setError('')
                              }
                              }
                            >
                            {/* {moment(ele?.startTime).format("hh:mm A")} - {moment(ele?.endTime).format("hh:mm A")} */}
                              {ele?.startTime} {"-"} {ele?.endTime}
                            </label>
                          </div>
                            ))
                          }
                        </div>
                        {
                  error && <Error error={error} />
                }
                      </div>
                     
                    </div>
                  </div>
                </div>
                
                <div class="col-12 mt-4 pt-2">
                
                        <p class="small text-center mb-0">  {t('crm.you_will_receive_a_call_back_on',{phonecode:requestPayload?.phoneCode,mobileno: requestPayload?.phone})}</p>
                      </div>
                      <div class="col-12 mt-4 pt-2">
                        <div class="d-grid">
                          {/* {console.log(isBusy)} */}
                          <button 
                          class="btn btn-gradient" 
                          disabled={isBusy}
                          onClick={nextHandler}
                          > {t('crm.next')}</button>
                        </div>
                      </div>
              </div>
            </div>
          </div>
        </Modal.Body>
    </Modal>
  )
}

export default TimeSlotModal