import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Rules from "../../Common/Rules";

const HowToCreateContest  = ({
  showHowTo,
  setShowHowTo,
  steps,
}) => {
  const [show, setShow] = useState(showHowTo);
  const handleClose = () => {
    setShowHowTo((prev) => false);
    setShow(false);
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="tournamentModal modal fade"
      centered
    >
      <Modal.Body>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <div class="row justify-content-center">
          <div class="col-11">
            <h3 className="text-center">How To Create A Contest</h3>
            <div class="card rulesCard text-center">
              <div class="card-body">
      <div dangerouslySetInnerHTML={{ __html: steps }} />
    </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default HowToCreateContest ;
