import axios from "axios";
import { url } from "./url";

// localStorage.setItem('token','7dXCaHTxirvcmTeR8eahXFiskV859Getdz/OP5uw521gvttyuvDnQ+M2W+AKZRU7+5nUzawbt+1KwBTR9xLodg==')

var header = { "user-type": "appUser" };
header["company-code"] = "GJ";
header['user-id'] = localStorage.getItem('user-id') ? localStorage.getItem('user-id') : '';
const instanceTokenLess = axios.create({
  baseURL: url.apiUrl,
  timeout: 40000,
  headers: header,
});

// instance.interceptors.request.use(function (config) {
//     if (!!config.data) {
//         for (let key of Object.keys(config?.data)) {
//             if (config.data[key] === "")
//                 config.data[key] = null
//         }
//     }
//     return config;
// }, function (error) {
//     return error //Promise.reject(error);
// });

// // Add a response interceptor
// instance.interceptors.response.use(function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     // console.log("response===axi", response);

//     if (response.status === 200) {
//         return response.data;
//     }
//     return response;
// }, function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     // console.log("error===axi", error);

//     if (error?.response?.status === 401) {
//         localStorage.clear();
//         window.location.href = '/sign-in';
//     }
//     return error?.response;
// });

export default instanceTokenLess;
