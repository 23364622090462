import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import TickIconPurple from "../../assets/images/success-payment.png";

const SuccessPop = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    //   setShowWinningModal((prev) => false);
    setShow(false);
  };
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="winningBreakupModal modal fade"
      centered
    >
      <Modal.Body>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <div class="content text-center">
          <div class="row justify-content-center">
            <div class="col-12">
              <div class="title">
              <img style={{width:'120px', height:'120px'}} src={TickIconPurple} />
              </div>
              <div class="row justify-content-center mt-2">
                <div class="col-xl-6 col-lg-7 col-9">
                    
                    <h2 className="mt-2">Transaction Successful</h2>
                    <div>
                    <p className="mb-0">Transaction Id: <span style={{color:"var(--primary-color)"}}>12345edcfcfs213234 </span></p>
                    <p className="">Amount:  <span style={{color:"var(--primary-color)"}}>$2.99</span></p>
                    </div>
                    <p className="mb-0">Thank you for subscribing!</p>
                    <p className="mt-0">Your are now a premium member.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessPop;
