import React, { useEffect, useReducer, useState } from 'react'
import Rules from '../../Common/Rules'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { INITIAL_STATE, createContestReducer } from './createContestReducer'
import { type } from '@testing-library/user-event/dist/type'
import { func } from '../../../Utilities/logFunc'
import SummaryContest from './SummaryContest'
import { getGameTypeScrim, getScrimById, getScrimGameRules, updateScrimById } from '../../../Utilities/controller'
import moment from 'moment/moment'
import { Dropdown } from 'react-bootstrap'
import Error from '../../Common/Error'
import { errors } from '../../../Utilities/error'
import { async } from 'q'
import { routes } from '../../../Utilities/routesFb'
import DropDown from '../../Common/DropDown'
import Select from "react-select";
import DatePicker from '../../Common/DatePicker'
import ResponsiveTimePickers from '../../Common/TimePicker'
import { useTranslation } from "react-i18next";
import { useProfileDataContext } from '../ProfileContext'
import NoContestFound from "../../../assets/images/noContestFound.png";

import {eventTracking} from "../../../firebaseAnalytics"; 
import {events} from "../../../Utilities/appEvents";

const CreatePrivateContest = () => {
    const [games, setGames] = useState([])
    const [gameTypes, setGameTypes] = useState([])
    const [activeType, setActiveType] = useState(games[0])
    const [state, dispatch] = useReducer(createContestReducer, INITIAL_STATE)
    const [showSummary, setShowSummary] = useState(false)
    const [showDropDown, setShowDropDown] = useState(true)
    const [bufferTime, setBufferTime] = useState("");
    const [dropDownNames, setDropDownnames] = useState([]);
    const [currencyName, setCurrencyName] = useState('')
    const [currencyId, setCurrencyId] = useState('')
    const { t } = useTranslation();
    const [currencyImg, setCurrencyImg] = useState('')
    const navigate = useNavigate()

    const [error, setError] = useState('')
    const [errorDate, setErrorDate] = useState('')
    const [errorTime, setErrorTime] = useState('')
    const [errorTitle1, setErrorTitle1] = useState('')
    const [errorTitle2, setErrorTitle2] = useState('')
    const [errorPlayer, setErrorPlayer] = useState('')
    const [errorWinner, setErrorWinner] = useState('')
    const [gameTypeId, setGameTypeId] = useState([])
    const [index, setIndex] = useState(0)
    const param = useParams()
  const { profileData, updateProfileData, liteProfile } = useProfileDataContext()

    const { id } = param
    func("id", id)
    const fetchGameType = async () => {
        const payload = {
            game: localStorage.getItem('activeGameId')
        }
        try {
            const res = await getGameTypeScrim(payload)
            func("scrim", res)
            let game = []
            let gameType = []
            let gameId = []
            res?.data?.forEach(element => {
                game.push(element?.name)
                gameId.push(element?._id)


            });
            func("game", game)
            setGames(game)
            setActiveType(game[0])
            setGameTypeId(gameId)

            res?.data?.forEach((element, index) => {
               
                var obj = {};
                obj["name"] = element.name;
                obj["value"] = element.game;
                gameType.push(obj);
            });
            func("gameType", gameType)
            setGameTypes(gameType);

            setBufferTime(res?.hostSettings?.currency?.[0]?.BufferTime);
            setNames(game[0])

            let countryId = localStorage.getItem('country') || liteProfile?.country?._id
            let stringWithoutQuotes = countryId?.replace(/"/g, '');

            dispatch({ type: 'CHANGE_NAME', payload: { name: 'feeAmount', value: res?.hostSettings?.hostFees } })
            dispatch({ type: 'CHANGE_NAME', payload: { name: 'type', value: res?.data[0]?.name } })
            dispatch({ type: 'CHANGE_NAME', payload: { name: 'country', value: stringWithoutQuotes } })
            dispatch({ type: 'CHANGE_NAME', payload: { name: 'createdBy', value: profileData?.item?.user_id } })
            dispatch({ type: 'CHANGE_NAME', payload: { name: 'hostedBy', value: profileData?.item?.user_id } })
            dispatch({ type: 'CHANGE_NAME', payload: { name: 'updatedBy', value: profileData?.item?.user_id } })



            dispatch({ type: 'CHANGE_NAME', payload: { name: 'currency', value: res?.hostSettings?.currency?.[0]?._id } })
            dispatch({ type: 'CHANGE_NAME', payload: { name: 'game', value: localStorage.getItem('activeGameId') } })
            dispatch({ type: 'CHANGE_NAME', payload: { name: 'gameType', value: res?.data[0]?._id } })
            dispatch({ type: 'CHANGE_NAME', payload: { name: 'date', value: new Date().toISOString()}})
            dispatch({ type: 'CHANGE_NAME', payload: { name: 'time', value: new Date().toISOString()}})

            setCurrencyName(res?.hostSettings?.currency?.[0]?.code == "D11" ? "Diamond" : "Coin")
            setCurrencyId(res?.hostSettings?.currency?.[0]?._id)

            setCurrencyImg(res?.hostSettings?.currency?.[0]?.img?.default)
            func("currency name",res?.hostSettings?.currency?.[0]?.name )

        }
        catch (error) {
            func(error)
        }
    }
    const fetchRules = async () => {
        const payload = {
            game: localStorage.getItem('activeGameId')
        }
        try {
            const rules = await getScrimGameRules(payload)

            func("rules", rules?.data)
            dispatch({ type: 'CHANGE_RULES', payload: rules?.data?.[0]?.rules })
        }
        catch (error) {
            func("eeror", error?.response?.data?.data?.[0].msg)
        }
    }
    const setNames = (type) => {
       

        let names = [];
        for (var i = 0; i < gameTypes.length; i++) {
            if (gameTypes[i].name === type) {
                let data = gameTypes[i].value;

                // console.log(data)

                for (let i = 0; i < gameTypes.length; i++) {
                    // console.log(data.titles[i], data[`title${i + 1}Values`].length > 0)
                    if (data[`title${i + 1}Values`]?.length > 0) {
                        var labelValue = [];
                        for (let j = 0; j < data[`title${i + 1}Values`].length; j++) {
                            labelValue.push({
                                value: data[`title${i + 1}Values`][j]["value"],
                                label: data[`title${i + 1}Values`][j]["value"],
                            });
                        }
                        // console.log(labelValue)
                        names.push([data.titles[i].value, labelValue]);
                    }
                }
                func("names", names)
                setDropDownnames([...names]);
                
                // dispatch({type:'CHANGE_NAME', payload:{name:'title1', value:names[0][0]}})
                // dispatch({type:'CHANGE_NAME', payload:{name:'title2', value:names[1][0]}})
                dispatch({ type: 'CHANGE_TITLE', payload: { name: 'title1', value: { name: names?.[0]?.[0], value: names?.[0]?.[1]?.[0].value } } })
                if (names?.[1])
                    dispatch({ type: 'CHANGE_TITLE', payload: { name: 'title2', value: { name: names?.[1]?.[0], value: names?.[1]?.[1]?.[0].value } } })


                // if (dropDownNames.length > 1) setTitle2(names[1][1].value);
            }
        }

    };

    const fetchScrimById = async () => {
        try {
            const res = await getScrimById({
                id: id
            })
            const payload = {
                game: localStorage.getItem('activeGameId')
            }
            const resType = await getGameTypeScrim(payload)
            let game = []
            let gameType = []
            let gameId = []
            resType?.data?.forEach(element => {
                game.push(element?.name)
                gameId.push(element?._id)


            });
            func("game", game)
            setGames(game)
            setGameTypeId(gameId)
            setActiveType(res?.data?.data[0]?.param?.gameType?.name )
            resType.data?.forEach((element, index) => {
                // if(element.name==="OPEN SQUAD")
                // console.log(element.game.titles)
                var obj = {};
                obj["name"] = element.name;
                obj["value"] = element.game;
                gameType.push(obj);
            });
            // console.log(titles, "titles");
            func("gameType 192", res?.data?.data[0]?.param?.titles[0]?.value)
            setGameTypes(gameType);
            func("red dit", res?.data?.data[0])
            dispatch({ type: 'CHANGE_TITLE', payload: { name: 'title1', value: { name: res?.data?.data[0]?.param?.titles[0]?.name, value: res?.data?.data[0]?.param?.titles[0]?.value } } })
            if (res?.data?.data[0]?.param?.titles[1])
                dispatch({ type: 'CHANGE_TITLE', payload: { name: 'title2', value: { name: res?.data?.data[0]?.param?.titles[1]?.name, value: res?.data?.data[0]?.param?.titles[1]?.value } } })
            dispatch({ type: 'CHANGE_NAME', payload: { name: 'type', value: res?.data?.data[0]?.name } })
            dispatch({ type: 'CHANGE_NAME', payload: { name: 'country', value: localStorage?.getItem('country') } })
            dispatch({ type: 'CHANGE_NAME', payload: { name: 'createdBy', value: profileData?.item?.user_id } })
            dispatch({ type: 'CHANGE_NAME', payload: { name: 'hostedBy', value: profileData?.item?.user_id } })
            dispatch({ type: 'CHANGE_NAME', payload: { name: 'updatedBy', value: profileData?.item?.user_id } })
            dispatch({ type: 'CHANGE_NAME', payload: { name: 'title', value: res?.data?.data[0]?.param?.title } })
            dispatch({ type: 'CHANGE_NAME', payload: { name: 'noOfPlayerCapacity', value: res?.data?.data[0]?.param?.noOfPlayerCapacity } })
            dispatch({ type: 'CHANGE_NAME', payload: { name: 'noOfWinner', value: res?.data?.data[0]?.param?.noOfWinner } })
            dispatch({ type: 'CHANGE_NAME', payload: { name: 'date', value: res?.data?.data[0]?.param?.date} })
            dispatch({ type: 'CHANGE_NAME', payload: { name: 'time', value: res?.data?.data[0]?.param?.time } })
            dispatch({ type: 'CHANGE_RULES', payload: res?.data?.data[0]?.param?.rules })
            dispatch({ type: 'CHANGE_NAME', payload: { name: 'game', value: res?.data?.data[0]?.param?.gameType?.game } })
            dispatch({ type: 'CHANGE_NAME', payload: { name: 'gameType', value: res?.data?.data[0]?.param?.gameType?._id } })
            dispatch({ type: 'CHANGE_NAME', payload: { name: 'feeAmount', value: resType?.hostSettings?.hostFees } })
            func("game 210", res?.data)
            setCurrencyName(resType?.hostSettings?.currency?.[0]?.code == "D11" ? "Diamond" : "Coin")
            setCurrencyImg(resType?.hostSettings?.currency?.[0]?.img?.default)
            func("gameType 215", res?.data?.data[0]?.param?.titles[0]?.value)

        }
        catch (error) {
            func(error)
        }
    }

    useEffect(() => {
        if (id) {
            func("called edit")
            fetchScrimById()
        }
        else {
            fetchGameType()
            fetchRules()
        }

    }, [])

    useEffect(() => {
        setNames(activeType)
    }, [activeType])
    const onChangeHandler = (e) => {
        func(e.target.name, e.target.value)
        dispatch({
            type: "CHANGE_NAME", payload: {
                name: e.target.name,
                value: e.target.value
            }
        })
    }

    const callEditScrim = async () => {
        let data = { ...state }
        let newDate = data?.date + " " + data?.time + ":00"
        func("newDate", newDate)
        func("datee", new Date(newDate))

        data = { ...data,  platform: "webapp" }
        let payload = {
            id: id,
            data: data
        }
        try {
            const res = await updateScrimById(payload)
            func("res", res?.data?.success)
            if (res?.data?.success) {
                func("jkdfjjkdnjkfnj", '/home/private-contest/manage-contest/' + id)
                navigate('/home/' + routes.privateContest + '/manage-contest/' + id)
            }
        }
        catch (error) {

        }
    }

    const handlerNext = () => {
        // func("state", state)
        let err = false
        // console.log(state?.time)
        let [t2, modifier] = moment(state?.time).format("hh:mm A").split(" ");
        let [hour, m] = t2.split(":");
        // console.log("279",hour,m)
        if (modifier == "PM" && hour != 12) {
            hour = parseInt(hour) + 12;
        }

        let newT = hour + ":" + m;
        console.log(new Date().getTime())
        let t1 = moment(new Date()).add(bufferTime + 15, "m").format("hh:mm A");
        // console.log(t1);
        let [nt, nmodifier] = t1.split(" ");
        let [nhour, nm] = nt.split(":");
        // console.log("293",nhour, nm, modifier);
        if (nmodifier == "PM" && nhour != 12) {
            nhour = parseInt(nhour) + 12;
        }
        let nt1 = nhour + ":" + nm;
        // console.log(newT, '------',nt1)

        var letterNumber = /^[0-9a-zA-Z\s]+$/;
        if (state.title.length == 0) {
            setError(t('error_select_game'));
            err = true
        }

        else if (!state.title.match(letterNumber)) {
            let value = "Please Enter Letters and Numbers Only";
            setError(t('error_special_characters_are_not_allowed'));
            err = true
        }
        else {
            setError('')
        }
        // func("Date***************",moment(state.date).diff(moment(new Date()),"day") )
        if (!state.date) {
            setErrorDate(t('error_please_select_date'));
            err = true
        }
        else if (moment(state.date).diff(moment(new Date()),"day") < 0 ){
            setErrorDate("Select Future Date")
            err = true
        }
        else {
            setErrorDate('')
        }

        if (!state.time) {
            setErrorTime(t('error_please_select_time'));
            err = true
        }

        else if (moment(state?.date).format("DD/MM/YYYY") == moment(new Date()).format("DD/MM/YYYY") && newT < nt1) {
            let value = "Please select future time";
            setErrorTime(t('error_please_select_futur_time'));
            err = true
        }
        else {
            setErrorTime('')
        }
        if (!state.title1 || state.title1.length === 0) {
            let value = `Please Select ${state.dropDownNames?.[0]?.[0]}`;
            setErrorTitle1(value)
            err = true
        }
        else {
            setErrorTitle1('')
        }
        if (state.dropDownNames?.length > 1) {
            if (!state.title2 || state.title2.length === 0) {
                let value = `Please Select ${state.dropDownNames?.[1]?.[0]}`;
                setErrorTitle2(value)
                err = true
            }
            else {
                setErrorTitle2('')
            }
        }
        if (state.noOfPlayerCapacity?.length === 0) {
            let value = "Please enter number of entries that can enter ";
            setErrorPlayer(t('error_please_enter_player'));
            err = true
        }
        else if (parseInt(state.noOfPlayerCapacity) <= 0) {
            let value = "Number of entries should be greater than 0";
            setErrorPlayer(value)
            err = true
        }
        else if (state.noOfPlayerCapacity?.length > 3) {
            let value = "Please enter number of winners";
            setErrorPlayer(value)
            err = true
        }
        else {
            setErrorPlayer('')
        }
        func("Winnereeee", state.numberOfWinners)
        if (parseInt(state.numberOfWinners) <= 0) {
            let value = "Number of Winner should be greater than 0";
            setErrorWinner(value)
            err = true
        }


        else if (state.noOfWinner?.length === 0) {
            let value = "Please Enter  Winners Count";
            setErrorWinner(t('error_please_enter_winner'));
            err = true
        }
        else if (state.noOfWinner?.length > 3) {
            let value = " Winners Count is invalid";
            setErrorWinner(value)
            err = true
        }
        else if (parseInt(state.noOfWinner) === parseInt(state.noOfPlayerCapacity)) {
            let value = " Winners cannot equal number of entries";
            setErrorWinner(value)
            err = true
        }

        else if (parseInt(state.noOfWinner) > parseInt(state.noOfPlayerCapacity)) {
            let value = "Winners cannot exceed number of entries";
            setErrorWinner(t('error_please_winner_not_more_then_player'));
            err = true
        }
        else {
            setErrorWinner('')
        }
        func("errr", state)
        if (!err) {
            if (id) {
                func("eeut called")
                callEditScrim()
            }
            else {
                setShowSummary(true)
            }
        }

    }

  

    return (
        <>
            <div class="row gy-5">
                <div class="col-12">
                    <div class="mb-5 pb-3 d-flex align-items-center justify-content-between">
                        <h2 class="h3 bodyBoldFont mb-0">{t('private_contest.private_contest')}</h2>
                    </div>
                    <div class="row justify-content-between">
                        <div class="col-xxl-6 pe-xxl-0">
                            <h4 class="text-primary-color-red  mb-4 pb-2 bodyBoldFont">{t('private_contest.basic_details')}</h4>
                            <form >
                                <div class="mb-4 pb-2">
                                    <label for="contestName" class="form-label h5 mb-2 pb-1"> {t('private_contest.contest_name')}</label>
                                    <input
                                        type="text"
                                        class="form-control"
                                        id="contestName"
                                        placeholder={t('private_contest.enter_contest_name')}
                                        name="title"
                                        value={state.title}
                                        onChange={(e) => onChangeHandler(e)}
                                    />
                                    {
                                        error && <Error error={error} />
                                    }
                                </div>
                                <div class="row mb-5">
                                    <label for="contestName" class="form-label h5 mb-2 pb-1"> {t('private_contest.select_scrim_type')}</label>
                                    <div class="d-flex gap-5" style={{flexWrap:'wrap'}}>
                                        {
                                            games.map((item, ind) => (
                                                <div class="form-check form-check-primary" key={ind} style={{width:'20%'}}>
                                                    <input
                                                        class="form-check-input"
                                                        type="radio"
                                                        name="contestType"
                                                        id={`soloCheck${ind}`}
                                                        value={state.type}
                                                        onChange={(e) => {
                                                            setActiveType(item)

                                                            dispatch({ type: 'CHANGE_NAME', payload: { name: 'type', value: item?.name } })
                                                            dispatch({ type: 'CHANGE_NAME', payload: { name: 'gameType', value: gameTypeId[ind] } })
                                                        }}
                                                        checked={activeType === item ? true : false}

                                                    />
                                                    <label class="form-check-label" for={`soloCheck${ind}`}>
                                                        {item}
                                                    </label>
                                                </div>
                                            ))
                                        }

                                    </div>
                                </div>

                                {/* <div className='row mb-4'>
                                    <div className="col-6 " key={dropDownNames?.[0]?.[0]}>
                                        <label
                                            for="inputCoutryCode"
                                            className="form-label text-nowrap"
                                        >
                                            {dropDownNames?.[0]?.[0]}
                                        </label>
                                        <select
                                            id="inputCoutryCode"
                                            className="form-select form-control  select d11-title-dropdown"
                                            name='title1'
                                            // value={loginInput.countryOption}
                                            onChange={(e) => dispatch({ type: 'CHANGE_TITLE', payload: { name: 'title1', value: { name: dropDownNames?.[0]?.[0], value: e.target.value } } })}
                                        >
                                            {
                                                dropDownNames?.[0]?.[1]?.length > 0 ?
                                                    dropDownNames?.[0]?.[1]?.map((ele, index) => (
                                                        <option>

                                                            {ele?.value}
                                                        </option>
                                                    ))
                                                    : <></>
                                            }

                                        </select>
                                    </div>
                                    {
                                        dropDownNames?.[1] ?
                                            <div className="col-6" key={dropDownNames?.[1]?.[0]}>
                                                <label
                                                    for="inputCoutryCode"
                                                    className="form-label text-nowrap"
                                                >
                                                    {dropDownNames?.[1]?.[0]}
                                                </label>
                                                <select
                                                    id="inputCoutryCode"
                                                    className="form-select select form-control d11-title-dropdown"
                                                    name='title2'
                                                    // value={loginInput.countryOption}
                                                    onChange={(e) => dispatch({ type: 'CHANGE_TITLE', payload: { name: 'title2', value: { name: dropDownNames?.[1]?.[0], value: e.target.value } } })}
                                                >
                                                    {
                                                        dropDownNames?.[1]?.[1]?.length > 0 ?
                                                            dropDownNames?.[1]?.[1]?.map((ele, index) => (
                                                                <option >

                                                                    {ele?.value}
                                                                </option>
                                                            ))
                                                            : <></>
                                                    }

                                                </select>
                                            </div>
                                            : <></>
                                    }

                                </div> */}
                                <div className='row'>
                                    <div className="col-sm-6 mb-4 pb-2 scrim-gametype-dropsown" key={dropDownNames?.[0]?.[0]}>
                                        <label
                                            for="inputCoutryCode"
                                            className="form-label text-nowrap"
                                        >
                                            {dropDownNames?.[0]?.[0]}
                                        </label>
                                        
                                        {/* <Select
                                        styles={customStyles}
                                        name='title1'
                                       
                                        onChange={(e) => dispatch({ type: 'CHANGE_TITLE', payload: { name: 'title1', value: { name: dropDownNames?.[0]?.[0], value: e?.value} } })}
                                        defaultValue={ dropDownNames?.[0]?.[1]?.[0]}
                                        options={ dropDownNames?.[0]?.[1]}
                                        
                                        /> */}
                                         <DropDown options={dropDownNames?.[0]?.[1]} name="title1" value={state?.title1?.value || dropDownNames?.[0]?.[1]?.[0]}
                                        
                                         handleChnage={(e)=>{
                                            func("drop down v alue", e)
                                         dispatch({ type: 'CHANGE_TITLE', payload: { name: 'title1', value: { name: dropDownNames?.[0]?.[0], value: e} } })
                                        
                                         }} />
                                    </div>
                                    {
                                        dropDownNames?.[1] ?
                                            <div className="col-sm-6 mb-4 pb-2 scrim-gametype-dropsown" key={dropDownNames?.[1]?.[0]}>
                                                <label
                                                    for="inputCoutryCode"
                                                    className="form-label text-nowrap"
                                                >
                                                    {dropDownNames?.[1]?.[0]}
                                                </label>
                                                {/* <Select
                                        styles={customStyles}
                                        name='title2'
                                        // value={loginInput.countryOption}
                                        onChange={(e) => dispatch({ type: 'CHANGE_TITLE', payload: { name: 'title2', value: { name: dropDownNames?.[1]?.[0], value: e?.value} } })}
                                        defaultValue={ dropDownNames?.[1]?.[1]?.[0]}
                                        options={ dropDownNames?.[1]?.[1]}
                                        
                                        /> */}
                            <DropDown 
                            options={dropDownNames?.[1]?.[1]} name="title2" 
                            value={state?.title2?.value || dropDownNames?.[1]?.[1]?.[0]}
                            handleChnage={(e)=>dispatch({ type: 'CHANGE_TITLE', payload: { name: 'title2', value: { name: dropDownNames?.[1]?.[0], value: e} } })} />

                                            </div>
                                            : <></>
                                    }

                                </div>

                                {/* <div class="row">
                                    <div class="col-6">
                                        <div class="mb-4 pb-2">
                                            <label for="contestName" class="form-label h5 mb-2 pb-1">Pick A Date</label>
                                            <input
                                                class="form-control"
                                                type="date"
                                                name="date"
                                                value={state.date}
                                                onChange={(e) => onChangeHandler(e)}

                                            />
                                            {
                                                errorDate && <Error error={errorDate} />
                                            }
                                        </div>
                                    </div>
                                    {func("date......", state.time)}
                                    <div class="col-6">
                                        <div class="mb-4 pb-2">
                                            <label for="contestName" class="form-label h5 mb-2 pb-1">Select Time</label>
                                            <input
                                                type="time"
                                                class="form-control"
                                                id="contestName"
                                                name="time"
                                                value={state.time}
                                                onChange={(e) => onChangeHandler(e)}

                                            />
                                            {
                                                errorTime && <Error error={errorTime} />
                                            }
                                        </div>
                                    </div>
                                </div> */}
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="mb-4 pb-2">
                                            <label for="contestName" class="form-label h5 mb-2 pb-1"> {t('private_contest.pick_a_date')}</label>
                                            {/* <input
                                                class="form-control"
                                                type="date"
                                                name="date"
                                                value={state.date}
                                                onChange={(e) => onChangeHandler(e)}

                                            /> */}
                                            {func("cre date", state)}
                                            <DatePicker 
                                            name="date"
                                            value={state?.date } 
                                            handlerChange={(e)=> {
                                                
                                                dispatch({ type: 'CHANGE_NAME', payload: { name: 'date', value: e.format()} })
                                            }} />
                                            {
                                                errorDate && <Error error={errorDate} />
                                            }
                                        </div>
                                    </div>
                                    {func("date......", state.time)}
                                    <div class="col-sm-6">
                                        <div class="mb-4 pb-2">
                                            <label for="contestName" class="form-label h5 mb-2 pb-1"> {t('private_contest.select_time')}</label>
                                            {/* <input
                                                type="time"
                                                class="form-control"
                                                id="contestName"
                                                name="time"
                                                value={state.time}
                                                onChange={(e) => onChangeHandler(e)}

                                            /> */}
                                            <ResponsiveTimePickers 
                                            value={state?.time } 

                                            handlerChange={(e)=> {
                                                func("date picker e",moment(e.format()).format('DD/MM/YYYY'))
                                                dispatch({ type: 'CHANGE_NAME', payload: { name: 'time', value: e.format() } })
                                            }} 
                                            />
                                            {
                                                errorTime && <Error error={errorTime} />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="mb-4 pb-2">
                                            <label for="contestName" class="form-label h5 mb-2 pb-1">{t('private_contest.number_of_players')}</label>
                                            <input
                                                type="number"
                                                class="form-control"
                                                id="contestName"
                                                placeholder= {t('private_contest.enter_number_of_players')}
                                                onKeyDown={(e) => {
                                                    if (
                                                        (e.key === "-" || e.key === "." || e.key === "e") ||
                                                        (e.key !== "Backspace" && e.target.value.length >= 3)
                                                      ) {
                                                        e.preventDefault();
                                                      }
                                                }}
                                                name="noOfPlayerCapacity"
                                                value={state.noOfPlayerCapacity}
                                                onChange={(e) => onChangeHandler(e)}

                                            />
                                            {
                                                errorPlayer && <Error error={errorPlayer} />
                                            }
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="mb-4 pb-2">
                                            <label for="contestName" class="form-label h5 mb-2 pb-1"> {t('private_contest.number_of_winners')}</label>
                                            <input
                                                type="number"
                                                class="form-control"
                                                id="winners"
                                                placeholder={t('private_contest.enter_number_of_winners')}
                                                onKeyDown={(e) => {
                                                    if (
                                                        (e.key === "-" || e.key === "." || e.key === "e") ||
                                                        (e.key !== "Backspace" && e.target.value.length >= 3)
                                                      ) {
                                                        e.preventDefault();
                                                      }
                                                }}
                                                name='noOfWinner'
                                                value={state.noOfWinner}
                                                onChange={(e) => onChangeHandler(e)}
                                            />
                                            {
                                                errorWinner && <Error error={errorWinner} />
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div class="row pt-md-4">
                                    <div class="col-sm-7">
                                        <div class="d-grid">
                                            <span
                                                class="btn btn-primary btn-arrow"
                                                onClick={handlerNext}
                                            >
                                                {t('private_contest.next')}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="col-lg-5 ps-lg-0 mt-lg-0 mt-5">
                            <h4 class="text-primary-color-red mb-4 pb-2 bodyBoldFont"> {t('private_contest.rules')}</h4>
                            {/* <div class="card rulesCard">
                               
                                <div class="card-body">
                                 
                                           
                                </div>
                            </div> */}
                            <Rules value={state?.rules} setRulesData={(value)=>dispatch({type:'CHANGE_RULES',payload:value})}/>
                        </div>
                    </div>
                </div>
            </div>

            {
                showSummary && <SummaryContest showSummary={showSummary} setShowSummary={setShowSummary} data={state} currName={currencyName} currImg={currencyImg} currencyId={currencyId} />
                // showSummary && <>{console.log('state',state)}</>
            }
        </>
    )
}

export default CreatePrivateContest 