export const INITIAL_STATE_REG = {
    mobileNumber: '',
    countryOption: '',
    code: ''
}

export const registerReducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE_INPUT':
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }
    }
}