import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
// import BannerImageInfo from "../../../assets/images/BGMI-banner.jpg";
import BannerImageInfo from "../../../assets/images/FreeFire.png";

import { routes } from "../../../Utilities/routesFb";
import { Link } from "react-router-dom";
import { url } from "../../../Utilities/url";
import {
  gameTypes,
  getUpdateProfile,
  howToJoinGame,
  getUserInGameName,
  addUserInGameName,
} from "../../../Utilities/controller";
import BaseAuth from "../../Auth/BaseAuth";

import { ToastContainer, toast } from "react-toastify";
import HowToJoin from "./HowToJoin";
import AddInNameGameModal from "./AddInNameGameModal";
import { useProfileDataContext } from "../ProfileContext";
import { useTranslation } from "react-i18next";
import Loader from "../../Common/Loader";
import {eventTracking} from "../../../firebaseAnalytics"; 
import {events} from "../../../Utilities/appEvents";
const Banner = ({ bannerImage }) => {
  const {t} = useTranslation()

  const [isBaseAuthOpen, setIsBaseAuthOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [allData, setAllData] = useState([]);

  const [gameid, setGameid] = useState("all");
  const [gameData, setGameData] = useState("all");
  const [title, setTitle] = useState("");
  const [showContacts, setShowContacts] = useState(false);
  const [type, setType] = useState("");
  const [tounamentsImg, setTounamentsImg] = useState("");
  const [currentName, setcurrentName] = useState("");
  const [newName, setnewtName] = useState("");
  const [howtoJoinTitle, setHowtoJoinTitle] = useState("");
  const [howtoJoin, setHowtoJoin] = useState("");
  const [showHowtoJoin, setShowHowtoJoin] = useState(false);
  const [error, setError] = useState(false);
  const [gameNames, setGameNames] = useState([]);
  const [userINGameName, setuserINGameName] = useState();
  const [stepsData, setStepsData] = useState([]);
  const [UserGameNameData, setUserGameNameData] = useState([]);
  const [setNoGameFound, setNoGameNameFound] = useState(false);
  const { profileData, updateProfileData } = useProfileDataContext()

  const toggleBaseAuthPopup = () => {
    setIsBaseAuthOpen((prevState) => !prevState);
  };
  const [isHowToJoinOpen, setIsHowToJoinOpen] = useState(false);

  const openHowToJoinPopup = () => {
    setIsHowToJoinOpen((prevState) => !prevState);
  };
  // const fetchData = async () => {
  //   let payload = localStorage.getItem("activeGameId");
  //   let response = await getGamesDetails(payload);

  //   if (response) {
  //     setGameData(response?.data);
  //     setTitle(response?.data?.name);
  //     // GetInGameNames();
  //     findUserName();
  //     setLoader(false);
  //   }
  // };
  
  useEffect(() => {
   
  }, [localStorage.getItem("activeGameId")]);

  useEffect(() => {
    getInGameNames()
  }, []);

  const getInGameNames = async () => {
    setLoader(true)
    try {

      let payload = {
        search: {
          user:profileData?.item?.user_id,
          game: localStorage.getItem("activeGameId"),
        },
      };

      let userInGameNameResponse = await getUserInGameName(payload);

      if (userInGameNameResponse?.data?.success) {

        const activeGame = localStorage.getItem("activeGameId")
        let index = null
        userInGameNameResponse?.data?.data.filter((ele,ind) =>{
            if(ele?.game === activeGame)
            index = ind
        })
        setuserINGameName(userInGameNameResponse?.data?.data[0]?.userINGameName)
        setNoGameNameFound(false);
        setLoader(false)
      } else {
        setNoGameNameFound(true);
        setLoader(false)
      }
    } catch (error) {
      setNoGameNameFound(true);
      setLoader(false)
    }
  };

  // let findUserName = async() => {
  //   try{
  //   let payload = {
  //     search: {
  //       user: profileData?.item?.user_id,
  //       game: localStorage.getItem("activeGameId"),
  //     },
  //   };

  //   let userInGameNameResponse = await getUserInGameName(payload);
  //   let gameNames = userInGameNameResponse?.data;
  //   // console.log(gameNames?.data)
  //   if (Array.isArray(gameNames)) {
  //     let index = gameNames.findIndex((obj) => obj?.game == gameid);
  //     if (index > -1) {
  //       setcurrentName(gameNames[index].userINGameName);
  //       return gameNames[index].userINGameName || "";
  //     } else {
  //       return "";
  //     }
  //   } 
  // }
  // catch(error) {
    
  // }
  // };

  const fetchDataOpen = () => {
    //fetchData();
    setShowContacts(true);
  };

 
  const [contentTitle, setContenttitle] = useState();
  const [contentImage, setContentImage] = useState();
  const [contentPairs, setContentPairs] = useState([]);
  const onClickJoin = async () => {
    setShowHowtoJoin(true);

    
  };
 
  return (
    <div class="col-12">
      {loader    ? <Loader />:<></>}
      <div class="tournamentBannerCard card">
        <div class="card-img position-relative" >
          {bannerImage ? (
            <img src={url?.imageUrl + bannerImage} class="rounded10" alt="" />
          ) : (
            <img src={BannerImageInfo} class="rounded10" alt="" />
          )}
        </div>
        <div class="card-body" >
          <div class="row h-100">
            <div class="card-content d-flex flex-column col-sm-6">
              <h3 class="card-title bodyExtraBoldFont text-uppercase">
                {localStorage.getItem("activeGame")}
              </h3>
              <div class="cardLinks mt-auto d-flex flex-column">
                <Link
                  className="bodyBoldFont text-capitalize mt-3 fw-normal"
                  // style={{fontSize:'1.25rem'}}
                  onClick={() => {
                    eventTracking(events.how_to_join,{
                      GJ_GameName:localStorage.getItem('activeGame'),
                      GJ_GameID:localStorage.getItem('activeGameId')
                  });
                    openHowToJoinPopup();
                    onClickJoin();
                  }}
                >
                  {t('tournaments.how_to_join_a_contest')}
                </Link>

                <div className="cardLinks mt-auto d-flex flex-column">
                  <Link
                    className="bodyBoldFont text-capitalize mt-3 fw-normal"
                    // style={{marginTop:'10px', fontSize:'1.25rem'}}
                    onClick={() => {
                      toggleBaseAuthPopup();
                      fetchDataOpen();
                    }}
                  >
                    {t('tournaments.add_change_game',{game_name:localStorage.getItem("activeGame")})}
                  </Link>
                  <p className="mb-0 mt-3 bodyBoldFont text-white">{userINGameName}</p>
                </div>

                {isHowToJoinOpen && (
                   <HowToJoin  isHowToJoinOpen={isHowToJoinOpen} setIsHowToJoinOpen={setIsHowToJoinOpen}/>
                  
                )}
                {isBaseAuthOpen && (
                  <AddInNameGameModal isBaseAuthOpen={isBaseAuthOpen} setIsBaseAuthOpen={setIsBaseAuthOpen} />
                 
                )}
              </div>
            </div>
            <div class="card-buttons d-flex flex-column col-sm ms-sm-auto justify-content-end mt-md-0 mt-4">
              <Link
                to={"/home/" + routes.myContest}
                class="btn btn-secondary mb-3"
                onClick={()=>{
                  eventTracking(events.my_contests,{
                    GJ_GameName:localStorage.getItem('activeGame'),
                    GJ_GameID:localStorage.getItem('activeGameId')
                });
                }}
              >
                {t('tournaments.my_contest')}
              </Link>
              <Link
                to={"/home/" + routes.privateContest}
                className="btn btn-secondary d11-ticket-text"
                onClick={()=>{
                  eventTracking(events.host_private_scrim,{
                    GJ_GameName:localStorage.getItem('activeGame'),
                    GJ_GameID:localStorage.getItem('activeGameId')
                });
                }}
              >
                {t('tournaments.host_private_contest')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
