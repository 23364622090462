import React, { useState } from "react";
import Load from "../../assets/images/loader.gif";
import { Modal } from "react-bootstrap";
import Lottie from "lottie-react";
import loderAnimation from '../../assets/jsonFiles/loader_animation.json'

const Loader = () => {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  return (
    <Modal
      show={show}
      className="loader"
      centered
    >
      <Modal.Body
      style={{
        content: {
          backgroundColor: "red !important",
        },
      }}
      >
        <div className="loader" style={{ height: "100vh" }}>
          {/* <img src={Load} />{" "} */}
          <Lottie
           animationData={loderAnimation} loop={true}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Loader;
