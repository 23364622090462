import React, { useEffect, useReducer, useState } from "react";
import BaseAuth from "./BaseAuth";
import {
  getCountryList,
  requestApplySignupCode,
  userRequestOTP,
  userRequestEmailOTP,
  userRequestEmailOTPSignUp,
  userRequestEmaiLoginlOTP,
  requestGoogleLogin,
  userRequestOTPSignUp,
} from "../../Utilities/controller";
import { func } from "../../Utilities/logFunc";
import Loader from "../Common/Loader";
import { url } from "../../Utilities/url";
import { errors } from "../../Utilities/error";
import { useLocation, useNavigate } from "react-router";
import { routes } from "../../Utilities/routesFb";
import { INITIAL_STATE, loginReducer } from "./loginReducer";
import LandingPage from "../LandingPage/LandingPage";
import { INITIAL_STATE_REG, registerReducer } from "./SignupReducer";
import Select from "react-select";
import DropDown from "../Common/DropDown";
import CountryDropDown from "../Common/CountryListDropDown";
import { logout } from "../../Utilities/logout";
import { toast } from "react-toastify";
import LoginLandingPage from "../LandingPage/LoginLandingPage";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Pipe from '../../assets/new-images/auth-pipe.png'
import Gogoole from '../../assets/new-images/gogoole.png'
// import { GoogleLogin } from 'react-google-login';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import {eventTracking} from "../../firebaseAnalytics"; 
import {events} from "../../Utilities/appEvents";


const EmailLogin = () => {
  func("login", "login page");
  const [countryList, setCountryList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loginemailInput, dispatchInput] = useReducer(loginReducer, INITIAL_STATE);
  const [registerInput, dispatchRegisterInput] = useReducer(
    registerReducer,
    INITIAL_STATE_REG
  );
  const [error, setError] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [appliedCouponCode, setAppliedCouponCode] = useState(undefined);
  const [couponError, setCouponError] = useState("");
  const [couponSuccess, setCouponSuccess] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [signupError, setSignupError] = useState("");
  const [onAgree, setOnAgree] = useState(false);
  const [tncError, setTncError] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [index, setIndex] = useState(0);
  const [disabledLoader, setDisabledLoader] = useState(false)
  const [loginData, setLoginData] = useState(
    // localStorage.getItem('loginData')
    //   ? JSON.parse(localStorage.getItem('loginData'))
    //   :
     null
  );
  const navigate = useNavigate();
  
  const location = useLocation()
  const {state} = location
  // console.log(state)
  const [active, setActive] = useState(state || 0)
  const { t } = useTranslation();

  const translatedText = t("login.i_agree_to_gamerji", {
    interpolation: { escapeValue: false },
  });

  useEffect(() => {
    let getLoginhandler = localStorage.getItem("loginHandler")
    if(getLoginhandler == "email-signup") {
      localStorage.removeItem("loginHandler");
      setActive(1)
    }
    else {
      localStorage.removeItem("loginHandler");
      setActive(0)
    }
  }, [])

  const fetchCountry = async () => {
    setLoader(true);
    try {
      const res = await getCountryList();
      if (res) setCountryList((prev) => res?.list);
      // setCountryOption(prev=> res?.list[2].dialingCode)
      let index = null;
      res?.list?.filter((item, ind) => {
        if (item?.dialingCode == "+973") {
          index = ind;
          return index;
        }
      });
      func("index", index);
      setIndex(index);
      dispatchInput({
        type: "CHANGE_INPUT",
        payload: {
          name: "countryOption",
          value: res?.list?.[index]?.dialingCode,
        },
      });
      dispatchInput({
        type: "CHANGE_INPUT",
        payload: { name: "code", value: res?.list?.[index]?._id },
      });
      dispatchRegisterInput({
        type: "CHANGE_INPUT",
        payload: {
          name: "countryOption",
          value: res?.list?.[index]?.dialingCode,
        },
      });
      dispatchRegisterInput({
        type: "CHANGE_INPUT",
        payload: { name: "code", value: res?.list?.[index]?._id },
      });
    } catch (error) {
      func("error", error);
    }
    setLoader(false);
  };
  useEffect(() => {
    fetchCountry();
  }, []);
  const onChangeHandler = (e) => {
    setError("");
    dispatchInput({
      type: "CHANGE_INPUT",
      payload: { name: e.target.name, value: e.target.value },
    });
  };
  const onSignupChangeHandler = (e) => {
    setSignupError("");
    setCouponError("")
    dispatchRegisterInput({
      type: "CHANGE_INPUT",
      payload: { name: e.target.name, value: e.target.value },
    });
  };
  const validateEmail = (email) => {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the regex
    return emailRegex.test(email);
}
  const loginHandler = (e) => {
    e.preventDefault();

    if (!loginemailInput?.email || loginemailInput?.email?.length == 0) {
      setError(t('error_enter_email'));
    } 
    else if (loginemailInput?.email!=="") {
      //let st= validateEmail(loginemailInput?.email)
     // console.log("*****",loginemailInput?.email,st)
      if (!validateEmail(loginemailInput?.email)) {
        setError(t('error_enter_valid_email'));
      } else {
        callToOTPRequestAPI();
      }
    } else {
      // if (loginemailInput?.mobileNumber.length < 8 || loginemailInput?.mobileNumber.length > 14 ) {
        setError(t('error_enter_valid_email'));
      // } else {
      //   callToOTPRequestAPI();
      // }
    }
    
    // else if (loginemailInput?.mobileNumber?.length != 10) {
    //   setError(errors?.error_enter_valid_phone);
    // } else {
    //   callToOTPRequestAPI();
    // }
    // const onSignupChangeHandler = (e) => {
    //   dispatchRegisterInput({ type: 'CHANGE_INPUT', payload: { name: e.target.name, value: e.target.value } })
    // }
    // const loginHandler = (e) => {
    //   e.preventDefault()
    //   if (!loginemailInput?.mobileNumber || loginemailInput?.mobileNumber?.length == 0) {
    //     setError(errors.error_enter_phone)
    //   }
    //   else if (loginemailInput?.mobileNumber?.length != 10) {
    //     setError(errors?.error_enter_valid_phone)
    //   }
    //   else {
    //     callToOTPRequestAPI();

    //   }
    // }
  }


  // request OTP after login
  const callToOTPRequestAPI = async () => {
    var payload = {
      type: "otpRequest",
      email: loginemailInput?.email,
      // phoneCode: loginemailInput?.countryOption,
      // country: loginemailInput?.code,
    };
    localStorage.setItem('email', loginemailInput?.email)
    localStorage.setItem("loginInfo", JSON.stringify(payload));
    setLoading(true)
    try {
      eventTracking(events.login_with_email,{
        GJ_EmailID : loginemailInput?.email,
      });
      const res = await userRequestEmaiLoginlOTP(payload)
      func('login res', res?.response?.data?.errors?.[0]?.msg)
      if (res?.data?.success) {
        navigate("/" + routes.emailloginotp, { state: loginemailInput })
      } else {
        setError(res?.response?.data?.errors?.[0]?.msg)
      }
    } catch (error) {
      func("userRequestOTP error", error)
      setError(error?.response?.data?.errors?.[0]?.msg)
    }
    setLoading(false)
  }

  const handlerSelect = (e) => {
    setCountryCode(e);
    dispatchInput({
      type: "CHANGE_INPUT",
      payload: { name: "code", value: e?._id },
    });
    dispatchInput({
      type: "CHANGE_INPUT",
      payload: { name: "countryOption", value: e?.dialingCode },
    });
  };
  const handlerSelectRegister = (e) => {
    setCountryCode(e);
    func("onChange", )
    setCouponError("")
    setCouponSuccess("")
    setCouponCode("")
    dispatchRegisterInput({
      type: "CHANGE_INPUT",
      payload: { name: "code", value: e?._id },
    });
    dispatchRegisterInput({
      type: "CHANGE_INPUT",
      payload: { name: "countryOption", value: e?.dialingCode },
    });
  };
  // request OTP after login


  // Registration
  const checkValidation = (e) => {
    setDisabledLoader(true)
    console.log("************checkValidation",registerInput)
    setSignupError("");
    e.preventDefault();
    if (
      !registerInput?.email ||
      registerInput?.email == ""
    ) {
      setDisabledLoader(false)
      setSignupError(t('login.enter_email_address'));
      return;
    }else if (registerInput?.email!=="") {
      //let st= validateEmail(loginemailInput?.email)
     // console.log("*****",loginemailInput?.email,st)
      if (!validateEmail(registerInput?.email)) {
        setDisabledLoader(false)
        setSignupError(t('error_enter_valid_email'));
        return;
       }
      // else {
      //   callToOTPRequestAPI();
      // }
    } 

    if (onAgree) {
      setTncError("");
      setDisabledLoader(true)
      callToOTPRequestAPISignup();
    } else {
      setDisabledLoader(false)
      setTncError(t('error_agree_terms_and_conditions'));
    }
  };

  // Update the state based on whether the checkbox is checked or not
  const handleCheckboxChange = (e) => {
    setTncError("")
    setOnAgree(e.target.checked);
  };

  // call signup API
  const callToOTPRequestAPISignup = async () => {
    // if (!registerInput?.countryOption) {
    //   setSignupError(t('error_select_country_code'));
    //   return;
    // }
    
    var payload = {
      type: "otpRequest",
      email: registerInput?.email,
      code: couponCode,
      platform:"webapp"
    };
     localStorage.setItem('loginInfo',JSON.stringify(payload))
    if (appliedCouponCode) {
      payload.code = couponCode;
    }
    setLoading(true);
    func("signup payload", payload);
    try {
      const res = await userRequestEmailOTPSignUp(payload);
     eventTracking(events.register_button,{GJ_EmailID:registerInput?.email})
      func("register res", res);
      if (res?.data?.success) {
        navigate("/" + routes.emailotp, { state: registerInput });
      } else {
        setDisabledLoader(false)
        setSignupError(res?.response?.data?.errors[0]?.msg);
      }
    } catch (error) {
      func("signup error", error);
      setDisabledLoader(false)
      if (error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_invalid" || error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_expired")
      logout()
            toast.error(error?.response?.data?.errors?.[0]?.msg)
      setSignupError(error?.response?.data?.errors?.[0]?.msg);
    }
    setLoading(false);
  };

  // call coupon apply API
  const applySingupCode = async (e) => {
    e.preventDefault();
    if (couponCode == "") {
      setCouponError(t('error_enter_coupon_code'));
    } else {
      setLoading(true);
      try {
        const res = await requestApplySignupCode({
          code: couponCode,
          type: "signupCode",
          country:registerInput.code
        });
        func("applySingupCode res", res);
        // setAppliedCouponCode(res.item)
        if (res?.data?.success) {
          setCouponError('')
          setCouponSuccess("Signup Code Applied Successfully!");
        }
        if (res?.response.data.errors) {
          setCouponError(res?.response?.data?.errors?.[0]?.msg);
        }
      } catch (error) {
        func("error", error);
        if (error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_invalid" || error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_expired")
        logout()
        setSignupError(error?.response?.data?.errors?.[0]?.msg);
      }
      setLoading(false);
    }
  };

  const customStyles = {

    // menu: (defaultStyles, state) => ({
    //   ...defaultStyles,
    //   // backgroundColor: "red",
    //   minHeight:'15rem',
    //   backgroundColor: "var(--secondary-color-emphasis)",
    // }),

    option: (defaultStyles, state) => ({
      ...defaultStyles,
      color: state.isSelected ? "#fff" : "#fff",
      backgroundColor: state.isSelected
        ? "var(--secondary-color-emphasis)!important"
        : "purple",
        height:'2.5rem',
        "&:hover" : {
          color:'var(--accent-color)'
        },
        padding:"0.65rem 1.25rem",
        borderRadius:'20px'
      
    }),

    control: (defaultStyles) => ({
      ...defaultStyles,
      backgroundColor: "var(--secondary-color-emphasis)",
      padding: "0.5rem",
      border: "none",
      boxShadow: "none",
      borderRadius: "50px",
      display: "flex",
      alignItems: "center",
      caretColor:'transparent',
      minHeight:'3.75rem'
    }),
    singleValue: (defaultStyles) => ({
      ...defaultStyles,
      color: "#fff",
      width: "4.7rem",
      marginRight: "0.75rem",
      padding: "0.35rem",
     
    }),
    indicatorsSeparator: (defaultStyles) => ({
      ...defaultStyles,
      color: "#fff",
      display: "none",
    }),
    indicatorContainer: (defaultStyles) => ({
      ...defaultStyles,
      padding: "0",
      backgroundColor: "red",
    }),

    input: (defaultStyles) => ({
      ...defaultStyles,
      color: "#fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "1.85rem",
    }),
    dropdownIndicator:(defaultStyles) => ({
    
      color:'var(--color-white)',
      width:'1.5rem',
      height:'1.5rem',
      padding:'0',
      display:'flex',
      justifyContent:'center',
      alignItems:'center'
    })
  };


    
  const responseGoogleOnSuccess = async (googleData) => {
    // console.log("googleData mobile in 123", googleData);
    setLoginData(googleData);
    // localStorage.setItem('loginData', JSON.stringify(googleData));
    const decoded = jwtDecode(googleData.credential);

    let payload = {
      "googleData": googleData.decoded,
      // "campaignId": campaignId,
      "platform": "webapp",
    }
    let response = await requestGoogleLogin(payload);
    // console.log("requestGoogleLogin:: response",response?.data?.errors)
    if (response) {
    //  console.log("requestGoogleLogin:: response",response)
      if (response?.data?.success === true) {
        localStorage.setItem('user-id', response?.data?.item?.id);
        localStorage.setItem('token', response?.data?.token);
        localStorage.setItem("country", response?.data?.item?.address?.country);
        eventTracking(events.Sign_in_using_Gmail,{GJ_EmailID:response?.data?.item?.email,})
        // if (response?.data?.item?.isDetailsFilled) window.location.href = "/" + routes.home;
        if (response?.data?.item?.isDetailsFilled)
          {
            // console.log(localStorage.getItem('code'))
            if (localStorage.getItem('code'))
            {
              window.location.href = "/" + routes.home + `?code=${localStorage.getItem('code')}`;
              
            }
             
              else 
              window.location.href = "/" + routes.home ;
          } 
        else window.location.href = "/avatar";

        // if (response.item.isSingup === true) {
        //   localStorage.setItem('profile', JSON.stringify(response));
        //   window.location.href = '/avatar';
        // } else {
        //   window.location.href = '/';
        // }
      } else {
        setError(response?.response?.data?.errors ? response?.response?.data?.errors?.[0]?.msg : t('error_Oops_something_went_wrong_please_try_again'));
      }
      // console.log("responseGoogleOnSuccess====response==>", response);
      //  setLoginData(response);
      // localStorage.setItem('loginData', JSON.stringify(response));
    }
    // const res = await fetch('/api/google-login', {
    //   method: 'POST',
    //   body: JSON.stringify({
    //     token: googleData.tokenId,
    //   }),
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    // });
    // const data = await res.json();
    console.log("responseGoogleOnSuccess==>", googleData);
  };

  const responseGoogleOnFailure = (response) => {
    console.log("responseGoogleOnFailure==>", response);
    setError(response?.data?.errors ? response?.data?.errors?.[0]?.msg : t('error_Oops_something_went_wrong_please_try_again'));
  };
  return (
    <LoginLandingPage>
          <BaseAuth>
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-7 col-sm-9">
                <ul
                  className="nav nav-pills  d-flex justify-content-between auth-nav"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation" style={{width:'50%'}}>
                    <button
                      className={`nav-link ${active === 0 ? 'active' : ""}`}
                      id="login-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#login-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="login-tab-pane"
                      aria-selected="true"
                      style={{width:'100%'}}
                      onClick={()=>eventTracking(events.LOGIN_TAB)}
                    >
                      {t("login.login")}
                    </button>
                  </li>
                  {/* <li className="nav-item pipe-nav-div" role="#" style={{width:'4%'}}>
                    <img src={Pipe} />
                  </li> */}
                  <li className="nav-item" role="presentation" style={{width:'50%'}}>
                    <button
                      className={`nav-link ${active === 1 ? 'active' : ""}`}
                      id="register-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#register-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="register-tab-pane"
                      aria-selected="false"
                      style={{width:'100%'}}
                      onClick={()=>eventTracking(events.REGISTER_TAB)}
                    >
                      {t("login.register")}
                    </button>
                  </li>
                </ul>
                <div className="tab-content auth-content">
                  {/* LOGIN TAB*/}
                  <div
                    className={`tab-pane fade ${active === 0 ? 'show active' : ""}`}
                    id="login-tab-pane"
                    role="tabpanel"
                    aria-labelledby="login-tab"
                    tabindex="0"
                  >
                    <form className="row">
                      <div className="col-12">
                        <label for="inputEmailId" className="form-label">
                        {t("login.email_id")}
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="inputEmailId"
                          placeholder={t("login.enter_email_address")}
                          name="email"
                          // onKeyDown={(e) =>
                          //   e.preventDefault()
                          // }
                          onChange={(e) => onChangeHandler(e)}
                          maxLength={52}
                          value={loginemailInput?.email}
                        />
                      </div>

                      <div className="col-12 d-grid">
                        <button
                          className="btn btn-primary btn-arrow"
                          onClick={(e) => loginHandler(e)}
                        >
                          {t("login.login")}
                        </button>
                        {error && (
                          <p className="error" style={{ textAlign: "center" }}>
                            {error}
                          </p>
                        )}
                      </div>

                      <div className="col-12 d-flex justify-content-center">
                        <label className="form-label">
                          {t("login.or")}
                        </label>
                      </div>

                      <div className="col-12 d-grid other_login">
                        <button
                          className="btn btn-outline-primary text-capitalize"
                          type="button"
                          onClick={()=>{ localStorage.setItem("loginHandler","login"); navigate("/login")}}
                        >
                          {t("login.login_with_phone")}
                        </button>
                      </div>
                      {/* <div className="col-12 d-grid other_login">
                        <button
                          className="btn btn-outline-primary text-capitalize btn-google"
                          type="button"
                        >
                          {t("login.sign_in_with_google")}
                        </button>
                      </div> */}
                      {/* GOOGLE LOGIN  */}
                      <div className="col-12  d-flex justify-content-center other_login">
                          {
                            <p className="text-center mt-2">
                              <GoogleLogin
                                // clientId={
                                //   "454874517092-tk61r0pb6f79ecte31ugdcde36old1c5.apps.googleusercontent.com"
                                // }
                                className="google-login"
                                onSuccess={responseGoogleOnSuccess}
                                onFailure={responseGoogleOnFailure}
                                cookiePolicy={"single_host_origin"}
                                theme={"light"}
                                width={"600"}
                                height={"60"}
                                shape={"circle"}
                              // uxMode={'redirect'}
                              // redirectUri={"http://localhost:3000/register/"}
                              >
                                <span className="buttonText" style={{"display":"inline-block","verticalAlign":"middle","paddingLeft":"42px","paddingRight":"42px","fontSize":"14px","fontWeight":"700","textAlign":"center","fontFamily":"\"Roboto\",sans-serif","marginLeft":"3rem"}}> Sign in with Google</span>
                              </GoogleLogin>
                            </p>
                          }
                        </div>
                      {/* GOOGLE LOGIN  */}
                    </form>
                  </div>
                  {/* Registertion Tab */}
                  <div
                    className={`tab-pane fade ${active === 1 ? 'show active' : ""}`}
                    id="register-tab-pane"
                    role="tabpanel"
                    aria-labelledby="register-tab"
                    tabindex="0"
                  >
                    <form className="row">
                    <div className="col-12">
                        <label for="inputEmailId" className="form-label">
                        {t("login.email_id")}
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="inputEmailId"
                          placeholder={t("login.enter_email_address")}
                          name="email"
                          // onKeyDown={(e) =>
                          //   e.preventDefault()
                          // }
                          onChange={(e) => onSignupChangeHandler(e)}
                          maxLength={52}
                          value={registerInput?.email}
                        />
                      </div>
                      {/* <div className="col-md-12">
                        <label for="inputCouponCode" className="form-label"
                        >
                           {t("login.coupon_code") || "Coupon Code"}
                          </label>
                        <div className="couponCode-block">
                          <input
                            type="text"
                            className="form-control"
                            id="inputCouponCode"
                            placeholder={t("login.enter_coupon_code") || "Enter Coupon Code"}
                            name='couponCode'
                            onChange={(e) => {
                              setCouponCode(prev => e.target.value)
                              setCouponError("")
                            }
                            }
                            value={couponCode}
                          />
                          {
                            couponSuccess ? <button
                            className="btn btn-primary"
                            // style={{ position: 'absolute', right: '0', top: '0', width: '120px', height: '100%' }}
                            onClick={(e) =>{
                              e.preventDefault()
                              setCouponError('')
                              setCouponCode('')
                              setCouponSuccess('')
                            }
                            }
                          >
                            {t("login.remove") || "Remove"}
                          </button> : 
                          <button
                          className="btn btn-primary"
                          // style={{ position: 'absolute', right: '0', top: '0', width: '120px', height: '100%' }}
                          onClick={(e) => applySingupCode(e)}
                        >
                          {t("login.apply") || "Apply"}
                        </button>
                          }
                          
                        </div>
                        {couponError && <p className='error'>{couponError}</p>}
                        {couponSuccess && <p style={{color:"green", marginTop:'20px', marginLeft:'10px'}}>{couponSuccess}</p>}
                      </div> */}
                      <div className="col-12">
                        <div className="form-check d-flex justify-content-start">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="gridCheck"
                            name="checkBox"
                            onChange={handleCheckboxChange}
                          />
                          <label className="form-check-terms " for="gridCheck">
                          <span
                              dangerouslySetInnerHTML={{
                                __html: translatedText,
                              }}
                            />
                            {/* I agree to D11’s <Link to={'/'+ routes.termsAndCondition} target="_blank"><strong>Terms & Conditions</strong> {" "} </Link> and {" "}
                            <Link to={'/' + routes.policy}  target="_blank"><strong> Privacy Policy </strong></Link> */}
                          </label>
                        </div>
                        {tncError && <p className='error'>{tncError}</p>}

                      </div>
                      <div className="col-12 d-grid">
                        <button 
                        type="submit" 
                        className="btn btn-primary btn-arrow" 
                        disabled={disabledLoader}
                        onClick={(e) => checkValidation(e)}>
                        {t("login.register") || "Register"}
                        </button>
                      </div>
                      {func("sigin", error)}
                      {
                        signupError && <p className='error' style={{ textAlign: 'center' }}>{signupError}</p>
                      }

                      <div className="col-12 d-flex justify-content-center">
                        <label className="form-label">
                          {t("login.or")}
                        </label>
                      </div>

                      <div className="col-12 d-grid other_login">
                        <button
                          className="btn btn-outline-primary text-capitalize"
                          type="button"
                           onClick={()=>{localStorage.setItem("loginHandler","register"); navigate("/login")}}
                        >
                          {t("login.register_with_phone")}
                        </button>
                      </div>
                      {/* <div className="col-12 d-grid other_login">
                        <button
                          className="btn btn-outline-primary text-capitalize btn-google"
                          type="button"
                        >
                          {t("login.register_in_with_google")}
                        </button>
                      </div> */}
                       {/* GOOGLE LOGIN  */}
                      <div className="col-12  d-flex justify-content-center other_login">
                          {
                            <p className="text-center mt-2">
                              <GoogleLogin
                                // clientId={
                                //   "454874517092-tk61r0pb6f79ecte31ugdcde36old1c5.apps.googleusercontent.com"
                                // }
                                className="google-login"
                                onSuccess={responseGoogleOnSuccess}
                                onFailure={responseGoogleOnFailure}
                                cookiePolicy={"single_host_origin"}
                                theme={"light"}
                                width={"600"}
                                height={"60"}
                                shape={"circle"}
                              // uxMode={'redirect'}
                              // redirectUri={"http://localhost:3000/register/"}
                              >
                                <span className="buttonText" style={{"display":"inline-block","verticalAlign":"middle","paddingLeft":"42px","paddingRight":"42px","fontSize":"14px","fontWeight":"700","textAlign":"center","fontFamily":"\"Roboto\",sans-serif","marginLeft":"3rem"}}> Sign in with Google</span>
                              </GoogleLogin>
                            </p>
                          }
                        </div>
                      {/* GOOGLE LOGIN  */}

                    </form>


                  </div>
                </div>
              </div>
            </div>
          </BaseAuth>
          {isLoading    ? <Loader />:<></>}
        </LoginLandingPage>
  );
};
export default EmailLogin;
