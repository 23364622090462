import React from 'react'
import LeftArrow from '../../assets/images/left-arrow.png'
import RightArrow from '../../assets/images/right-arrow.png'

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);
  const maxDisplayedPages = 4; // Adjust this value as needed


  const renderPageNumbers = () => {
    if (totalPages <= maxDisplayedPages) {
      return pageNumbers.map((page) => (
        <li key={page} className={currentPage === page ? 'active' : ''}>
          <button onClick={() => onPageChange(page)}>{page}</button>
        </li>
      ));
    } else {
      const start = Math.max(1, currentPage - Math.floor(maxDisplayedPages / 2));
      const end = Math.min(start + maxDisplayedPages - 1, totalPages);

      return (
        <>
          {start > 1 && (
            <>
              <li>
                <button onClick={() => onPageChange(1)}>1</button>
              </li>
              {start > 2 && <li>...</li>}
            </>
          )}

          {pageNumbers.slice(start - 1, end).map((page) => (
            <li key={page} className={currentPage === page ? 'active' : ''}>
              <button onClick={() => onPageChange(page)}>{page}</button>
            </li>
          ))}

          {end < totalPages && (
            <>
              {end < totalPages - 1 && <li>...</li>}
              <li>
                <button onClick={() => onPageChange(totalPages)}>{totalPages}</button>
              </li>
            </>
          )}
        </>
      );
    }
  };

  return (
    <div className='d11-pagination mt-5'>
    <button disabled={currentPage === 1} onClick={() => onPageChange(currentPage - 1)}>
      {'<'}
    </button>
    <ul className="pagination mb-0">
      {renderPageNumbers()}
    </ul>
    <button disabled={currentPage === totalPages} onClick={() => onPageChange(currentPage + 1)}>
      {'>'}
    </button>
  </div>

//     <div className='pagination-container'>
//     <div className='d11-pagination mt-5'>
//  <button
//         disabled={currentPage === 1}
//         onClick={() => onPageChange(currentPage - 1)}
//       >
//             <img src={LeftArrow} />
        
//       </button>
//       <ul className="pagination mb-0">
//         {pageNumbers.map((page) => (
//           <li key={page} className={currentPage === page ? 'active' : ''}>
//             <button onClick={() => onPageChange(page)}>{page}</button>
//           </li>
//         ))}
//       </ul>
//       <button
//         disabled={currentPage === totalPages}
//         onClick={() => onPageChange(currentPage + 1)}
//       >
//             <img src={RightArrow} />
//       </button>
//     </div>
//     </div>
  )
}

export default Pagination