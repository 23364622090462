import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import TournamentStructure from "./TournamentStructure";
import { func } from "../../../Utilities/logFunc";
import { getContestByCode } from "../../../Utilities/controller";
import moment from "moment";
import { toast } from "react-toastify";
import { errors } from "../../../Utilities/error";
import { useTranslation } from "react-i18next";

const JoinModal = ({
  showJoinModal,
  setShowJoinModal,
  handlerWinning,
  handlerJoinNow,
  tournaments,
  setShowRulesModal,
  setSelectedTournament,
  setShowJVC,
  setShowTournamentModal,
  setShowBasicProfileModal
}) => {
  const [show, setShow] = useState(showJoinModal);

  const {t} = useTranslation()

  const handleClose = () => {
    setShowJoinModal((prev) => false);
    setShow(false);
    if(setShowJVC)
    setShowJVC(false)
    // if(setShowTournamentModal)
    // setShowTournamentModal(false)

  };

  function isTournamentStarted(){
    var today = new Date();
    const tempRound = tournaments?.roundDateTime
    console.log(tempRound)
    var cdate = moment(tempRound).format("y-MM-DD");
    var ctime = moment(tempRound).format("HH:mm:ss");
    var contestDateTime = moment(cdate + " " + ctime);
    console.log( today ,contestDateTime, new Date(contestDateTime))
    console.log("contestDateTime",today > new Date(contestDateTime))

    if (today > new Date(contestDateTime)) {
      // toast.error(t('error_Oops_This_tournament_has_already_started'))
      return true
    }
    return false
 
  }
 const result =  isTournamentStarted()
  
  const handlerJoin = (tournaments) => {
    // alert("tournaments");
   
    // if(setShowJVC)
    // setShowJVC(false)
    var today = new Date();
    let roundaDateTime = tournaments?.rounds[0]?.matches
    var tempRound = roundaDateTime?.[roundaDateTime.length - 1];
    console.log(tempRound)
    var cdate = moment(tempRound.date).format("y-MM-DD");
    var ctime = moment(tempRound.time).format("HH:mm:ss");
    var contestDateTime = moment(cdate + " " + ctime);
    console.log( today , new Date(contestDateTime))
    if (today > new Date(contestDateTime)) {
      toast.error(t('error_Oops_This_tournament_has_already_started'))
    }
    else{
      setShowJoinModal((prev) => false);
      setShow(false);
      if(setShowTournamentModal)
      setShowTournamentModal(true)
      handlerJoinNow(tournaments);
    }
   
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="winningBreakupModal modal fade"
      centered
    >
      <Modal.Body>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
        ></button>
        <div class="row justify-content-center">
          <div class="col-md-11">
            <TournamentStructure
              handlerJoin={() => handlerJoin(tournaments)}
              handlerWinning={() => handlerWinning(tournaments)}
              tournaments={tournaments}
              setShowRulesModal={setShowRulesModal}
              setSelectedTournament={setSelectedTournament}
              setShowJoinModal={setShowJoinModal}
              setShowTournamentModal={setShowTournamentModal}
              setShowJVC = {setShowJVC}
              // isTournamentStarted = {result}

            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default JoinModal;
