import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { func } from "../../Utilities/logFunc";
import moment from "moment";
import Diamond from '../../assets/new-images/coin.svg'
import { url } from "../../Utilities/url";
import {
  getDailyReward,
  getAccountDailyReward,
  getAccountProfile,
  getHeaderApi,
} from "../../Utilities/controller";
import { toast } from "react-toastify";
import { useProfileDataContext } from "../HomePage/ProfileContext";
import { useTranslation } from "react-i18next";
import Loader from "../Common/Loader";

import {eventTracking} from "../../firebaseAnalytics"; 
import {events} from "../../Utilities/appEvents";
import { collectDailyRewards } from "../../Utilities/withJwtController";

const DailyLoginRewards = ({handleClose,  isPopup}) => {
  const {t} = useTranslation()

  const currentDayActiveBtnRef = useRef(null);
  const currentDayActiveBtnRef2 = useRef(null);
  const dailyLoginDetailsCardRef = useRef(null);

  const [isLoading, setLoading] = useState(false)

  const [dailyRewardList, setDailyRewardList] = useState([]);
  const [accountDailyReward, setAccountDailyReward] = useState(null);
  const {profileData, updateProfileData} = useProfileDataContext()

  func("handlercliose", handleClose)
  const getCurrentDayItem = (day) => {
    if (day < accountDailyReward?.currentDay) {
      return 1;
    } else if(day == accountDailyReward?.currentDay) {
      return 2;
    }
    else{
      return 3
    }
  };

  const fetchDailyRewardList = async (pageNo) => {
    try {
      //   console.log("user_data", user_data);

      let data = {
        skip: 0,
        limit: 100,
        day: accountDailyReward?.currentDay,
        sortBy: "day",
        sort: "asc",
      };

      const res = await getDailyReward(data);

      if (res?.data?.list) {
        setDailyRewardList(res?.data?.list);
        

      }
      func(res);
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };

  useEffect(() => {
    if(dailyRewardList?.length > 0) {
      const currentDayActiveBtn = currentDayActiveBtnRef.current;
      const dailyLoginDetailsCard = dailyLoginDetailsCardRef.current;

      if (currentDayActiveBtn && dailyLoginDetailsCard) {
        // Calculate the distance to scroll
        const scrollTopValue = currentDayActiveBtn.offsetTop - dailyLoginDetailsCard.offsetTop;

        // Scroll to the calculated position
        dailyLoginDetailsCard.scrollTo({
          top: scrollTopValue,
          behavior: 'smooth' // Add smooth scrolling behavior
        });
      } else {
        console.log("One or both elements not found.");
      }
    }
  }, [dailyRewardList]);

  const fetchAccountDailyRewardList = async () => {
    try {
      const res = await getAccountDailyReward();

      if (res?.data?.success) {
        setAccountDailyReward(res?.data?.item);
      }
      func(res);
    } catch (error) {
      toast.error(error?.response?.data?.errors?.[0]?.msg)
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };

  const getTotalCoin = (wallet) => {
    let index = wallet.findIndex((obj) => obj.currencyData?.code == "D11");

    if (index > -1) {
      return wallet[index].winningAmount ? wallet[index].winningAmount : 0;
    }
    return 0;
  };

  const handlerClaim = async(id,item)=>{
    try{
      setLoading(true)
      const payload = {
        dailyReward:id
      }
        const res = await collectDailyRewards(payload)
        if(res){

          const resProfile = await getHeaderApi();
          if (resProfile?.data) {
            eventTracking(events.DAILY_LOGIN_REWARD,{
              GJ_ClaimAmount:item.amount,
              GJ_CurrentDay:item.day
            });

            setLoading(false)
          toast.success(t('daily_reward'))
             updateProfileData(resProfile?.data)
             fetchAccountDailyRewardList()
          if (handleClose)
          handleClose()
            // localStorage.setItem("profile", JSON.stringify(res?.data))
            // localStorage.setItem("profile", JSON.stringify(resProfile?.data));
            // localStorage.setItem(
            //   "currentD11",
            //   getTotalCoin(resProfile?.item?.wallet)
            // );
          }
          
        }
    }catch(error){
      setLoading(false)
      toast.error(error?.response?.data?.errors[0]?.msg)
    }
  }

  useEffect(() => {
    fetchAccountDailyRewardList();
  }, []);

  useEffect(() => {
    fetchDailyRewardList(1);
  }, [accountDailyReward]);

  return (
    <div class="ps-lg-3">
      <h2 class="h3 bodyBoldFont mb-4 text-center text-dark">{t('Profile.daily_login_rewards')}</h2>
      <div class="card dailyloginCard mb-2">
        <div class="card-header">
          <div class="row justify-content-between align-items-center">
            <div class="col-4 d-flex justify-content-around">
              <h5 class="bodyNormalFont h4 mb-0 text-black">{t('Profile.login_rewards')}</h5>
              </div>
              <div class="col-4 d-flex justify-content-between align-items-center">
                <div class="col text-center">
                  <h5 class="bodyNormalFont h4 mb-0 text-black">{t('Profile.day')}</h5>
                </div>
              </div>
            
            <div class="col-4">
              <div class="d-flex justify-content-between">
                <div class="col text-center">
                  <h5 class="bodyNormalFont h4 mb-0 text-black">{t('Profile.status')}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card dailyLoginDetailsCard  mb-4">
        <div class="card-body dailyLoginDetailsCard-2" ref={dailyLoginDetailsCardRef} style={{ overflowY: 'scroll' }}>
          <ul class="list-unstyled mb-0">
            {dailyRewardList.length > 0 &&
              dailyRewardList.map((item, ind) =>  (
                <li ref={(item.day == accountDailyReward?.currentDay) ? currentDayActiveBtnRef : currentDayActiveBtnRef2} class={`row justify-content-between align-items-center ${(item.day == accountDailyReward?.currentDay) && 'current-day-active-btn'}`} key={ind} style={{padding:'0.75rem'}} >                   
                  <div class="col-4 d-flex justify-content-around">
                    {item?.avatar &&
                    item?.avatar?.img &&
                    item?.avatar?.img?.default ? (
                      <h6 class="mb-1 h5 text-white d-flex justify-content-center align-items-center">
                        {item?.avatar?.name}{" "}
                        <img
                          src={url?.imageUrl + item?.avatar?.img?.default}
                          // height={"22px"}
                          // width={"18px"}
                          class="mx-2 icon-20"
                        />
                      </h6>
                    ) : (
                      <h6 class="mb-1 h5 text-white d-flex justify-content-center align-items-center">
                        +{item?.amount}{" "}
                        <span class="mx-2">
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewbox="0 0 20 20"
                            fill="none"
                          >
                            <g clip-path="url(#clip0_2639_4171)">
                              <path
                                d="M10 0C4.47728 0 0 4.47728 0 10C0 15.5227 4.47728 20 10 20C15.5227 20 20 15.5227 20 10C20 4.47728 15.5227 0 10 0ZM15.5374 15.5374C14.166 16.9086 12.3347 17.7222 10.398 17.8209C8.46122 17.9196 6.55673 17.2962 5.05314 16.0715H3.05173L2.13968 16.066L2.87062 13.5766H3.0295C2.36924 12.2906 2.07774 10.8469 2.18726 9.40549C2.29678 7.96403 2.80305 6.58099 3.65004 5.40949C4.49702 4.23798 5.65166 3.32371 6.98615 2.76789C8.32063 2.21206 9.7829 2.03636 11.2111 2.26024C12.6392 2.48412 13.9776 3.09885 15.078 4.03637C16.1784 4.97389 16.9979 6.19763 17.4457 7.57211C17.8936 8.9466 17.9524 10.4182 17.6156 11.824C17.2788 13.2299 16.5595 14.5151 15.5374 15.5374Z"
                                fill="#FFCD05"
                              ></path>
                              <path
                                d="M11.701 4.52344H6.1701L4.23972 11.033C4.23972 11.033 4.34817 11.0395 4.53037 11.0395C5.41205 11.0395 8.02511 10.8942 8.50716 9.19592C9.03964 7.31815 9.14592 7.01015 9.14592 7.01015H10.0704C11.0313 7.01015 11.6559 7.22524 11.9444 7.65542C12.1201 7.91786 12.1788 8.31713 12.1206 8.85323H10.6371L10.3058 9.77504H11.9406C11.9179 9.86288 11.8934 9.95181 11.8669 10.0462C11.8202 10.2027 11.7734 10.3518 11.7264 10.4935H10.0856L9.75431 11.4153H11.3545C11.2633 11.6043 11.1543 11.7843 11.0291 11.9527C10.7305 12.3453 10.3938 12.6204 10.0189 12.778C9.64406 12.9356 9.06982 13.0153 8.29623 13.0171H3.63296L2.90039 15.5065L3.8119 15.5119H8.4589C9.11935 15.5119 9.88499 15.4122 10.7558 15.2126C11.3924 15.068 12.0341 14.777 12.6808 14.3396C13.0479 14.0913 13.5744 13.5957 13.7539 13.4232C14.1985 12.9607 14.6307 12.2363 14.9896 11.4169H16.4298L16.7611 10.4951H15.3513C15.4327 10.2576 15.5075 10.0174 15.5736 9.77883H16.9835L17.3142 8.85702H15.7867C15.931 8.06264 15.9548 7.33875 15.8046 6.82688C15.7629 6.67993 15.655 6.33344 15.5037 6.09268C15.2145 5.5884 14.7755 5.20052 14.1866 4.92903C13.5977 4.65755 12.7692 4.52308 11.701 4.52561V4.52344Z"
                                fill="#FFCD05"
                              ></path>
                            </g>
                            <defs>
                              <clippath id="clip0_2639_4171">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                ></rect>
                              </clippath>
                            </defs>
                          </svg> */}
                          { item?.currency ? <img src={url?.imageUrl + item?.currency?.img?.default} className="icon-20"  /> : <img src={Diamond} className="icon-20"  />}
                        </span>
                      </h6>
                    )}
                    </div>
                    <div class="col-4 d-flex justify-content-between">
                      <div class="col text-center">
                        <h5 class="bodyNormalFont mb-0">{t('Profile.dayData',{n:item.day})}</h5>
                      </div>
                    </div>
                  
                  <div class="col-4 text-center d-flex justify-content-center">
                    {func("accountDailyReward?.day", accountDailyReward)}
                    {
                    item?.day < accountDailyReward?.currentDay  ?
                     <div class="d-grid">
                     <button 
                    style={{textTransform:'capitalize'}}
                     class="btn btn-sm bg-dark text-black mx-2 my-1  d11-claim-btn" disabled>
                        {t('Profile.claimed')}
                    </button>
                    </div>
                    :
                    item.day ==  accountDailyReward?.currentDay ? 
                    
                      
                        accountDailyReward?.isDailyRewardPopup ? 
                      
                      <button
                        class="btn btn-sm bg-dark text-black mx-2 my-1 d11-claim-btn"
                        style={{textTransform:'capitalize'}}
                        onClick = {()=>handlerClaim(item?._id,item)}
                      >
                       
                       {t('Profile.claim')}
                      </button>
                      :
                      <div class="d-grid">
                         <button 
                        style={{textTransform:'capitalize'}}
                         class="btn btn-sm bg-dark text-black mx-2 my-1  d11-claim-btn " disabled>
                             {t('Profile.claimed')}

                        </button>
                        </div>

                    : ""
                   
                    }
                  </div>
                </li>
                
              ))}
          </ul>
        </div>
      </div>

      {isLoading    ? <Loader />:<></>}
    </div>
  );
};

export default DailyLoginRewards;
