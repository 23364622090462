import React, { createContext, useContext, useEffect, useState } from 'react'
import { d11_color , gamerji} from './Utilities/color';
import { func } from './Utilities/logFunc';
const ThemeContext = createContext();

export const useTheme = () => {
  return useContext(ThemeContext);
};
const ThemeProvider = ({children}) => {
    const root = document.documentElement;
    const name = {
      '1':'Light Theme',
      '0': 'Dark Theme'
    }
  //   const [themeName, setThemeName] = useState(localStorage.getItem('theme') == '0' ? name['1'] :  name['0'])
  // const [theme, setTheme] = useState(localStorage.getItem('theme') == '1' ? d11_color : gamerji);
  const [themeName, setThemeName] = useState(name['0'] )
  const [theme, setTheme] = useState( d11_color);
  const [token, setToken] = useState('');
  const [userData, setUserData] = useState([]);
  Object.entries(theme)?.forEach(([key, value]) => {
    root.style.setProperty(`--${key}`, value);
  });

  const changeTheme = (theme) => {
    // console.log(theme)
    setThemeName(name[theme]);
    localStorage.setItem('theme', theme)
    if (theme == '0') setTheme(gamerji)
      else setTheme(d11_color)
  }
  const fetchUserData = (data) => {
    setUserData(data?.item)
    setToken(data?.token)
  }
  
  return (
    <ThemeContext.Provider value={{theme, token, userData, fetchUserData, changeTheme, themeName}}>
        {children}
    </ThemeContext.Provider>
  )
}

export default ThemeProvider