import React, { useEffect } from "react";
import { Outlet } from "react-router";
import {eventTracking} from "../../../firebaseAnalytics"; 
import {events} from "../../../Utilities/appEvents"; 

const CRMMain = () => {
  useEffect(()=>{
    EventTracking(); 
  },[]);
  const EventTracking=()=>{
    eventTracking(events.MENU.CRM);
  }
  return (
    <div class="content">
      <div className="content-inner">

      <Outlet />
      </div>
    </div>
  );
};

export default CRMMain;
