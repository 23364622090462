import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { json, useNavigate } from "react-router";

import { func } from "../../../Utilities/logFunc";
import { blogList } from "../../../Utilities/controller";
import { url } from "../../../Utilities/url";
import Pagination from "../../Common/Pagination";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loader from "../../Common/Loader";

const AllNews = ({ handleClickBlog }) => {
  let [newsDataInfo, setNewsDataInfo] = useState([]);
  let [limit, setLimit] = useState(8);
  let [count, setCount] = useState(1);
  let [totalRecord, setTotalRecord] = useState(1);
  const navigate = useNavigate();
  const [skipCount, setSkipCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);

  const fetchBlogs = async (skipCount) => {
    setLoading(true);
    try {
      let data = {
        skip: skipCount,
        limit: limit,
        sort: "desc",
        sortBy: "createdAt",
      };

      const res = await blogList(data);
      if (res?.data?.list) {
        setNewsDataInfo(res?.data?.list);
      
        setTotalPages(Math.ceil(res?.data?.count/limit))
      }
      setTimeout(() => {
        setLoading(false);
      }, 1000);

      func(res);
    } catch (error) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    setSkipCount((prev) => (page - 1) * limit);
    fetchBlogs(Math.ceil(page - 1) * limit);
  };
  useEffect(() => {
    fetchBlogs(0);
  }, []);

  const loadMoreBtnClick = () => {
    let totalCount = parseInt(count) + 1;
    setCount(totalCount);
    fetchBlogs(totalCount);
  };

  return (
    <>
      {isLoading ? <Loader /> : <></>}
      
      {newsDataInfo?.length > 0 && (
        <div class="col-12" style={{position:'relative', paddingBottom:'40px', height:'75vh'}}>
          <div class="mb-3 d-flex align-items-center justify-content-between">
            <h2 class="h3 bodyBoldFont mb-0">{t('woe.esports_news')}</h2>
          </div>
          <div class="row g-20">
            {newsDataInfo.length > 0 &&
              newsDataInfo.map((data, indp) => (
                <div class="col-lg-3 col-md-4 col-6 " key={indp}>
                  <div class="card newsCard card-border">
                    <div class="card-body " style={{padding:'20px'}}>
                      <div class="card-img" style={{height:'13rem'}}>
                        <img
                         style={{height:'100%', objectFit:'cover'}}

                          class="rounded-1 w-100"
                          src={url?.imageUrl + data?.img?.default}
                          alt=""
                        />
                      </div>
                      <div class="card-content mt-2">
                        <div class="d-flex align-items-end justify-content-between">
                          <div className="row w-100">
                            <div className="col-sm-12">
                              <div class="info pt-2">
                                <h6 class="mb-1 bodyBoldFont fs-4">
                                  {
                                    data?.title.length > 30 ? 
                                  <marquee>{data?.title}</marquee>
                                  : data?.title
}
                                </h6>
                              </div>
                            </div>
                            <div className="col-sm-9 pe-0">
                              <div class="info">
                                <h6 class="mb-0 fs-6 bodyNormalFont text-body newsDescription" style={{marginBottom:'1rem',display:'block',width:'100%',whiteSpace: "nowrap", overflow: "hidden",
  textOverflow: "ellipsis"}}>
                                  {/* {data?.shortDescription} */}
                                  {
                                  // data?.shortDescription?.length > 40
                                  //   ? data?.shortDescription?.substring(
                                  //       0,
                                  //       35 - 3
                                  //     ) + "..."
                                  //   : 
                                    data?.shortDescription}
                                </h6>
                              </div>
                            </div>
                            <div className="col-sm-3 f-65 ps-0 pe-0">
                              <span
                              
                              onClick={() => {
                                // handleClickBlog(data);
                                navigate(
                                  "/home/world-of-esports/news-details",
                                  {state: data}
                                );
                              }}
                                className="text-danger bodyBoldFont cursor"
                                style={{fontSize:'.875rem'}}
                              >
                                {t('woe.read_more')}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}

      {/* {newsDataInfo.length < totalRecord && (
        <div class="card-footer d-flex justify-content-center mt-4">
          <button
            class="btn btn-primary col-2 text-center"
            onClick={() => loadMoreBtnClick()}
          >
            Load More
          </button>
        </div>
      )} */}
      {totalPages > 1 && (
             
              <Pagination currentPage = {currentPage} totalPages = {totalPages} onPageChange={handlePageChange} />
            )}
    </>
  );
};

export default AllNews;
