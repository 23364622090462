import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { json, useLocation, useNavigate } from "react-router";

import { func } from "../../../Utilities/logFunc";
import { blogList } from "../../../Utilities/controller";
import { url } from "../../../Utilities/url";
import moment from "moment";
import { color } from "@mui/system";

const NewsDetails = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const {state }= location
  useEffect(() => {
    if (state) {
    } else {
      navigate(-1);
    }
  }, [state]);
  return (
    <div className="row gy-5">
      <div className="col-lg col-12">
        <div class="card newsCardDetails">
          <div class="card-body">
            <div class="card-img">
              <img
                class="rounded-1 w-100"
                src={url?.imageUrl + state?.img?.default}
                alt=""
              />
            </div>
            <div class="card-content mt-4">
              <div class="d-flex align-items-end justify-content-between">
                <div className="row w-100">
                  <div className="col-sm-12">
                    <div class="info">
                      <h4 class="mb-3 bodyBoldFont">{state?.title}</h4>
                    </div>
                  </div>
                  <div className="col-sm-12 pe-0">
                    <div class="info">
                      <h6 class="mb-0 bodyNormalFont text-body newsDescription bodyBoldFont" style={{color:'white !important'}}>
                        Published:{" "}
                        {moment(state?.createdAt).format("DD MMM YYYY")}
                      </h6>
                      <div
                        className="mt-3"
                        dangerouslySetInnerHTML={{ __html: state?.description }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsDetails;
