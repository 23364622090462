import React, { useEffect } from "react";
import CointStoreInfo from "./CointStoreInfo";
import { useLocation } from "react-router";
import { func } from "../../../Utilities/logFunc";
import {eventTracking} from "../../../firebaseAnalytics"; 
import {events} from "../../../Utilities/appEvents"; 
const CoinStore = () => {
  //   const location = useLocation();
  //   const { active } = location?.state;
  //   func(active, "lead");
  useEffect(() => {
    func("called");
  }, []);
  useEffect(()=>{
    EventTracking(); 
  },[]);
  const EventTracking=()=>{
    eventTracking(events.MENU.COIN_STORE);
  }
  return (
    <div class="content">

<div className="content-inner">
      <div class="row gy-4">
        <CointStoreInfo />
      </div>
      </div>
    </div>
  );
};

export default CoinStore;
