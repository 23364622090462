import React from 'react'
import ScrimDetailInfo from './ScrimDetailInfo';
import { useParams } from 'react-router';

const ScrimDetails = () => {
    const params = useParams();
  const scrimId =params?.id
  return (
    <div class="content">
      <div className="content-inner">

    <div class="row gy-4">
      <ScrimDetailInfo id={scrimId}/>
     </div>
    </div>
  </div>
  )
}

export default ScrimDetails