import React, { useEffect, useState } from "react";
import Search from "../../../assets/images/search-icon-svg.svg";
import FriendsShare from "./FriendsShare";
import { url } from "../../../Utilities/url";
import { statusUpdate, updateWinners } from "../../../Utilities/controller";
import { func } from "../../../Utilities/logFunc";
import Error from "../../Common/Error";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Champion from "../../../assets/images/championIcon.png";
import Master from "../../../assets/images/GoldPremium.png";
import { useTranslation } from "react-i18next";
import { eventTracking } from "../../../firebaseAnalytics";
import { events } from "../../../Utilities/appEvents";
const LeaderBoard = ({
  leaderBoardData,
  setRankSubmitted,
  status,
  setIsSSUpload,
  state,
  code,
  scrimData
}) => {
  const { t } = useTranslation();

  const [rankArray, setRankArray] = useState([]);
  const [idArray, setIdArray] = useState([]);
  const [error, setError] = useState("");
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const onChangeHandler = (e, id, ind) => {
    let arr = [...rankArray];
    arr[ind] = parseInt(e.target.value);
    setRankArray([...arr]);
    let arra1 = [...idArray];
    arra1[ind] = id;
    setIdArray([...arra1]);
  };
  const check = () => {
    var rankCount = 0;
    for (let key in rankArray) {
      if (rankArray[key]) {
        rankCount += 1;
      }
      // let capacity = localStorage.getItem('playerCapacity')
      // localStorage.removeItem('playerCapacity')
      // if (rankArray[key] > capacity) {
      //   return "Please Enter upto " + capacity + " rank";
      // }
    }
    if (rankCount == 0) {
      return "Please enter at least one rank";
    }
    let uniqueItems = [...new Set(rankArray)];
    if (uniqueItems.length !== rankArray.length) {
      return "Please enter unique ranks";
    }
  };
  const yesHandler = async () => {
    let id = state;
    try {
      var data = [];
      for (let i = 0; i < rankArray.length; i++) {
        var obj = {
          _id: idArray[i],
          rank: rankArray[i],
        };
        data = [...data, obj];
      }
      var res = await updateWinners({
        _id: id,
        winners: [...data],
      });
      if (res?.data.success) {
        setDisabled(true);
        setRankSubmitted(true);
      } else {
        setError(res?.data?.[0]?.msg);
      }
    } catch (error) {
      func(error);
    }

    // let res = await statusUpdate({
    //   status:'Canceled'
    // }, params?.id)
    // console.log(res)
    // navigate('/private-scrim')
  };
  const handlerCancel = async () => {
    try {
      let res = await statusUpdate(
        {
          status: "Canceled",
        },
        state
      );
        if(scrimData!=undefined && scrimData!=null){
        eventTracking(events.Cancel_Contest, {
          GJ_GameName: localStorage.getItem('activeGame'),
          GJ_GameID: localStorage.getItem('activeGameId'),
          GJ_PrivateContestID:scrimData._id,
          GJ_PrivateContestDate:scrimData.date,
          GJ_PrivateContestTime:scrimData.time
        });
      }
      navigate("/home/private-contest");
      toast.success(t("private_contest.success_cancel_scrim"));
    } catch (error) {
      toast.error(error?.response?.data?.errors[0]?.msg);
    }
  };
  const handlerSubmit = () => {
    func("ramnk", rankArray);
    let err = check();
    if (err) {
      setError(err);
    } else {
      setError("");
      yesHandler();
    }
  };
  useEffect(() => {}, [disabled]);
  return (
    <div class="col-lg-6 border-start privateContestLeaderboard border-gray-50 mt-lg-3 mt-5">
      <div class="ps-lg-3">
        <h4 class="text-primary-color-red bodyBoldFont mb-3 pb-1">
          {t("private_contest.leaderboard")}
        </h4>
        {/* <div class="d-flex mb-3 pb-1"> */}
        {/* <form class="d-flex mb-4 pb-1 ms-auto searchForm" role="search">
                <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                <button type="submit"><img src={Search} alt="" /></button>
              </form> */}
        {/* </div> */}
        <div class="card leaderBoardCard">
          <div class="card-header">
            <div class="row justify-content-between align-items-center">
              <div class="col-5 ps-lg-5 ps-4">
                <h3 class="bodyBoldFont mb-0 mt-2 fs-4">
                  {t("contest_details.gamerji_username")}
                </h3>
              </div>
              <div class="col-auto text-end pe-lg-5 pe-4">
                <h3 class="bodyBoldFont mb-0 mt-2 fs-4 rankBlock">
                  {t("contest_details.rank")}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="card leaderBoardDetailCard mt-1 mb-4">
          <div class="card-body">
            <ul class="list-unstyled mb-0">
              {leaderBoardData?.length > 0
                ? leaderBoardData?.map((ele, ind) => (
                    <li
                      class="row justify-content-between align-items-center firstPrize"
                      key={ind}
                    >
                      {/* userProfile class for premium user */}
                      <div class="col-5 ps-lg-5 ps-4 ">
                        <div class="d-flex align-items-center">
                          <span class="icon me-2 d-block">
                            <img
                              class="icon-50"
                              src={
                                url.imageUrl +
                                ele?.levels?.featuredImage?.default
                              }
                              alt=""
                            />
                          </span>
                          <div>
                            <div
                            className="d-flex justify-content-between w-100 align-items-center"
                              // style={{
                              //   display: "flex",
                              //   justifyContent: "space-between",
                              //   width: "100%",
                              // }}
                            >
                              <h6
                                class="mb-2 text-dark"
                                style={{
                                  display: "block",
                                  width: "10rem",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {" "}
                                {ele?.gamerJiName}
                              </h6>
                              {/* <h6 class="mb-0">{ele?.usersInGameName}</h6> */}
                              {ele?.isPremium ? (
                                // <img className="icon-30 ms-3" src={ele?.planName == "D11_CHAMPION"? Champion : Master}/>
                                <img className="icon-30 ms-3" src={Master}/>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-auto text-end pe-lg-5 pe-4 text-dark">
                        {/* <h5 class="bodyNormalFont mb-0 rankBlock fs-4">3</h5> */}
                        {status == "Completed" ? (
                          <h5 class="bodyNormalFont mb-0 rankBlock text-dark">
                            {ele?.rank ? ele?.rank : "-"}
                          </h5>
                        ) : !setIsSSUpload ? (
                          <input
                            type="text"
                            class="form-control form-control-xs rounded-1 text-center text-dark px-0"
                            style={{ width: "50px", background: "transparent" }}
                            value={"-"}
                            disabled
                          />
                        ) : (
                          <input
                            type="text"
                            class="form-control form-control-xs bg-white rounded-1 text-center text-black px-0"
                            style={{ width: "50px" }}
                            // value={''}
                            defaultValue={""}
                            onKeyDown={(e) =>
                              ["e", "E", "+", "-"].includes(e.key) &&
                              e.preventDefault()
                            }
                            onChange={(e) => onChangeHandler(e, ele._id, ind)}
                          />
                        )}
                      </div>
                    </li>
                  ))
                :
                (<li className="text-center text-dark py-3 noData">{t('notFound.no_records_found')}</li>)
              }
            </ul>
          </div>
        </div>
        {error && (
          <p className="error" style={{ textAlign: "center" }}>
            {error}
          </p>
        )}
        {/* {console.log("*****", status)} */}
        {status == "Canceled" || status == "Completed" ? (
          ""
        ) : (
          <div class="text-center text-white">
            <button
              className={`btn bg-primary-color-dark-blue text-white btn-sm ${
                status !== "In Review" ? "disabled" : ""
              }`}
              style={{ width: "15.625rem" }}
              onClick={handlerSubmit}
            >
              {t('private_contest.submit')}
            </button>
          </div>
        )}

        {status == "Waiting" || status == "Started" ? (
          <FriendsShare code={code}  scrimData={scrimData} onEvent={()=>{
            eventTracking(events.private_contest_Share_With_Friends, {
              GJ_GameName: localStorage.getItem('activeGame'),
              GJ_GameID: localStorage.getItem('activeGameId'),
              GJ_PrivateContestID:scrimData._id,
              GJ_PrivateContestDate:scrimData.date,
              GJ_PrivateContestTime:scrimData.time
            });
          }}/>
        ) : (
          ""
        )}

        {status == "Waiting" || status == "In Review" || status == "Started" ? (
          <div
            className="d-flex justify-content-center mt-4"
            style={{ width: "100%" }}
          >
            <button className=" col-6 btn btn-primary" onClick={handlerCancel}>
            {t('right_hand_drop_down.cancel')}
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default LeaderBoard;
