import React, { useEffect, useState } from "react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { getBanners, bannerAdLog } from "../../Utilities/controller";
import { func } from "../../Utilities/logFunc";
import { routes } from "../../Utilities/routesFb";
import { url } from "../../Utilities/url";
import Loader from "../Common/Loader";
import { logout } from "../../Utilities/logout";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { eventTracking } from "../../firebaseAnalytics";
import { events } from "../../Utilities/appEvents";
const Hero = () => {
  const navigate = useNavigate()
  const [banners, setBanners] = useState([]);
  const [loader, setLoader] = useState(false);

  const fetchBanner = async () => {
    setLoader(true);
    try {
      let data = {
        skip: 0,
        limit: 100,
      };

      const res = await getBanners(data);
      if (res?.data?.list) {
        setBanners(res?.data?.list);
        setLoader(false);
      }
      func(res);
    } catch (error) {
      if (error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_invalid" || error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_expired")
        logout()
      toast.error(error?.response?.data?.errors?.[0]?.msg)
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (banners.length > 0) {
      banners.sort((a, b) => a.order - b.order);
    }
  }, [banners]);

  useEffect(() => {
    fetchBanner();
  }, []);

  const handleSlideClick = (event) => {
    eventTracking(events.BANNER, {
      GJ_BannerID: event._id,
      GJ_ScreenCode: event?.screen?.code
    });

    let screen = event?.screen?.code && event?.screen?.code;
    switch (screen) {
      case "contestsList":
        break;
      case "profile":
        break;
      case "applyPromoCode":
        break;
      case "addABalanceWallet":
        break;
      case "URLRedirect":
        window.open(
          event?.url,
          "_blank" // <- This is what makes it open in a new window.
        );
        break;
      case "tournamentLists":
        break;
      case "coinStore":
        break;
      case "leaderboard":
        break;
      case "applyPromoCodePopup":
        break;
      case "dailyLoginRewards":
        break;
      case "editProfile":
        break;
      case "esportsNewsDetails":
        break;
      case "gamerjiPoints":
        break;
      case "more":
        break;
      case "viewAllTopProfiles":
        break;
      case "rewardStore":
        break;
      case "myRewards":
        break;
      case "collegeLeagues":
        break;
      case "worldOfEsports":
        break;
      case "friends":
        break;
      case "ReferAFriend":
        break;
      case "htmlCategories":
        break;
      default:
        break;
    }
  };
  function bannerRedirection(banner_data) {
    let gameType = banner_data?.gameType;
    var url_redirection = '';
    switch (banner_data?.screen?.code) {
      case 'account':
        url_redirection = '/account';
        break;

      case 'addBalance':
        url_redirection = '';
        break;

      case 'addChangeUsernamePopup':
        url_redirection = '';
        break;

      case 'allGames':
        url_redirection = '/home';
        break;

      case 'applyPromoCodePopup':
        url_redirection = '';
        break;

      case 'coinStore':
        url_redirection = `${routes.home}/${routes.coinStore}`;
        break;

      case 'collegeLeagues':
        url_redirection = '';
        break;

      case 'collegeLeaguesDetails':
        url_redirection = '/collegiate-details';
        break;

      case 'contestDetails':
        url_redirection = `${routes.home}/${routes.contestDetails}`;
        break;

      case 'contestsList':

        localStorage.setItem("activeGameId", gameType?.game?._id);
        localStorage.setItem("activeGame", gameType?.game?.name);
        localStorage.setItem("tournamentIdGame", gameType.game.tournamentGameID);
        localStorage.setItem("activeGameTypeName", gameType?.name);
        localStorage.setItem("activeGameType", JSON.stringify(gameType));
        navigate(`/${routes.home}/${routes.gameTournament}`, { state: gameType?.name });
        // url_redirection = `${routes.home}/${routes.gameTournament}`;
        break;

      case 'customerCare':
        url_redirection = `${routes.home}/${routes.customerCare}`;
        break;

      case 'dailyLoginRewards':
        url_redirection = '';
        break;

      case 'dailyLoginRewardsPopup':
        url_redirection = '';
        break;

      case 'dobAndStateValidation':
        url_redirection = '';
        break;

      case 'editProfile':
        url_redirection = `${routes.home}/${routes.myProfile}`;
        break;

      // Logic for World of eSports Sub Screens
      case 'esportsNewsDetails':
        url_redirection = `${routes.home}/${routes.newsDetails}`;
        break;

      case 'friends':
        url_redirection = '';
        break;

      case 'gameType':
        url_redirection = '';
        break;

      case 'gamerjiPoints':
        url_redirection = ''

      case 'htmlCategories':
        url_redirection = '';
        break;

      case 'htmlGames':
        url_redirection = '';
        break;

      case 'howToPlay':
        url_redirection = '';
        break;

      case 'infoPopup':
        url_redirection = '';
        break;

      case 'joinContestWalletPopup':
        url_redirection = '';
        break;

      case 'joinTournamentWalletPopup':
        url_redirection = '';
        break;

      case 'joinViaInviteCodePopup':
        url_redirection = '';
        break;

      case 'leaderboard':
        url_redirection = '/home/leader-board';
        break;

      case 'legality':
        url_redirection = '';
        break;

      case 'linkBankOrUpiAccount':
        url_redirection = '';
        break;

      case 'mobileAndEmailVerify':
        url_redirection = '';
        break;

      case 'more':
        url_redirection = '/home/get-premium';
        break;

      case 'myContests':
        localStorage.setItem("activeGameId", gameType?.game?._id);
        localStorage.setItem("activeGame", gameType?.game?.name);
        localStorage.setItem("tournamentIdGame", gameType.game.tournamentGameID);
        localStorage.setItem("activeGameType", JSON.stringify(gameType));
        localStorage.setItem("activeGameTypeName", gameType?.name);
        url_redirection = '/my-contest';
        break;

      case 'myRecentTransactions':
        url_redirection = `${routes.home}`;
        break;

      case 'myRewards':
        url_redirection = '/home/reward-store';
        break;

      case 'otherUserProfile':
        url_redirection = '/user-profile';
        break;

      case 'paymentGateway':
        url_redirection = '';
        break;

      case 'paymentOptions':
        url_redirection = '';
        break;

      case 'prizePoolPopup':
        url_redirection = '';
        break;

      case 'profile':
        url_redirection = '/home/my-profile';
        break;

      case 'profileInsights':
        url_redirection = '';
        break;

      case 'raiseAComplaint':
        url_redirection = '/home/customer-care';
        break;

      case 'redeemRewardsPopup':
        url_redirection = '';
        break;

      case 'rewardStore':
        url_redirection = '/home/reward-store';
        break;

      case 'rulesPopup':
        url_redirection = '';
        break;

      case 'selectFavoriteGamePopup':
        url_redirection = '';
        break;

      case 'singleContest':
        url_redirection = '';
        break;

      case 'singleTournament':
        url_redirection = '';
        break;

      case 'squadRegistration':
        url_redirection = '';
        break;

      case 'streamOnGamerji':
        url_redirection = '';
        break;

      case 'ticketDetails':
        url_redirection = '/home/customer-care';
        break;

      case 'tournamentDetails':
        url_redirection = '/home/tounament-details';
        break;

      case 'tournamentLists':
        localStorage.setItem("activeGameId", gameType?.game?._id);
        localStorage.setItem("activeGame", gameType?.game?.name);
        localStorage.setItem("tournamentIdGame", gameType?.game?.tournamentGameID);
        localStorage.setItem("activeGameType", JSON.stringify(gameType));
        localStorage.setItem("activeGameTypeName", gameType?.name);
        // url_redirection = `${routes.home}/${routes.gameTournament}`;
        navigate(`/${routes.home}/${routes.gameTournament}`, { state: "tournamentLists" });
        break;

      case 'tournamentTimingPopup':
        url_redirection = '';
        break;

      case 'URLRedirect':
        url_redirection = '';
        break;

      case 'updateEmailPopup':
        url_redirection = '';
        break;

      case 'verifyEmail':
        url_redirection = '';
        break;

      case 'videos':
        url_redirection = '';
        break;

      // 
      case 'viewAllEsportsNews':
        url_redirection = `${routes.home}/${routes.news}`;
        break;

      case 'viewAllMedals':
        url_redirection = '';
        break;

      // 
      case 'viewAllTopProfiles':
        url_redirection = `${routes.home}/${routes.topProfiles}`;
        break;

      // 
      case 'viewAllVideos':
        url_redirection = '';
        break;

      case 'withdrawal':
        url_redirection = '';
        break;

      case 'worldOfEsports':
        url_redirection = `${routes.home}/${routes.worldOfEsports}`;
        break;

      case 'directDownloadAPKs':
        url_redirection = '';
        break;

    }
    return url_redirection;
  }
  const onChangeBannerAdOwlCarousel = (e) => {
    if (e.activeIndex > 0 && e.activeIndex <= banners.length) {
      let data = {
        type: "1",
        bannerAd: banners[parseInt(e.activeIndex) + 1]?._id,
        platform: "webapp",
      }
      // console.log('View: ', data);

      bannerAdLogAPICall(data);
    }
  };
  const onClickBannerAdOwlCarousel = (value) => {
    // console.log(value)

    let data = {
      type: "2",
      banner: value._id,
      platform: "webapp",
    }
    // console.log('Click: ', data);

    bannerAdLogAPICall(data);
    if (value.url === "" || value.url == null) {
      var url_redirection = bannerRedirection(value);
      console.log('Banner URL: ', url_redirection);
      if (url_redirection) window.location.href = url_redirection;
    } else {
      window.open(value.url, '_blank');
      // // window.open(value.url, '_blank');
      // var url_redirection = bannerRedirection(value);
      // // console.log('Banner URL: ', url_redirection);
      // window.location.href = url_redirection;
    }
  };
  const bannerAdLogAPICall = async (data) => {
    bannerAdLog(data);
  }

  return (
    <div className="col-12">
      <Swiper
        className="dashBoardClubSlider commonSlider"
        spaceBetween={20}
        modules={[Autoplay, Navigation, Pagination]}
        slidesPerView={1}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        onSlideChange={(e) => onChangeBannerAdOwlCarousel(e)}
        // onSwiper={(swiper) => console.log(swiper)}
        navigation
        pagination={{ clickable: true, el: ".swiper-pagination" }}
        breakpoints={{
          576: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
        }}
      >
        {banners.length > 0 &&
          banners.map((data, ind) => (
            <SwiperSlide
              class="swiper-slide"
              key={ind}
              onClick={() => handleSlideClick(data)}
            >
              {" "}
              <img
                className="rounded10"
                // style={{ height: "240px" }}
                key={ind}
                src={url.imageUrl + data?.img?.default}
                alt={data?.screen?.name}
                onClick={() => onClickBannerAdOwlCarousel(data)}
              />
            </SwiperSlide>
          ))}
        <div class="swiper-pagination"></div>
      </Swiper>
      {loader ? <Loader /> : <></>}
    </div>
  );
};

export default Hero;
