import React, { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { routes } from "../../Utilities/routesFb";

import { func } from "../../Utilities/logFunc";
import { getHtml5Settings, getGameList, getExternalGamePBE, getExternalGamePBL } from "../../Utilities/controller";
import { url } from "../../Utilities/url";
import Loader from "../Common/Loader";
import GamePlayed from '../../assets/images/gamesPlayed-icon-svg.svg'
import { logout } from "../../Utilities/logout";
import { toast } from "react-toastify";
import { convertNumberToMillions, kFormatter } from "../../Utilities/kFormatter";
import { useTranslation } from "react-i18next";
import {eventTracking} from "../../firebaseAnalytics"; 
import {events} from "../../Utilities/appEvents"; 

const AllGames = ({ handleClickGame }) => {
  const [freeGames, setFreeGames] = useState([]);
  const [allGames, setAllGames] = useState([]);
  const [pblGame, setPBLGames] = useState([]);
  const navigate = useNavigate();
const [loader, setLoader] = useState(false)
const {t} = useTranslation()
useEffect(()=>{
  EventTracking(); 
},[]);
const EventTracking=()=>{
  eventTracking(events.MENU.All_GAMES);
}
  const getHtml5SettingsList = async () => {
    func("called","sfbdhget5html")
    let payload = {sortby:"order",sort:"asc"}
    try {
      const res = await getHtml5Settings(payload);
      if (res?.data?.list) {
        setFreeGames(res?.data);
      }
      func("freegames",res);
    } catch (error) {
      setLoader(false)
      if (error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_invalid" || error?.response?.data?.errors?.[0]?.code == "middlewares.token_parser.validation.token_expired")
      logout()
            toast.error(error?.response?.data?.errors?.[0]?.msg)
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };

  const getAllGameList = async () => {
    try {
      let data = {
        pagination: {
          pageNo: 1,
          recordPerPage: 100,
          sortBy: "order",
          sortDirection: "asc",
        },
      };
      const res = await getGameList(data);
      if (res?.data?.data) {
        setAllGames(res?.data?.data);
      }
      func("gamelist",res);
    } catch (error) {
      setLoader(false)
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };

  const getPBLGame = async() => {
    let responsePBE = await getExternalGamePBE();
    let responsePBL = await getExternalGamePBL();

    let token = localStorage.getItem("token")

    let temp = []
    if (responsePBE?.data?.success) {
      temp.push({
        id: responsePBE?.data?.data?._id,
        img: url.imageUrl + responsePBE?.data?.data?.featuredImage?.default,
        name: responsePBE?.data?.data?.name,
        tournaments: '',
        redirectLink: token ? responsePBE?.data?.data?.redirectionLink+"?token="+token+"&platform=web" : responsePBE?.data?.data?.redirectionLink,
        join: convertNumberToMillions(responsePBE?.data?.data?.playedCount || 0),
        order: responsePBE?.data?.data?.order
      })
    }

    if (responsePBL?.data?.success) {
      temp.push({
        id: responsePBL?.data?.data?._id,
        img: url.imageUrl + responsePBL?.data?.data?.featuredImage?.default,
        name: responsePBL?.data?.data?.name,
        tournaments: '',
        redirectLink: token ? responsePBL?.data?.data?.redirectionLink+"?token="+token+"&platform=web" : responsePBL?.data?.data?.redirectionLink,
        join: convertNumberToMillions(responsePBL?.data?.data?.playedCount || 0),
        order: responsePBL?.data?.data?.order
      })
    }

    setPBLGames(temp);
  }

  useEffect(() => {
    setLoader(true)
    getHtml5SettingsList();
    getAllGameList();
    getPBLGame();
    setLoader(false)
  }, []);

  const clickFreeHandler = async(name) => {
    navigate("/" + routes.home +'/' +routes.freeGames)
    // handleClickGame(name)
    // navigate("/" + routes.home +'/' +routes.gameTournament)
  };

  const clickHandler = (name, id, bannerImage, tournamentId) => {
    // handleClickGame(name, id);
    localStorage.setItem("activeGame", name);
    localStorage.setItem("activeGameId", id);
    localStorage.setItem("tournamentIdGame", tournamentId);

    localStorage.setItem("activeGameBannerImage", bannerImage?.default);
    navigate("/" + routes.home + "/" + routes.gameTournament);
  };

  return (
    <>
    {
       
      <div class="col-12">
      <div class="title mb-3">
        <h2 class="h3 d-flex align-items-center">
          <span class="titleIcon me-3">
            <img src="assets/images/game-icon-svg.svg" alt="" />
          </span>
          <span>{t('home.all_games')}</span>
        </h2>
      </div>
      <div class="row g-20 allGames-row">
        {freeGames?.list?.length > 0 &&
          freeGames?.list?.map((data, indp) => (
            <div class="col-xxl-five col-lg-3 col-md-4 col-6" key={indp}  >
              <div
                class="card gameCard card-border"
                style={{height:"100%"}}
                onClick={() => clickFreeHandler(data?.title)}
              >
                <div class="card-body" style={{paddingTop:'1rem'}}>
                  <div class="card-title text-center">
                    {func("lengh",data?.title?.length>5)}
                    
                      <h4 class="bodyExtraBoldFont" style={{marginBottom:'1rem', width:'100%'}}>{data?.title}</h4>
                    
                    
                  </div>
                  <div class="card-img">
                    <img
                      class="w-100"
                      src={url.imageUrl + data?.featuredImage?.default}
                      alt={data?.title}
                    />
                  </div>
                  <div class="card-content" 
                  // style={{padding:'8px'}}
                  >
                    <div class="d-flex align-items-center">
                      <span class="me-2 card-icon">
                        <img
                          src={GamePlayed}
                          alt=""
                        />
                      </span>
                      <h4 class="mb-0 text-ellipsis-allgames"  
                      style={{display:'block',
                      // width:'150px',
                      whiteSpace: "nowrap", overflow: "hidden",
  textOverflow: "ellipsis"}}>
    {/* {kFormatter(freeGames?.totalJoinedPlayers)} Played */}
     {kFormatter(freeGames?.totalJoinedPlayers)} {t('home.played')}
  </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        {pblGame.length > 0 && pblGame.some(item => item.order === 0) &&
          pblGame.map((data, indp) => (
            <div class="col-xxl-five col-lg-3 col-md-4 col-6" key={indp} >
              <a
                class="card gameCard card-border"
                style={{height:"100%",textDecoration: "none"}}
                href={`${data?.redirectLink}`} target="blank"
              >
                <div class="card-body" style={{paddingTop:'1rem'}}>
                  <div class="card-title text-center">
                    <h4 class="bodyExtraBoldFont" style={{marginBottom:'1rem',display:'block',width:'100%',whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                      {
                      data?.name}
                      </h4>
                  </div>
                  <div class="card-img" style={{cursor:'pointer', borderRadius:'10px'}}>
                    <img
                      class="w-100"
                    
                      style={{borderRadius:'10px'}}
                      src={data?.img}
                      alt={data?.name}
                    />
                  </div>
                  <div class="card-content" style={{padding:'8px'}}>
                    <div class="d-flex align-items-center">
                      <span class="me-2 card-icon">
                        <img
                          src={GamePlayed}
                          alt=""
                        />
                      </span>
                      
                      <h4 class="mb-0 text-ellipsis-allgames" style={{display:'block',
                      // width:'100%',
                      whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                      {data?.join}  {t('home.played')}
                      </h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ))
        }
        {allGames.length > 0 &&
          allGames.map((data, indp) => (
            <div class="col-xxl-five col-lg-3 col-md-4 col-6" key={indp} >
              <div
                class="card gameCard card-border"
                style={{height:"100%"}}
                onClick={() =>
                  clickHandler(data?.name, data?._id, data?.bannerImage, data?.tournamentGameID)
                }
              >
                <div class="card-body" style={{paddingTop:'1rem'}}>
                  <div class="card-title text-center">
                    <h4 class="bodyExtraBoldFont" style={{marginBottom:'1rem',display:'block',width:'100%',whiteSpace: "nowrap", overflow: "hidden",
  textOverflow: "ellipsis"}}>
                      {
                      // data?.name?.length > 17 ? <marquee>{data?.name}</marquee>
                      // : 
                      data?.name}
                      </h4>
                  </div>
                  <div class="card-img" style={{cursor:'pointer', borderRadius:'10px'}}>
                    <img
                      class="w-100"
                    
                      style={{borderRadius:'10px'}}
                      src={url.imageUrl + data?.featuredImage?.default}
                      alt={data?.name}
                    />
                  </div>
                  <div class="card-content" style={{padding:'8px'}}>
                    <div class="d-flex align-items-center">
                      <span class="me-2 card-icon">
                        <img
                          src={GamePlayed}
                          alt=""
                        />
                      </span>
                      <h4 class="mb-0 text-ellipsis-allgames" style={{display:'block',
                      // width:'100%',
                      whiteSpace: "nowrap", overflow: "hidden",
  textOverflow: "ellipsis"}}>
    {/* {convertNumberToMillions(data?.playedCount)} Played */}
    {/* {console.log(convertNumberToMillions(data?.playedCount))} */}
    {convertNumberToMillions(data?.playedCount)}  {t('home.played')}
    </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {pblGame.length > 0 && !pblGame.some(item => item.order === 0) &&
          pblGame.map((data, indp) => (
            <div class="col-xxl-five col-lg-3 col-md-4 col-6" key={indp} >
              <a
                class="card gameCard card-border"
                style={{height:"100%",textDecoration: "none"}}
                href={`${data?.redirectLink}`} target="blank"
              >
                <div class="card-body" style={{paddingTop:'1rem'}}>
                  <div class="card-title text-center">
                    <h4 class="bodyExtraBoldFont" style={{marginBottom:'1rem',display:'block',width:'100%',whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                      {
                      data?.name}
                      </h4>
                  </div>
                  <div class="card-img" style={{cursor:'pointer', borderRadius:'10px'}}>
                    <img
                      class="w-100"
                    
                      style={{borderRadius:'10px'}}
                      src={data?.img}
                      alt={data?.name}
                    />
                  </div>
                  <div class="card-content" style={{padding:'8px'}}>
                    <div class="d-flex align-items-center">
                      <span class="me-2 card-icon">
                        <img
                          src={GamePlayed}
                          alt=""
                        />
                      </span>
                      
                      <h4 class="mb-0 text-ellipsis-allgames" style={{display:'block',
                      // width:'100%',
                      whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                      {data?.join}  {t('home.played')}
                      </h4>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ))
        }
      </div>
      {loader ?  <Loader /> : <></>}
    </div>
    }
    </>
  );
};

export default memo(AllGames);
