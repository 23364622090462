import React from "react";
import Banner from "./Banner";
import ContestList from "./ContestList";

const Contest = () => {
  const gameName=localStorage.getItem("activeGame")
  const gameId=localStorage.getItem("activeGameId")
  return (
    <div class="content">
      <div className="content-inner">

      <div class="row gy-4">
        <Banner />
        <ContestList gameId={gameId} />
        </div>
      </div>
    </div>
  );
};

export default Contest;
