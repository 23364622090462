import jsCookie from "js-cookie";
import i18next from "./Translations/i18n";

export const logout = () => {
  // i18next.changeLanguage('en');  
    localStorage.removeItem("country");
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    localStorage.removeItem("gamerjiToken");
    localStorage.removeItem("activeGame");
    localStorage.removeItem("activeGameId");
    // jsCookie.remove("d11Lang");
    // jsCookie.remove("d11LangCode");


    // navigate("/" + routes.login);
    localStorage.clear();
    window.history.replaceState(null, "", "/");
    window.location.href = "/";
  };