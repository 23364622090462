export const  countryCodeLang = {
    '+91':"en",
    "+971":"ar",
    "+966":"ar",
    "+63":"fl"
}
export const  countryCodeLangName = {
    'hi':"हिंदी",
    "ar":"عربي",
    "fl":"Filipino",
    'en':'English'
}
