import React, { useEffect, useState } from "react";
import { json, useNavigate, useLocation } from "react-router";
import TournamentModal from "./TournamentModal";
import TournamentConfirmationModal from "./TournamentConfirmationModal";
import { func } from "../../../Utilities/logFunc";
import TournamentStructure from "./TournamentStructure";
import WinningBreakUp from "./WinningBreakUp";
import NotFound from "../../../assets/images/noContestFound.png";
import NoTournamentFound from "../../../assets/images/noTournamentFound.png";

import {
  getContest,
  getTournament,
  requestWalletUsageLimit,
  walletUsageLimit,
  getUserInGameName,
  getAccountProfile,
  getGameTypeLists,
  getHeaderApi,
} from "../../../Utilities/controller";
import Banner from "../Contest/Banner";
import GameTypeTab from "../Contest/GameTypeTab";
import RulesModal from "./RulesModal";
import ContestStructure from "../Contest/ContestStructure";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContestConfirmationModel from "../Contest/ContestConfirmationModel";
import Loader from "../../Common/Loader";
import TeamRegisterModal from "./TeamRegisterModal";
import BasicProfile from "./BasicProfile";
import BasicProfileContest from "./BasicProfileContest";
import BasicProfilePrivateContest from "./BasicProfilePrivateContest";
import { getGameTypeList } from "../../../Utilities/controller";
import ShowFreeContestModal from "../../Subscription/ShowFreeContestModal";
import { useProfileDataContext } from "../ProfileContext";
import { useTranslation } from "react-i18next";
import { eventTracking } from "../../../firebaseAnalytics";
import { events } from "../../../Utilities/appEvents";
import moment from "moment";
const GameTournament = () => {
  var user_data = JSON.parse(localStorage.getItem("profile"))?.item;
  const gameId = localStorage.getItem("activeGameId");
  const gameName = localStorage.getItem("activeGame");
  const bannerImage = localStorage.getItem("activeGameBannerImage");
  const { profileData, updateProfileData, liteProfile } = useProfileDataContext()

  const { t } = useTranslation()

  const navigate = useNavigate();
  const location = useLocation();

  // const [userDataInfo, setUserData] = useState("");

  const [showTournamentModal, setShowTournamentModal] = useState(false);
  const [showTournamentConfirmationModal, setShowTournamentConfirmationModal] =
    useState(false);

  const [selectedMatch, setMatch] = useState(null);

  const [showWinningModal, setShowWinningModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [tournaments, setTournaments] = useState([]);
  const [showRulesModal, setShowRulesModal] = useState(false);

  const [showTeamRegisterModal, setShowTeamRegisterModal] = useState(false);
  const [showBasicProfileModal, setShowBasicProfileModal] = useState(false);
  const [showBasicProfileContestModal, setShowBasicProfileContestModal] =
    useState(false);
  const [showPremiumPopupContest, setShowPremiumPopupContest] = useState(false);
  const [showPremiumPopupTournament, setShowPremiumPopupTournament] =
    useState(false);

  const [
    showBasicProfilePrivateContestModal,
    setShowBasicProfilePrivateContestModal,
  ] = useState(false);

  const [contests, setContests] = useState([]);
  const [active, setActive] = useState(0);
  const [gameType, setGameType] = useState([]);
  const [activeId, setActiveId] = useState(0);
  const [gameTypeLength, setGameTypeLength] = useState(0);

  const [selected, setselected] = useState([]);
  const [walletData, setWalletData] = useState([]);
  const [activeTournament, setActiveTournament] = useState(null);
  const [activeCurrencyWallet, setActiveCurrencyWallet] = useState(null);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [showJoinModal, setShowJoinModal] = useState(null);
  const [userInGameName, setUserInGameName] = useState("");
  const [showContestConfirmationModal, setShowContestConfirmationModal] =
    useState(false);

  const [index, setIndex] = useState(0);
  const [data, setData] = useState([]);
  const [currData, setCurrData] = useState([]);
  const [totalContest, setTotalContest] = useState(0);
  const [contestPage, setContestPage] = useState(1);
  const [totalTournament, setTotalTournament] = useState(0);
  const [tournamentPage, setTournamentPage] = useState(1);

  const handlerJoin = async (data) => {
    // console.log(data);
    setLoading(true);
    setActiveTournament(data);
    setSelectedTournament(data);

    // localStorage.setItem("activeGameId", data?.gameAry?.[0]?._id);
    // localStorage.setItem("activeGame", data?.gameAry?.[0]?.name);
    const payload = {
      search: {
        user: profileData?.item?.user_id || user_data?.user_id,
        game: data?.gameAry?.[0]?._id,
      },
    };
    try {
      eventTracking(events.Tournament_JoinNow, {
        GJ_GameName: data?.gameAry?.[0]?.name, GJ_GameID: data?.gameAry?.[0]?._id, GJ_TournamentID: data?._id, GJ_TournamentDate: data?.startDate, GJ_TournamentTime: data?.startDate
      });

      getWallet(data);
      // const isName = await getUserInGameName(payload);
      //  const isName =  getUserInGameNameData(data?.gameAry?.[0]?._id);
      let isName = false
      if (data?.userInGameName) {
        isName = true
        setUserInGameName(data?.userInGameName)
      }
      else {
        let ingamenamesearch = await getUserInGameName(payload);
        if (ingamenamesearch?.data?.success) {
          isName = true
        }
        else {
          isName = false
        }
      }

      console.log("isName", isName)

      if (isName) {


        // console.log("here 1")
        // if (userInGameName) {
          var today = new Date();
          // console.log( today , data)
          let roundaDateTime = data?.rounds[0]?.matches
          var tempRound = roundaDateTime?.[roundaDateTime.length - 1];
          console.log(tempRound )
          var cdate = moment(tempRound.date).format("y-MM-DD");
          var ctime = moment(tempRound.time).format("HH:mm:ss");
          var contestDateTime = moment(cdate + " " + ctime);
          console.log( today , new Date(contestDateTime), today > new Date(contestDateTime))
          if (today > new Date(contestDateTime)) {
            toast.error(t('error_Oops_This_tournament_has_already_started'))
          }
         else
        setShowTournamentModal(true);
        // } else {
        //   setShowBasicProfileModal(true);
        // }
        setLoading(false);
      }
      else {
        setLoading(false);
        console.log("here")
        setShowBasicProfileModal(true);
      }
    } catch (error) {
      setLoading(false);
      func("getWalletUsageLimit error", error?.response?.data);
      if (
        error?.response?.data?.errors?.[0]?.code == "users.validation.not_found"
      )
        setShowBasicProfileModal(true);
      else toast.error(error?.response?.data?.errors?.[0]?.msg);
    }
  };

  // const getUserInGameNameData = async (gameType) => {
  //   setLoading(true);
  //   try {
  //     let data = {
  //       search: {
  //         user: user_data.user_id,
  //         game: localStorage.getItem("activeGameId"),
  //       },
  //     };

  //     const res = await getUserInGameName(data);
  //     if (res?.data?.data) {
  //       console.log(res?.data?.data)
  //       let index = res?.data?.data.findIndex(
  //         (obj) => obj.game == gameType?.game?._id
  //       );

  //       if (index > -1) {
  //         setUserInGameName(res?.data?.data[index].userINGameName || "");
  //       }
  //       // localStorage.setItem(
  //       //   "activeInNameGame",
  //       //   res?.data?.data?.[0]?.userINGameName
  //       // );
  //       setLoading(false);
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     // if(error?.response?.data?.errors?.[0]?.code == "users.validation.not_found")
  //     // toast.error(error?.response?.data?.errors?.[0]?.msg )
  //     // toast.error(error?.response?.data?.errors?.[0]?.msg )

  //     return false;
  //   }
  // };

  const handleJoinContest = async (item) => {
    setselected(item);
    let payload = {
      contest: item?._id,
      currencyCode: item?.currency[0]?.inCurrency?._id,
      entryFee: item?.entryFee,
      type: "contest",
    };
    try {
      const res = await requestWalletUsageLimit(payload);
      setWalletData(res?.item);
      // localStorage.setItem("activeGameId", item?.game);
      // localStorage.setItem("activeGame", item?.gameType);
      const data = {
        search: {
          user: profileData?.item?.user_id || user_data?.user_id,
          game: item?.gameAry?.[0]?._id,
        },
      };
      // console.log(item?.userInGameName)
      let isName = false
      if (item?.userInGameName) {
        isName = true
        setUserInGameName(item?.userInGameName)
      }
      else {
        let ingamenamesearch = await getUserInGameName(data);

        if (ingamenamesearch?.data?.success) {
          isName = true
        }
        else {
          isName = false
        }
      }
      // console.log(isName);

      setLoading(true);

      if (isName) {

        if (res?.success && res?.item) {
          // console.log("*********", res)

          setTimeout(async function () {
            if (item?.isJoined) {
              navigate("/home/contest-details/" + item._id);
            } else {
              setLoading(false);
              setShowContestConfirmationModal(true);
            }
            // else {
            //   if (userInGameName) {
            //     setShowContestConfirmationModal(true);
            //   } else {
            //     setShowBasicProfileContestModal(true);
            //   }
            // }
          }, 500);
          setLoading(false);
        } else {
          func("getWalletUsageLimit err");
        }
      } else {
        setLoading(false);
        setShowBasicProfileContestModal(true);
      }
    } catch (error) {
      setLoading(false);
      func("getWalletUsageLimit error", error?.response?.data);
      if (
        error?.response?.data?.errors?.[0]?.code == "users.validation.not_found"
      )
        setShowBasicProfileContestModal(true);
      else toast.error(error?.response?.data?.errors?.[0]?.msg);
    }
  };

  const handlerActive = (ind) => {
    setActive(ind);
    func("data168", ind);
    // setCurrData(data[ind])
  };

  const handlerGameTypeLength = (type) => {
    setGameTypeLength(type);
  };

  const handlerWinning = (data) => {
    setSelectedTournament(data);
  
    eventTracking(events.Tournamnet_Winners, {
      GJ_GameName: localStorage.getItem("activeGame"),
      GJ_GameID: localStorage.getItem("activeGameId"),
      GJ_ContestID: data._id,
      GJ_GameTypeID:data.gameTypeAry._id,
    });
    setShowWinningModal((prev) => true);
  };

  const handleClickGameType = (name, id) => {
    // func("handleClickGameType", name, id);
    // setActive(name);
    // setActiveId((prev) => id);
  };

  const getTournamentList = async (page) => {
    try {
      let data = {
        skip: page,
        limit: 9,
        filter: {
          tournamentType: "gamerji",
          user: profileData?.item?.user_id || user_data?.user_id,
          game: gameId,
        },
      };
      setLoading(true);
      const res = await getTournament(data);
      func();
      if (res?.data?.data) {
        if (page === 1) {
          setTournaments(res?.data?.data);
        } else {
          setTournaments([...tournaments, ...res?.data?.data]);
        }
        setTotalTournament(res?.data?.totalRecord)
        // setTournaments(res?.data?.data);
      }
      setLoading(false);

      // func(res);
    } catch (error) {
      func("getTournament error", error?.response?.data?.errors?.[0]?.msg);
      toast(error?.response?.data?.errors?.[0]?.msg);
      setLoading(false);
    }
  };

  const getGameTypeByGameList = async () => {
    try {
      let data = {
        search: {
          gameId: gameId,
        },
        pagination: {
          sortby: "order",
          sort: "asc",
        },
      };

      const res = await getGameTypeLists(data);
      if (res?.data?.data) {
        var tempGameTypes = [];

        tempGameTypes.push(...res?.data.data);

        setGameType(tempGameTypes);

        // setActiveId(res?.data?.data[0]._id);
        // setTypeLength(res?.data?.data?.length)
        // setActive(res?.data?.data?.length)
        func("tempTypes", tempGameTypes);
        handlerGameTypeLength(res?.data?.data?.length || 0);

        // func("game type 257", tempGameTypes[0]._id);
        if (tempGameTypes.length > 0)
         getContestList(tempGameTypes[0], contestPage);
        if (location?.state == 'tournamentLists') {
          setActive(tempGameTypes?.length);
        } else {
          if (location?.state) {
            let index = res?.data.data.findIndex((a) => a.name === location?.state)
            setActive(index);
          }
        }
        // console.log(tempGameTypes)

        if (tempGameTypes.length == 0)
          getTournamentList();
        // setActive(r);
        
        // setLoading(false);
      } else {
      }
    } catch (error) {
      if (
        error?.response?.data?.errors?.[0]?.data ==
        "events.validation.MAX_FREE_TRANSACTION"
      ) {
        setShowPremiumPopupContest(true);
      } else {
        toast.error(error?.response?.data?.errors?.[0]?.msg);
       
      }
      setLoading(false);
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };

  useEffect(() => {
    setLoading(true);
    getGameTypeByGameList();
    // setUserData(liteProfile);

    // getContestList();
    // getTournamentList();
  }, []);

  const getWallet = async (dataItem) => {
    try {
      let data = {
        event: dataItem?._id,
        type: "event",
        currencyCode: dataItem?.currency?.[0]?.inCurrency?._id,
        entryFee: dataItem?.entryFee,
      };

      const res = await walletUsageLimit(data);
      if (res?.data?.item) {
        setActiveCurrencyWallet(res?.data?.item);
      }
    } catch (error) {
      func("getTournament error", error?.response?.data?.errors?.[0]?.msg);
      toast.error(error?.response?.data?.errors?.[0]?.msg);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   //   setLoading(true);
  //   //   func("activeId", activeId)
  //   //   if(active== 'tournament')
  //   //     getTournamentList();
  //   //  else
  //   //  {
  //   //   setContests([])
  //   //   getContestList();
  //   //  }
  //   //   setLoading(false);
  // }, [activeId]);

  const getContestList = async (gameType, page) => {
    setLoading(true);
    // func("active", active);
    // console.log(profileData?.item?.user_id, user_data?.user_id)
    try {
      // func("data 290", data);
      let dataTemp = [];

      // for (let i = 0; i < data.length; i++) {
      let payload = {
        search: {
          user: profileData?.item?.user_id || user_data?.user_id,
          game: gameId,
          gameType: gameType?._id,
        },
        pagination: {
          pageNo: page,
          recordPerPage: 9,
          sortBy: "dateTime",
          sortDirection: "asc",
        },
      };
      // setLoading(true);
      const res = await getContest(payload);

      // }
      console.log("contestttttttt Data", data);
      if (page === 1) {
        setData(res?.data);
      } else {
        setData([...data, ...res?.data]);
      }

      // setData(res?.data);
      setTotalContest(res.totalRecord)
      setLoading(false);
    } catch (error) {
      func("getContest error", error?.response?.data?.errors?.[0]?.msg);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   // console.log("gameTypeLength", gameTypeLength);
  //   // console.log("gameTypeLength active", active);
  //   getAccountProfileDetails();
  // }, []);

  useEffect(() => {
    // console.log("game type 350", gameType[active]);
    // console.log("game type 350", active);
    if(gameType?.length > 0){
    if (gameType[active] === undefined && active == gameType?.length) {
      let totalPage = 1;
      setTournamentPage(totalPage);
      getTournamentList(totalPage);
    } else {
      let totalCount = 1;
      setContestPage(totalCount);
      getContestList(gameType[active], totalCount)

    };
  }
  }, [active]);

  useEffect(() => { }, [showTournamentConfirmationModal]);

  //if (isLoading) return <Loader />;
  return (
    <>
      {/* <ToastContainer /> */}
      <div className="content">
        <div className="content-inner">
          <div className="row gy-4">
            <Banner bannerImage={bannerImage} />

            <div className="col-12 commonTab tournamentTypeTab">
              {/* <GameTypeTab
              handleClickGameType={handleClickGameType}
              gameId={localStorage.getItem("activeGameId")}
              setGameTypeLength={setGameTypeLength}
              // setActiveTab={setActive}
              gameType={gameType}
              handlerActive={handlerActive}
            /> */}
              <ul
                className="nav nav-pills  d-flex justify-content-between auth-nav"
                id="tournamentTypeTab"
                role="tablist"
              >
                {gameType.map((item, ind) => (
                  <li
                    className="nav-item col d-grid"
                    role="presentation"
                    onClick={() => {
                      handlerActive(ind);
                      setActive(ind);
                    
                      // handlerActive(item, ind)
                    }}
                    key={ind}
                  >
                    <button
                      className={`nav-link ${ind == active ? "active" : ""}`}
                      role="tab"
                      aria-controls="solo-tab-pane"
                      aria-selected="true"
                      style={{ width: '100%' }}
                    >
                      {item?.name}
                    </button>
                  </li>
                ))}
                <li
                  className="nav-item col d-grid"
                  role="presentation"
                  onClick={() => handlerActive(gameType?.length)}
                // key={typeLength}
                >
                  <button
                    className={`nav-link ${active == gameType?.length ? "active" : ""
                      }`}
                    role="tab"
                    aria-controls="solo-tab-pane"
                    aria-selected="true"
                    style={{ width: '100%' }}
                    onClick={()=>{
                      
                      eventTracking(events.tournaments, {
                        GJ_GameName: localStorage.getItem('activeGame'),
                         GJ_GameID:  localStorage.getItem('activeGameId'),
                      });
                
                    }}
                  >
                    {t('header.tournaments')}
                  </button>
                </li>
              </ul>
              {func("contest list", active)}
              <div className="tab-content" id="tournamentTypeTabContent">
                {active == gameType?.length ? (
                  tournaments.length > 0 ? (
                    <div className="row gx-3 gy-5">
                      {tournaments?.map((item, ind) => {
                        if (item) {
                          return (
                            <div
                              className="col-lg-4"
                              style={{ cursor: "pointer" }}
                              key={ind}
                            >
                              <TournamentStructure
                                handlerJoin={() => handlerJoin(item)}
                                handlerWinning={() => handlerWinning(item)}
                                tournaments={item}
                                setShowRulesModal={setShowRulesModal}
                                setSelectedTournament={setSelectedTournament}
                                setShowJoinModal={setShowJoinModal}
                              />
                            </div>
                          );
                        }

                      })}
                      {tournaments?.length < totalTournament && (
                        <div class="d-flex justify-content-center mt-5">
                          <button
                            class="btn btn-primary col-3 text-center mt-5"
                            onClick={() => {
                              let totalPages = parseInt(tournamentPage) + 1;
                              setTournamentPage(totalPages);
                              getTournamentList(totalPages)

                            }}
                          >
                            Load More
                          </button>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div class="row justify-content-center">
                      <div className="col-lg-5">
                        <img
                          className="img-fluid winningBreakupModal"
                          src={NoTournamentFound}
                          alt=""
                        />
                      </div>
                    </div>
                  )
                ) : data?.length > 0 ? (
                  <div className="row gx-3 gy-5">
                    {data?.map((item) => {
                      return (
                        <div className="col-lg-4 col-md-6" key={item?._id}>
                          <ContestStructure
                            handleJoinContest={() => handleJoinContest(item)}
                            handlerWinning={handlerWinning}
                            tournaments={item}
                            setselected={setselected}
                          />
                        </div>
                      );
                    })}
                    {data?.length < totalContest && (
                      <div class="d-flex justify-content-center mt-5">
                        <button
                          class="btn btn-primary col-3 text-center mt-5"
                          onClick={() => {
                            let totalCount = parseInt(contestPage) + 1;
                            setContestPage(totalCount);
                            getContestList(gameType[active], totalCount)

                          }}
                        >
                          Load More
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div class="text-center">
                    {func("active 327", active)}
                    <img
                      className="img-fluid"
                      src={
                        active == "tournament" ? NoTournamentFound : NotFound
                      }
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showTournamentModal && (
        <TournamentModal
          showTournamentModal={showTournamentModal}
          setShowTournamentModal={setShowTournamentModal}
          setShowTournamentConfirmationModal={
            setShowTournamentConfirmationModal
          }
          setMatch={setMatch}
          tournaments={activeTournament}
          userInGameName={userInGameName}

        />
      )}

      {showTournamentConfirmationModal && (
        <TournamentConfirmationModal
          showTournamentConfirmationModal={showTournamentConfirmationModal}
          setShowTournamentConfirmationModal={
            setShowTournamentConfirmationModal
          }
          tournaments={selectedTournament}
          walletData={activeCurrencyWallet}
          match={selectedMatch}
          userInGameName={
            userInGameName || localStorage.getItem("activeInNameGame")
          }
          setShowPremiumPopupTournament={setShowPremiumPopupTournament}
        />
      )}
      {func("user name contest 512", userInGameName)}
      {showContestConfirmationModal && (
        <ContestConfirmationModel
          showContestConfirmationModal={showContestConfirmationModal}
          setShowContestConfirmationModal={setShowContestConfirmationModal}
          tournaments={selected}
          walletData={walletData}
          userInGameName={
            userInGameName || localStorage.getItem("activeInNameGame")
          }
          setShowPremiumPopupContest={setShowPremiumPopupContest}
        />
      )}

      {showWinningModal && (
        <WinningBreakUp
          showWinningModal={showWinningModal}
          setShowWinningModal={setShowWinningModal}
          selectedTournament={selectedTournament}
        />
      )}
      {showRulesModal && (
        <RulesModal
          showRulesModal={showRulesModal}
          setShowRulesModal={setShowRulesModal}
          selectedTournament={selectedTournament}
        />
      )}

      {showTeamRegisterModal && (
        <TeamRegisterModal
          showTeamRegisterModal={showTeamRegisterModal}
          setShowTeamRegisterModal={setShowTeamRegisterModal}
          setShowPremiumPopupTournament={setShowPremiumPopupTournament}
        />
      )}

      {/* {console.log("showBasicProfileModal", showBasicProfileModal)} */}

      {showBasicProfileModal && (
        <BasicProfile
          showBasicProfileModal={showBasicProfileModal}
          setShowBasicProfileModal={setShowBasicProfileModal}
          game={localStorage.getItem("activeGameId")}
          setShowTournamentModal={setShowTournamentModal}
          setUserInGameName={setUserInGameName}
        />
      )}

      {showBasicProfileContestModal && (
        <BasicProfileContest
          showBasicProfileModal={showBasicProfileContestModal}
          setShowBasicProfileModal={setShowBasicProfileContestModal}
          game={localStorage.getItem("activeGameId")}
          setShowContestConfirmationModal={setShowContestConfirmationModal}
          setUserInGameName={setUserInGameName}
        />
      )}
      {showPremiumPopupContest && (
        <ShowFreeContestModal
          title={"contest"}
          showPremiumPopupContest={showPremiumPopupContest}
          tournaments={selected}
          setShowPremiumPopupContest={setShowPremiumPopupContest}
          
        />
      )}
      {showPremiumPopupTournament && (
        <ShowFreeContestModal
          title={"tournament"}
          tournaments={selectedTournament}
          showPremiumPopupContest={showPremiumPopupTournament}
          setShowPremiumPopupContest={setShowPremiumPopupTournament}
        />
      )}

      {isLoading ? <Loader /> : <></>}
    </>
  );
};

export default GameTournament;
