import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfUse from "./TermsOfUse";
import { useTranslation } from "react-i18next";

const LegalityInfo = () => {
  let [type, setType] = useState("terms-of-use");
  const {t} = useTranslation()

  const loadMoreBtnClick = (type) => {
    setType(type);
  };

  return (
    <div class="col-12 mt-5 pt-lg-4 commonTab leaderBoardTab">
      <ul class="nav nav-tabs row" id="leaderBoardTab" role="tablist">
        <li class="nav-item col d-grid" role="presentation">
          <button
            class="nav-link active"
            id="today-tab"
            data-bs-toggle="tab"
            data-bs-target="#today-tab-pane"
            type="button"
            role="tab"
            aria-controls="today-tab-pane"
            aria-selected="true"
            onClick={() => loadMoreBtnClick("terms-of-use")}
          >
            {t('right_hand_drop_down.terms_of_use')}
          </button>
        </li>
        <li class="nav-item col d-grid" role="presentation">
          <button
            class="nav-link"
            id="byWeek-tab"
            data-bs-toggle="tab"
            data-bs-target="#byWeek-tab-pane"
            type="button"
            role="tab"
            aria-controls="byWeek-tab-pane"
            aria-selected="false"
            onClick={() => loadMoreBtnClick("privacy-policy")}
          >
             {t('right_hand_drop_down.privacy_policy')}
          </button>
        </li>
      </ul>
      <div class="tab-content" id="leaderBoardTabContent">
        <div
          class="tab-pane fade show active"
          id="today-tab-pane"
          role="tabpanel"
          aria-labelledby="today-tab"
          tabindex="0"
        >
          <div class="d-flex">
            <TermsOfUse type={type} />
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="byWeek-tab-pane"
          role="tabpanel"
          aria-labelledby="byWeek-tab"
          tabindex="0"
        >
          <div class="d-flex">
            <PrivacyPolicy type={type} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegalityInfo;
