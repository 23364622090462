import React, { useState } from "react";
import LandingPage from "./LandingPage";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Formik, Form, Field, useField } from "formik";
import * as Yup from "yup";

import { createContactUs } from "../../Utilities/controller";
import { Link } from "react-router-dom";
import Batelco from './images/logo-batelco.png.webp'
import Mobily from './images/logo-mobily.png.webp'
import Jawwal from './images/logo-jawwal.png.webp'
import Zain from './images/logo-zain.png.webp'
import CrediMax from './images/logo-credimax.png.webp'
const Contact = () => {
  const [active, setActive] = useState(1);
  const [errorName, setErrName] = useState()
  const [errorEmail, setErrEmail] = useState()
  const [errorPhone, setErrPhone] = useState()
  const [errorCompany, setErrCompany] = useState()
  const [errorMessage, setErrMessage] = useState()



  const [frmInitialValues, setFrmInitialValues] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    message: "",
  });

  const customerCareSchema = Yup.object().shape({
    name: Yup.string().required("Name is required."),
    email: Yup.string()
      .email("Please enter valid email id")
      .required("Email id is required."),
    phone: Yup.string().min(8).max(15).required("Phone Number is required."),
    company: Yup.string(),
    message: Yup.string().required("Message is required."),
  });

  const handleSubmit = async () => {
    let formdata = { ...frmInitialValues };
    let err = false
    console.log(formdata)
    if(!formdata.name || formdata.length == 0){
      setErrName("Name is required")
      err= true
    }
    else{
      setErrName('')
      err= false

    }
    
    
    if(!formdata.email || formdata.length == 0){
      setErrEmail("Email is required")
      err= true

    }
    else{
      setErrEmail("")
      err= false

    }
    if(!formdata.phone || formdata.length == 0){
      setErrPhone("Phone Number is required")
      err= true

    }
    else{
      setErrPhone('')
      err= false

    }
    if(!formdata.company || formdata.length == 0){
      setErrCompany("Company is required")
      err= true

    }
    else{
      setErrCompany('')
      err= false


    }
    if(!formdata.message || formdata.length == 0){
      setErrMessage("Message is required")
      err= true

    }
    else{
      setErrMessage('')
      err= false

    }
    if (!err) {

    
    try {
      const res = await createContactUs(formdata);
      if (res?.data?.success) {
        
        formdata.name = "";
        formdata.email = "";
        formdata.phone = "";
        formdata.company = "";
        formdata.message = "";
        setFrmInitialValues(formdata);
        toast.success("Contact us submitted successfully");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      // toast.error(error?.response?.data?.errors?.[0]?.msg);
    }
  }
  };

  const MyTextArea = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
      <>
        <label htmlFor={props.id || props.name}>{label}</label>
        <textarea class="form-control" {...field} {...props} />
        {meta.touched && meta.error ? (
          <div className="error">{meta.error}</div>
        ) : null}
      </>
    );
  };

  return (
    <LandingPage active={active}>
      <ToastContainer />
      <div class="container">
        <section class="section-explore">
          <div class="row ">
            <div class="explore-text-area">
            <h1 class="heading-primary text-center"><span class="mr-2">UNITING PLAYERS</span> AROUND THE<br />
          WORLD: D11 GAMING <span>PARTNERS</span></h1>
              <p class="text-center">
                Welcome to the revolutionary world of gaming! We at D11 Gaming
                are dedicated to connecting with
                <br />
                the youth audience in the Middle East. Our mission is to provide
                a fun and engaging environment for
                <br />
                gamers to connect, explore, and compete. We offer a wide range
                of services, including community
                <br />
                building, tournament management, brand engagement and marketing.
                Our experienced team of <br />
                professionals can help you create a unique gaming experience for
                your brand.
              </p>
            </div>
            <div class="" id="partners1">
          <h1 class="heading-primary text-center my-5 headTexture">OUR PARTNERS</h1>
          <section class="sectionPartner section-default mt-none mb-none">
            <div class="row">
              <div class="col-sm-6 col-md-4 col-lg-4">
                <div class="square-holder">
                  <Link class="d-flex justify-content-center" href=""><img alt="logo-batelco" src={Batelco} /></Link>
                </div>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-4">
                <div class="square-holder">
                  <Link class="d-flex justify-content-center" href=""><img alt="logo-credimax" src={CrediMax} /></Link>
                </div>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-4">
                <div class="square-holder">
                  <Link class="d-flex justify-content-center" href=""><img alt="logo-jawwal" src={Jawwal} /></Link>
                </div>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-4">
                <div class="square-holder">
                  <Link class="d-flex justify-content-center" href=""><img alt="logo-mobily" src={Mobily} /></Link>
                </div>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-4">
                <div class="square-holder">
                  <Link class="d-flex justify-content-center" href=""><img alt="logo-zain" src={Zain} /></Link>
                </div>
              </div>
              <div class="col-sm-6 col-md-4 col-lg-4">
                <div class="square-holder">
                  <Link class="d-flex justify-content-center" href="">
                   {/* <img alt="blank" src="" />  */}
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </div>
          </div>
        </section>
      </div>
      <div class="container" id="contact1">
        <section class="row submit-form d-flex justify-content-center align-items-center align-content-center">
          <div class="col-8 ">
            {/* <Formik
              type="submit"
              enableReinitialize={true}
              initialValues={frmInitialValues}
              validationSchema={customerCareSchema}
            >
              {({
                // handleSubmit,
                errors,
                values,
                // setFieldValue,
                // validateField,
                // validateForm,
              }) => ( */}
                <div className="row g-3">
                  <h1 class="heading-primary text-center">LETS WORKS TOGETHER!</h1>
          <p class="text-center col-12">Partner with us! Be a part of our success story. Together we can empower the next generation of<br />
          Esports is now communities.Fill the form below and someone from our team will connect with you.</p>
                  
                  <div class="col-md-6">
                    <input
                      type="text"
                      class="form-control contactInput"
                      placeholder="Name"
                      name="name"
                      value={frmInitialValues.name}
                      onChange={(e)=> setFrmInitialValues({...frmInitialValues, name: e.target.value})}
                    />
                    {errorName ? (
                      <div className="error">{errorName}</div>
                    ) : null}
                  </div>
                  <div class="col-md-6">
                    <input
                      type="email"
                      class="form-control contactInput"
                      placeholder="Email"
                      name="email"
                      value={frmInitialValues?.email}
                      onChange={(e)=> setFrmInitialValues({...frmInitialValues, email: e.target.value})}

                    />
                    {errorEmail ? (
                      <div className="error">{errorEmail}</div>
                    ) : null}
                  </div>
                  <div class="col-md-6">
                    <input
                      type="number"
                      class="form-control contactInput"
                      placeholder="Phone"
                      name="phone"
                      value={frmInitialValues?.phone}
                      onChange={(e)=> setFrmInitialValues({...frmInitialValues, phone: e.target.value})}

                      onKeyDown={(e) =>
                        ["e", "E", "-","."].includes(e.key) && e.preventDefault()
                      }
                    />
                    {errorPhone ? (
                      <div className="error">{errorPhone}</div>
                    ) : null}
                  </div>
                  <div class="col-md-6">
                    <input
                      type="text"
                      class="form-control contactInput"
                      placeholder="Company"
                      name="company"
                      value={frmInitialValues?.company}
                      onChange={(e)=> setFrmInitialValues({...frmInitialValues, company: e.target.value})}

                      
                    />
                    {errorCompany ? (
                      <div className="error">{errorCompany}</div>
                    ) : null}
                  </div>
                  <div class="col-12">
                    <textarea
                      class="form-control contactInput"
                      placeholder="Type your message here....."
                      id="floatingTextarea2"
                      style={{ height: "100px" }}
                      value={frmInitialValues?.message}
                      onChange={(e)=> setFrmInitialValues({...frmInitialValues, message: e.target.value})}

                    ></textarea>
                    {errorMessage ? (
                      <div className="error">{errorMessage}</div>
                    ) : null}
                    {/* <MyTextArea class="form-control" name="message" rows="4" placeholder="" /> */}
                  </div>
                  <div class="col-12 d-flex align-items-center justify-content-center">
                    <button
                      type="submit"
                      class="btn1 btn1-submit"
                      onClick={() => handleSubmit()}
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              {/* )}
            </Formik> */}
          </div>
        </section>
      </div>
    </LandingPage>
  );
};

export default Contact;
