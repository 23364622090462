import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import {
    collegeList,
    getAccountProfile,
    getHeaderApi,
    getStatistics,
    requestUpdateProfile,
    favGameList,
    getGamePlayed,
} from "../../Utilities/controller";
import Loader from "../Common/Loader";
import ProfileBG from "../../assets/images/profile-bg.jpg";
import Champion from "../../assets/images/dGames-champion.png";
import Master from "../../assets/images/GoldPremium.png";
import { url } from "../../Utilities/url";
import { useTranslation } from "react-i18next";
import { RWebShare } from "react-web-share";
import { Link } from "react-router-dom";
import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { Tooltip } from "react-bootstrap";
import GameInsightsChart from "../Subscription/GameInsightsChart";
import GameInsights from "../Subscription/GameInsightsSubscriptionModal";
import CollegeCard from "./CollegeCard";
import CollegeModal from "./CollegeModal";
import LevelsModal from "./LevelsModal";
import { useProfileDataContext } from '../HomePage/ProfileContext';

const OtherUserProfileModal = ({ otherProfileModal, setOtherProfileModal, otherProfileUserId}) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(otherProfileModal);
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState([]);
    const [levelProgressBar, setLevelProgressBar] = useState(0);
    const [statisticsVal, setStatisticsVal] = useState([]);
    const [showLevel, setShowLevel] = useState(false);
    const [showCollegeDetails, sethowCollegeDetails] = useState(false);
    const [showInsights, setShowInsights] = useState(false);
    const [showNonSubscribeInsights, setShowNonSubscribeInsights] =
      useState(false);
    const [gameId, setgameId] = useState("");
    const [gameName, setGameName] = useState("");
    // let premiumUser = JSON.parse(localStorage.getItem("premiumUser"));
    const {premiumUser} = useProfileDataContext()

    useEffect(() => {
        fetchAccountProfile();
    }, [])

    const handleClose = () =>{ 
        setShow(false)
        setOtherProfileModal(false)
    };

    const fetchAccountProfile = async () => {
        setLoading(true);
        try {
            let dataPayload = {
                user: otherProfileUserId?.user_id
            }
            const data = await getAccountProfile(dataPayload);
    
            setUserData(data?.data);
            if (data) {
                let currentLevelPoints =
                data?.data?.item?.level?.level?.endPoint -
                (data?.data?.item.level?.level?.startPoint - 1);
        
                let userPointsAsPerLevel =
                data?.data?.item.level?.points -
                (data?.data?.item.level?.level?.startPoint - 1);
        
                let lpVal = (userPointsAsPerLevel * 100) / currentLevelPoints;
                setLevelProgressBar(lpVal);
                getStatisticsList();
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const getStatisticsList = async () => {
        setLoading(true);
        try {
            let data = {
                user_id: otherProfileUserId?.user
            }
            let gameList = await getGamePlayed(data);
            let payload = {
                games: gameList?.data?.data,
                appType: "webapp",
                user: otherProfileUserId?.user
            };
            const res = await getStatistics(payload);
            setStatisticsVal(res?.data?.list);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    const handlerViewInsights = (gameId, gameName) => {
        if (premiumUser) {
            setgameId(gameId);
            setGameName(gameName);
            setShowNonSubscribeInsights(false);
      
            setShowInsights(true);
        } else {
            setShowInsights(false);
            setShowNonSubscribeInsights(true);
        }
    };

    
    return (
        <>
            {loading ? <Loader /> : 
                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    className="auth-modal modal fade "
                    centered
                >
                    <Modal.Body style={{minHeight:'31.25rem'}}>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={handleClose}
                        >
                        </button>
                        <h3 class={"bodyBoldFont mb-5 text-center text-dark"}>{t('header.user_profile')}</h3>
                        <div className="row justify-content-center mt-5">
                            <div class="content">
                                <div className="content-inner">
                                    <div class="row gy-4">
                                        <div class="col-12">
                                            <div class="profileBannerCard card">
                                            <div class="card-img position-relative">
                                                <img src={ProfileBG} class="rounded10" alt="profile-bg" />
                                            </div>
                                            <div class="card-body">
                                                <div class="card-content d-flex justify-content-center">
                                                <div class="profilecard p-1">
                                                    <div class="row px-2">
                                                    <div class="col-8">
                                                        <div class="row">
                                                        <div class="col-12 d-flex flex-column justify-content-center">
                                                            <h4 class="profiletitle">
                                                            {userData?.item?.gamerjiName}{" "}
                                                            <img
                                                                className="icon-20  mx-2"
                                                                src={
                                                                    userData?.item?.isPremium
                                                                        ? 
                                                                    Master
                                                                    : ""
                                                                }
                                                            />
                                                            </h4>
                                                            <div class="row">
                                                            <div class="col-xs-4 col-3 d-flex justify-content-center flex-column align-items-center">
                                                                <span>
                                                                <img
                                                                    className="icon-80"
                                                                    src={
                                                                    url.imageUrl +
                                                                    userData?.item?.level?.level
                                                                        ?.featuredImage?.default
                                                                    }
                                                                    alt="profile-icon"
                                                                />
                                                                </span>
                                                                <p>{userData?.item?.level?.level?.name}</p>
                                                            </div>
                                                            <div class="col-xs-8 col-9">
                                                                <div class="">
                                                                <div class="row align-content-center mb-3 justify-content-between g-2">
                                                                    <div class="col-auto">
                                                                    <label
                                                                        for=""
                                                                        class="d-block h5 text-center"
                                                                    >
                                                                        {userData?.item?.level?.level?.num}
                                                                    </label>
                                                                    <h6 class="text-center d11-profile-level">
                                                                    {t("Profile.level")}
                                                                    </h6>
                                                                    </div>
                                                                    <div class="col-auto">
                                                                    <label
                                                                        for=""
                                                                        class="text-center d-block h5"
                                                                    >
                                                                        {userData?.item?.followers}
                                                                    </label>
                                                                    <h6 class="text-center d11-profile-level">
                                                                        {t("Profile.followers")}
                                                                    </h6>
                                                                    </div>
                                                                    <div class="col-auto">
                                                                    <label
                                                                        for=""
                                                                        class="text-center d-block h5"
                                                                    >
                                                                        {userData?.item?.followings}
                                                                    </label>
                                                                    <h6 class="text-center d11-profile-level">
                                                                        {t("Profile.following")}
                                                                    </h6>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div
                                                                    class="col-12"
                                                                    style={{ position: "relative" }}
                                                                    >
                                                                    <span className="text-center d-block fs-10">
                                                                        {/* {userData?.item?.level?.points} PTS */}
                                                                        {t("Profile.n_pts", {
                                                                        n: userData?.item?.level?.points,
                                                                        })}
                                                                    </span>
                                                                    <div
                                                                        class="progress"
                                                                        role="progressbar"
                                                                        aria-label="Basic example"
                                                                        aria-valuenow="25"
                                                                        aria-valuemin="0"
                                                                        aria-valuemax="100"
                                                                    >
                                                                        <div
                                                                        class="progress-bar "
                                                                        style={{
                                                                            width: `${levelProgressBar}%`,
                                                                        }}
                                                                        ></div>
                                                                    </div>
                                                                    <div class="d-flex justify-content-between mt-2">
                                                                        <p className="fs-10 mb-0">
                                                                        {/* {
                                                                            userData?.item?.level?.level
                                                                            ?.startPoint
                                                                        }{" "} */}
                                                                        {t("Profile.n_pts", {
                                                                            n: userData?.item?.level?.level
                                                                            ?.startPoint,
                                                                        })}
                                                                        </p>
                                                                        <p className="mb-0 fs-10">
                                                                        {/* {
                                                                            userData?.item?.level?.level
                                                                            ?.endPoint
                                                                        }{" "} */}
                                                                        {t("Profile.n_pts", {
                                                                            n: userData?.item?.level?.level
                                                                            ?.endPoint,
                                                                        })}
                                                                        </p>
                                                                    </div>
                                                                    <div class="d-flex justify-content-center">
                                                                        <span
                                                                        class="bodyBoldFont d11-insights-color cursorPointer"
                                                                        onClick={() => setShowLevel(true)}
                                                                        >
                                                                        {t("Profile.view_all_levels")}
                                                                        </span>
                                                                    </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="row mt-4">
                                                        <div className="col-5 text-center">
                                                            <img
                                                            src={
                                                                url.imageUrl +
                                                                userData?.item?.avatar?.img?.default
                                                            }
                                                            style={{
                                                                width: "5.3125rem",
                                                                height: "10rem",
                                                            }}
                                                            alt="avatar1"
                                                            />
                                                        </div>
                                                        <RWebShare
                                                            data={{
                                                            text: t("Profile.join_text", {
                                                                name: "Gamerji",
                                                            }),
                                                            url: `${url.client_base_url}home/user-profile/${userData?.item?._id}`,

                                                            title: t("Profile.join_me", { name: "D11" }),
                                                            }}
                                                            onClick={() =>
                                                            console.info(
                                                                "share successful!",
                                                                `${url.client_base_url}home/user-profile/${userData?.item?._id}`
                                                            )
                                                            }
                                                        >
                                                            <div class="col-md-2 col-3 text-center">
                                                            <Link class="nav-link d-inline-block">
                                                                <span class="nav-link-icon">
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="20"
                                                                    height="20"
                                                                    viewbox="0 0 20 20"
                                                                    fill="none"
                                                                >
                                                                    <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M16.6668 4.79191C16.6668 5.32595 16.5089 5.84807 16.213 6.29262C15.917 6.73717 15.4963 7.08431 15.0036 7.29039C14.5109 7.49648 13.9683 7.5523 13.444 7.45086C12.9197 7.34941 12.4371 7.09522 12.0568 6.72024L7.75849 9.08857C7.9701 9.67913 7.9698 10.3249 7.75765 10.9152L12.0543 13.2819C12.5135 12.8287 13.1194 12.5542 13.7629 12.508C14.4064 12.4617 15.0453 12.6467 15.5645 13.0297C16.0838 13.4127 16.4492 13.9684 16.5951 14.5969C16.741 15.2253 16.6578 15.8852 16.3604 16.4578C16.063 17.0303 15.571 17.4779 14.9729 17.7199C14.3748 17.9619 13.71 17.9825 13.0981 17.7779C12.4863 17.5733 11.9675 17.157 11.6353 16.6039C11.3031 16.0509 11.1792 15.3974 11.286 14.7611L6.72099 12.2469C6.31335 12.5214 5.83871 12.6798 5.34793 12.7051C4.85714 12.7304 4.36872 12.6217 3.93499 12.3907C3.50126 12.1596 3.13857 11.8149 2.88578 11.3935C2.63299 10.9721 2.49964 10.4898 2.5 9.99835C2.50037 9.50692 2.63444 9.02485 2.88785 8.60379C3.14126 8.18273 3.50446 7.83856 3.93854 7.60815C4.37261 7.37774 4.8612 7.26978 5.35194 7.29584C5.84268 7.3219 6.31709 7.48099 6.72432 7.75607L11.286 5.24107C11.2254 4.87222 11.2418 4.49482 11.3342 4.13262C11.4266 3.77043 11.593 3.43128 11.8229 3.13653C12.0527 2.84177 12.3411 2.59779 12.6699 2.41995C12.9987 2.2421 13.3607 2.13423 13.7332 2.10314C14.1057 2.07204 14.4806 2.11839 14.8343 2.23927C15.188 2.36015 15.5129 2.55293 15.7885 2.8055C16.064 3.05806 16.2843 3.36493 16.4355 3.7068C16.5867 4.04866 16.6654 4.41811 16.6668 4.79191Z"
                                                                    fill="white"
                                                                    ></path>
                                                                </svg>
                                                                </span>
                                                            </Link>
                                                            </div>
                                                        </RWebShare>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-5 pt-lg-4 commonTab tournamentTypeTab">
                            <div class="row gx-3 gy-5">
                                <div class="col-lg-12">
                                <div class="pe-lg-3">
                                    <div class="col-12 commonTab profileTypeTab">
                                    <ul
                                        class="nav nav-tabs row"
                                        id="profileTypeTab"
                                        role="tablist"
                                    >
                                        <li class="nav-item col d-grid" role="presentation">
                                        <button
                                            class="nav-link active"
                                            id="statistics-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#statistics-tab-pane"
                                            type="button"
                                            role="tab"
                                            aria-controls="statistics-tab-pane"
                                            aria-selected="true"
                                        >
                                            {t("Profile.statistic")}
                                        </button>
                                        </li>
                                        <li class="nav-item col d-grid" role="presentation">
                                        <button
                                            class="nav-link"
                                            id="collegiate-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#collegiate-tab-pane"
                                            type="button"
                                            role="tab"
                                            aria-controls="collegiate-tab-pane"
                                            aria-selected="false"
                                        >
                                            {t('Profile.collegiate')}
                                        </button>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="earncoinTypeTabContent">
                                        <div
                                        class="tab-pane fade show active"
                                        id="statistics-tab-pane"
                                        role="tabpanel"
                                        aria-labelledby="statistics-tab"
                                        tabindex="0"
                                        >
                                        <div class="row gx-3 gy-5">
                                            {statisticsVal?.length > 0 ? (
                                            statisticsVal?.map((ele, ind) => (
                                                <div className="col-12">
                                                <div
                                                    class="card statisticsCard text-center"
                                                    key={ind}
                                                >
                                                    <div class="card-header py-3 d-flex align-items-center flex-row justify-content-between">
                                                    <h6 class="card-text mb-0 text-white">
                                                        {ele?.game?.name}:{" "}
                                                        <span class="text-purple">
                                                        {userData?.item?.gamerjiName}
                                                        </span>
                                                    </h6>
                                                    <h6 class="card-text mb-0 text-white">
                                                        {t("Profile.rank")}:{" "}
                                                        <span class="text-purple">
                                                        {ele?.avgRates}
                                                        </span>
                                                    </h6>
                                                    <span
                                                        class="bodyBoldFont d11-insights-color"
                                                        onClick={() =>{
                                                        // console.log(ele?.game?.name)
                                                        handlerViewInsights(
                                                            ele?.game?._id,
                                                            ele?.game?.name
                                                        )
                                                        }
                                                        }
                                                    >
                                                        {t("Profile.view_insights")}
                                                    </span>
                                                    </div>

                                                    {/* <img src={ChartImg} alt="charts" /> */}
                                                    <ResponsiveContainer width="100%" aspect={3}>
                                                    <AreaChart
                                                        width={730}
                                                        height={250}
                                                        data={ele.stateatistic}
                                                        margin={{
                                                        top: 10,
                                                        right: 30,
                                                        left: 0,
                                                        bottom: 0,
                                                        }}
                                                    >
                                                        <defs>
                                                        <linearGradient
                                                            id="colorUv"
                                                            x1="0"
                                                            y1="0"
                                                            x2="0"
                                                            y2="1"
                                                        >
                                                            <stop stopColor="#F92C2C" />
                                                            <stop
                                                            offset="1"
                                                            stopColor="#F92C2C"
                                                            stopOpacity={0}
                                                            />
                                                        </linearGradient>
                                                        </defs>
                                                        <XAxis dataKey="date" />
                                                        <YAxis />
                                                        <Tooltip />
                                                        <Area
                                                        type="monotone"
                                                        dataKey="played"
                                                        stroke="#F92C2C"
                                                        fillOpacity={1}
                                                        fill= "linear-gradient(181deg, #F92C2C -6.96%, rgba(249, 44, 44, 0.00) 97.18%)"
                                                        />
                                                    </AreaChart>
                                                    </ResponsiveContainer>
                                                </div>
                                                </div>
                                            ))
                                            ) : (
                                                <h4 className='text-center text-dark'>{t("notFound.no_records_found")}</h4>
                                            )}
                                        </div>
                                        </div>
                                        <div
                                        class="tab-pane fade"
                                        id="collegiate-tab-pane"
                                        role="tabpanel"
                                        aria-labelledby="collegiate-tab"
                                        tabindex="0"
                                        >
                                        <div class="row gx-3">
                                            <div class="row justify-content-center">
                                            <div class="col-12">
                                                {userData?.item?.collegeDetails ? (
                                                <div class="card collegiateCard">
                                                    <div
                                                    class="card-body"
                                                    style={{ paddingTop: "3rem" }}
                                                    >
                                                    <CollegeCard
                                                        collegeDetails={
                                                        userData?.item?.collegeDetails
                                                        }
                                                        sethowCollegeDetails={
                                                        false
                                                        }
                                                    />
                                                    </div>
                                                </div>
                                                ) : (
                                                    <h4 className='text-center text-dark'>{t("notFound.no_records_found")}</h4>
                                                )}
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            }
            {showLevel && (
                <LevelsModal showLevel={showLevel} setShowLevel={setShowLevel} />
            )}
            {showCollegeDetails && (
                <CollegeModal
                    collegeDetails={userData?.item?.collegeDetails}
                    showCollegeDetails={showCollegeDetails}
                    sethowCollegeDetails={sethowCollegeDetails}
                />
            )}
            {showInsights && (
                <GameInsightsChart
                    showInsights={showInsights}
                    setShowInsights={setShowInsights}
                    gameId={gameId}
                    gamename={gameName}
                />
            )}
            {showNonSubscribeInsights && (
                <GameInsights
                    showModal={showNonSubscribeInsights}
                    setShowModal={setShowNonSubscribeInsights}
                />
            )}
        </>
    )
}

export default OtherUserProfileModal