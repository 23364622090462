import React from 'react'
import { Outlet } from 'react-router'

const Base = () => {
  return (
    <div class="content">
      <div className="content-inner">

        <Outlet />
        </div>
    </div>
  )
}

export default Base