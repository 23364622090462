import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { func } from "../../../Utilities/logFunc";
import moment from "moment";

const ApplyPromoCodeModel = ({ showSPC, setShowSPC }) => {
  const [show, setShow] = useState(showSPC);

  const handleClose = () => {
    setShowSPC(false);
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="auth-modal modal fade "
      centered
    >
      <Modal.Body>
        <button
          type="button"
          className="btn-close"
          onClick={handleClose}
          aria-label="Close"
        ></button>

        <form className="row justify-content-center">
          <div
            class="col-12 justify-content-center align-items-center"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <h3 class="bodyBoldFont mb-5 text-center text-uppercase  color-primary-dark-blue">
              Success
            </h3>
            <div className="col-6 mb-5 justify-content-center text-center align-items-center color-primary-dark-blue">
              <span className="text-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4492 0.218814L11.7264 1.68459L13.6369 0.869989C13.9464 0.73891 14.3029 0.907681 14.4017 1.22891L14.9849 3.11802L17.0568 3.15487C17.3918 3.16021 17.654 3.45922 17.6129 3.79366L17.382 5.75984L19.2609 6.64111C19.5452 6.775 19.668 7.11536 19.5351 7.40142C19.279 7.93474 18.8153 8.6222 18.5016 9.15158L19.8622 10.7265C20.0816 10.9813 20.0329 11.3754 19.7592 11.5689L18.1518 12.7107L18.7574 14.7047C18.8556 15.0299 18.6519 15.366 18.322 15.4315L16.3947 15.8175L16.1415 17.8855C16.0984 18.2396 15.7452 18.4686 15.4083 18.3648L13.5291 17.9364L12.461 19.7214C12.288 20.012 11.901 20.0885 11.6321 19.8865L10.0532 18.702L8.35485 19.8967C8.07669 20.0919 7.69806 20.0007 7.53239 19.7057L6.57197 17.9811L4.53389 18.3788C4.18744 18.4452 3.86926 18.1848 3.85723 17.8334L3.68121 15.8982L1.66131 15.429C1.33109 15.3528 1.14248 15.0076 1.24882 14.6867L1.88238 12.8133L0.226558 11.5583C-0.0586009 11.3419 -0.0767906 10.9197 0.184582 10.6787L1.48976 9.26044L0.484572 7.43715C0.320025 7.13927 0.449312 6.76656 0.759097 6.63267L2.56492 5.85323L2.38526 3.77594C2.35448 3.41759 2.65866 3.12111 3.01099 3.15543L4.92818 3.18159L5.604 1.21062C5.71846 0.875896 6.10184 0.726533 6.41135 0.88996L8.16959 1.70569L9.58363 0.182528C9.82346 -0.0765349 10.2326 -0.0557198 10.4492 0.218814ZM6.69287 8.8191L9.07768 11.2162L13.2535 6.56348L14.3835 7.58595L9.13309 13.4361L5.61631 9.9012L6.69287 8.8191ZM10 2.88624C13.9086 2.88624 17.0772 6.07123 17.0772 9.99993C17.0772 13.9286 13.9086 17.1139 10 17.1139C6.09149 17.1139 2.92284 13.9286 2.92284 9.99993C2.92284 6.07123 6.09149 2.88624 10 2.88624ZM10 3.49831C6.42786 3.49831 3.53177 6.40933 3.53177 9.99993C3.53177 13.5905 6.42786 16.5015 10 16.5015C13.5722 16.5015 16.4683 13.5905 16.4683 9.99993C16.4683 6.40933 13.5722 3.49831 10 3.49831Z" fill="#070B28"/>
              </svg>
                <span className="ms-2">Promo Code Applied Successfully</span>
              </span>
            </div>

            <div className="col-6">
              <button
                className="btn btn-primary text-left"
                style={{ width: "100%" }}
                type="button"
                onClick={handleClose}
              >
                DONE
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ApplyPromoCodeModel;
