import React from 'react'

const Main = () => {
  return (
    <main className="d-grid align-content-center auth-main-div">
      {/* <div className="container">
        <h1 className="mb-3">WELCOME TO D11 GAMING. <br />YOUR GAMING HQ.</h1>
        <p>Your first step to becoming a gamer!</p>
      </div> */}
    </main>
  )
}

export default Main