import React, { useCallback, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { func } from '../../../Utilities/logFunc';
import { useDropzone } from 'react-dropzone';
import { routesBackend } from '../../../Utilities/routesBackend';
import { url } from '../../../Utilities/url';
import Close from '../../../assets/images/cross-circle-svg.svg'
import { async } from 'q';
import { ssUpload } from '../../../Utilities/controller';
import { errors } from '../../../Utilities/error';
import Error from '../../Common/Error';
import { useTranslation } from 'react-i18next';
import { eventTracking } from "../../../firebaseAnalytics";
import { events } from "../../../Utilities/appEvents";
const UploadSSModal = ({
    showUploadSSModal,
    setShowUploadSSModal,
    files,
    setFiles,
    setIsSSUpload,
    picture,
    status,
    state,
    scrimData

}) => {
    const [show, setShow] = useState(showUploadSSModal);
    const [uploadSS, setUploadSS] = useState('')
  const {t} = useTranslation()

    const [error, setError] = useState('')
    const onDrop = useCallback(
        (acceptedFiles) => {
            func("accepted", acceptedFiles)
          var formData = new FormData();
          formData.append("file", acceptedFiles[0], acceptedFiles[0].path);
    
          var requestOptions = {
            method: "POST",
            headers: { "x-access-token": localStorage.getItem("token") },
            body: formData,
            redirect: "follow",
          };
     
          fetch(`${url.apiUrl}${routesBackend.uploadSS}`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
            
              setFiles([...files, JSON.parse(result)?.item?.screenShot?.default]);
              func("files", files)
            })
            .catch((error) => {
              func(error)
            //   setUploadLoader("Something went wrong... Please upload again");
            });
        },
        [files]
      );
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: true,
      });
      const removeimage = (file) => {
        let f = files.filter((ele) => ele != file);
        setFiles(f);
      };
    const handleClose = ()=>{
        setShow(false)
        setShowUploadSSModal(false)
        func("ssupload", "dkfjd")
    }
    const handlerSubmit = async()=>{
        let id = state
        try{
            if (files.length > 0) {
                let ssupload = await ssUpload(
                  {
                    pictures: files,
                  },
                  id
                );
                if(ssUpload){
                  if(scrimData!=undefined && scrimData!=null){
                    eventTracking(events.SS_Upload, {
                      GJ_GameName: localStorage.getItem('activeGame'),
                      GJ_GameID: localStorage.getItem('activeGameId'),
                      GJ_PrivateContestID:scrimData._id,
                      GJ_PrivateContestDate:scrimData.date,
                      GJ_PrivateContestTime:scrimData.time
                    });
                    eventTracking(events.Winner_upload, {
                      GJ_GameName: localStorage.getItem('activeGame'),
                      GJ_GameID: localStorage.getItem('activeGameId'),
                      GJ_PrivateContestID:scrimData._id,
                      GJ_PrivateContestDate:scrimData.date,
                      GJ_PrivateContestTime:scrimData.time
                    });
                  }

                    setIsSSUpload(true)
                    setShowUploadSSModal(false)
                    setShow(false)
                }
               
              } else {
                
                if (files.length == 0) {
                  setError(t('error_screenshot_upload'));
                }
              }
        }
        catch(error){
            func(error)
        }
        
        
    }
  return (

    <Modal
    show={show}
    onHide={handleClose}
    backdrop="static"
    keyboard={false}
    className="uploadSSModal modal fade"
    centered
    >
      
        <Modal.Body>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>
          <div class="row justify-content-center">
            <div class="col-12">
              <h3 class="bodyBoldFont mb-0 text-center">{t('private_contest.upload_screenshot')}</h3>
              <div class="row justify-content-center my-4 py-4">
                <div class="col-3">
                  {/* <input type="file" name="" class="form-control form-control-sm" id="" /> */}
                  <button className='btn btn-primary bg-primary-color-dark-blue btn-sm'>
           
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div>
                <b>{t('private_contest.choose_an_image')}</b>
              </div>
            </div>
          </button>
                </div>
                {picture?.length != 0 ? (
        <div className="flex">
          {picture?.map((e) => (
            <img
              style={{ width: "5rem", height: "8.125rem", marginRight: "1.25rem" }}
              src={`${url.imageUrl}${e.url}`}
            />
          ))}
        </div>
      ) : (
        <div className="flex" style={{display:'flex', marginTop: '40px'}}>
          {files.length != 0 ? (
            files?.map((file) => (
              <div style={{position:'relative', margin: '10px'}}>
                <img
                  style={{ width: "5rem", height: "8.125rem", marginRight: "1.25rem" }}
                  src={`${url.imageUrl}${file}`}
                />
       <img src={Close}  className="image-cross-icon"
    onClick={() => removeimage(file)}/>

                {/* <img
                  src={Cancel}
                  style={{
                    width: "15px",
                    height: "15px",
                    marginRight: "20px",
                    marginLeft: "-5px",
                  }}
                  onClick={() => removeimage(file)}
                /> */}
              </div>
            ))
          ) : status == "Canceled" ? (
            ""
          ) : (
            <p
              style={{
                color: "#82878D",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              
            </p>
          )}
          
        </div>
        
      )}
      
              </div>
              {
            error ? <p className='error' style={{textAlign:'center'}}>{error}</p>
            :<></>
          }
              <div class="row justify-content-center">
                <div class="col-lg-6">
                  <div class="d-grid">
    
                    <button  class="btn btn-primary btn-arrow" onClick={handlerSubmit}> {t('private_contest.submit')}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
    </Modal>
  )
}

export default UploadSSModal