import React, { useEffect } from "react";
import LegalityInfo from "./LegalityInfo";
import { useLocation } from "react-router";
import { func } from "../../../Utilities/logFunc";

const Legality = () => {
  //   const location = useLocation();
  //   const { active } = location?.state;
  //   func(active, "lead");
  useEffect(() => {
    func("called");
  }, []);

  return (
    <div class="content">
      <div className="content-inner">
      
      <div class="row gy-4">
        <LegalityInfo />
      </div>
      </div>
    </div>
  );
};

export default Legality;
