export const  kFormatter = (num)=> {
    return Math.abs(Number(num)) >= 1.0e9
      ? (Math.abs(Number(num)) / 1.0e9).toFixed(1) + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(num)) >= 1.0e6
      ? (Math.abs(Number(num)) / 1.0e6).toFixed(1) + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(num)) >= 1.0e3
      ? (Math.abs(Number(num)) / 1.0e3).toFixed(1) + "K"
      : Math.abs(Number(num));
  }

export function convertNumberToMillions(labelValue) {

  if (labelValue < 1e3) return labelValue;
  if (labelValue >= 1e3 && labelValue < 1e6) return +(labelValue / 1e3).toFixed(1) + "K";
  if (labelValue >= 1e6 && labelValue < 1e9) return +(labelValue / 1e6).toFixed(1) + "M";
  if (labelValue >= 1e9 && labelValue < 1e12) return +(labelValue / 1e9).toFixed(1) + "B";
  if (labelValue >= 1e12) return +(labelValue / 1e12).toFixed(1) + "T";
}