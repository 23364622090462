import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import DailyLoginRewards from '../../../Components/Profile/DailyLoginRewards';
import {
  getAds,
  getDailyReward,
  getAccountDailyReward,
} from "../../../Utilities/controller";
import { func } from "../../../Utilities/logFunc";
import { url } from "../../../Utilities/url";
import AdTab from "./AdTab";
import { useProfileDataContext } from "../ProfileContext";
import Diamond from '../../../assets/new-images/coin.svg'
import { useTranslation } from "react-i18next";
import {eventTracking} from "../../../firebaseAnalytics"; 
import {events} from "../../../Utilities/appEvents";
const EarnCoin = ({selectedType}) => {
  var user_data = JSON.parse(localStorage.getItem("profile"));
  const [xsollaList, setXsollaList] = useState([]);
  const [dailyRewardList, setDailyRewardList] = useState([]);
  const [accountDailyReward, setAccountDailyReward] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [stayOpen, setStayOpen] = useState(-1);
  const [showAd, setShowAD] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [updateCoinSuccess, setUpdateCoinSuccess] = useState(false);
  const { profileData, updateProfileData } = useProfileDataContext()
  const {t} = useTranslation()

  useEffect(() => {
    if (updateCoinSuccess) {
      fetchXsollaList(1);
      setStayOpen(-1);
    }
  }, [updateCoinSuccess]);

  const fetchXsollaList = async (pageNo) => {
    try {
      //   console.log("user_data", user_data);

      let data = {
        userID: profileData?.item?.user_id,
        country: user_data?.country?._id,
        pagination: {
          pageNo: pageNo,
          limit: 10,
        },
        platform: "webportal"
      };

      const res = await getAds(data);

      if (res?.data?.data?.[0]?.param?.data) {
        if(pageNo === 1) {
          setXsollaList(res?.data?.data?.[0]?.param?.data);
          setTotalPage(res?.data?.data?.[0]?.param?.totalPages);
        } else {
          setXsollaList([...xsollaList, ...res?.data?.data?.[0]?.param?.data]);
          setTotalPage(res?.data?.data?.[0]?.param?.totalPages);
        }
       
      }
      func(res);
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };

  const fetchDailyRewardList = async (pageNo) => {
    try {
      //   console.log("user_data", user_data);

      let data = {
        skip: 0,
        limit: 100,
        day: 2,
        sortBy: "day",
        sort: "asc",
      };

      const res = await getDailyReward(data);

      if (res?.data?.list) {
        setDailyRewardList(res?.data?.list);
      }
      func(res);
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };

  const fetchAccountDailyRewardList = async () => {
    try {
      const res = await getAccountDailyReward();

      if (res?.data?.success) {
        setAccountDailyReward(res?.data?.item);
      }
      func(res);
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };

  const getCurrentDayItem = (day) => {
    if (day <= accountDailyReward?.currentDay) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if(selectedType === "earncoin-tab"){
      if(profileData?.item?.user_id) {
        fetchXsollaList(1);
      }
      fetchDailyRewardList(1);
      fetchAccountDailyRewardList();
    }
  }, [selectedType]);

  const handleBtn = (item) => {
    setShowAD(true);
    setSelectedItem(item);
  };
  const loadHandler = ()=>{
    let p = page
    setPage(page+1)
   
    fetchXsollaList(p + 1)
  
}
  return (
    <>
      <div class="col-lg-7">
        <div class="pe-lg-3 earn-coin-main">
          {/* <h2 class="h3 bodyBoldFont mb-3">Earn Coins</h2> */}
          <div
            class="accordion earncoinaccordion"
            id="accordionPanelsStayOpenExample"
          >
            {xsollaList.length > 0 ?
              xsollaList.map((item, ind) => (
                <div class="earncoinaccordion-item border border-secondary-subtle rounded-2 mb-2">
                  <h2 class="earncoinaccordion-header">
                    <button
                      class={`accordion-button earn-coin text-white ${
                        ind === stayOpen ? "" : "collapsed"
                      }`}
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#panelsStayOpen-collapseFirst${ind}`}
                      aria-expanded="false"
                      aria-controls="panelsStayOpen-collapseFirst"
                      onClick={() => setStayOpen(ind === stayOpen ? -1 : ind)}
                    >
                      <div class="row">
                        <div class="col-12 d-flex justify-content-center userProfile">
                          <div class="d-flex align-items-center">
                            <span class="icon me-2 d-block">
                              <img
                                class="icon-50"
                                src={url?.imageUrl + item?.brandImage?.default}
                                alt="rush"
                                height={"50px"}
                                width={"50px"}
                              />
                            </span>
                            <div>
                              <h5 class="mb-2">{item?.title}</h5>
                              <div class="mb-0 d-flex align-items-center" style={{ color: '#AAAAAA'}}>
                                {item?.description}{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </button>
                  </h2>
                  <div
                    id={`#panelsStayOpen-collapseFirst${ind}`}
                    class={`accordion-collapse collapse ${
                      ind === stayOpen ? "show" : ""
                    }`}
                  >
                    <div class="earncoinaccordion-body text-white">
                      <div class="row">
                        <div
                          class="col-lg-12 col-md-4"
                          style={{ paddingLeft: "0rem" }}
                        >
                          <div >
                            <h2 class="h5 p-2" style={{ background: "#4C555F", width: '205px' }}>{t('coin_store.how_to_earn_coins')}</h2>
                          </div>
                          {item?.howToEarn.map(
                            (itemHowToEarn, indHowToEarn) => (
                              <p class="px-4 mb-1">
                                {t('coin_store.step')} {indHowToEarn + 1} :{" "}
                                <span>{itemHowToEarn}</span>
                              </p>
                            )
                          )}
                          <div class="d-flex flex-row align-items-center mt-3">
                            <h6 class="mb-0 d-flex align-items-center px-4">
                              {t('coin_store.earn')}{" "}
                              <span class="mx-2">
                              <img style={{height:'14px', width:'15px'}}
                                src={ url.imageUrl +item?.adsEarningCurrency?.img?.default}
                              />
                              </span>{" "}
                              {item?.earningAmmount}
                            </h6>
                            <div class="col">
                              <div class="d-grid">
                                <button
                                  class="btn btn-sm bg-dark text-dark mx-2 my-1"
                                  style={{width: 'fit-content'}}
                                  onClick={() => {handleBtn(item);
                                    eventTracking(events.Earn,{GJ_AdID:item._id,GJ_EarnAmount:item?.earningAmmount});
                                    eventTracking(events.Claim_Reward,{GJ_AdID:item._id,GJ_EarnAmount:item?.earningAmmount});

                                  }}
                                >
                                  {t('coin_store.earn_now')}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
              : <div class="row justify-content-center text-center">
              <div className="col-lg-5">
                <h4>{t('notFound.no_records_found')}</h4>
              </div>
            </div>
            }
            {
                page < totalPage ? 
                <div class="d-flex justify-content-center mt-5">
                  <button
                    class="btn btn-primary col-3 text-center mt-5"
                    onClick={() => {loadHandler()}}
                  >
                    {t('woe.load_more')}
                  </button>
                </div>
                : ""
            }
          </div>
        </div>
      </div>

      <div class="col-lg-5 border-start border-gray-50">
        <div class="ps-lg-3">
        {selectedType === "earncoin-tab" && <DailyLoginRewards />}
          {/* <h2 class="h3 bodyBoldFont mb-3">Daily Login Rewards</h2>
          <div class="card dailyloginCard">
            <div class="card-header">
              <div class="row justify-content-between align-items-center">
                <div class="col-8 d-flex justify-content-around">
                  <h5 class="bodyNormalFont h4 mb-0">Login Rewards</h5>
                  <div class="d-flex justify-content-between">
                    <div class="col text-center">
                      <h5 class="bodyNormalFont h4 mb-0">Day</h5>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="d-flex justify-content-between">
                    <div class="col text-center">
                      <h5 class="bodyNormalFont h4 mb-0">Status</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card dailyLoginDetailsCard mt-1 mb-4">
            <div class="card-body">
              <ul class="list-unstyled mb-0">
                {dailyRewardList.length > 0 &&
                  dailyRewardList.map((item, ind) => (
                    <li class="row justify-content-between align-items-center">
                      <div class="col-8 d-flex justify-content-around">
                        {item?.avatar ? (
                          <h6 class="mb-1 h5 text-white d-flex justify-content-center align-items-center">
                            {item?.avatar?.name}{" "}
                            <img
                              src={url?.imageUrl + item?.avatar?.img?.default}
                              height={"20px"}
                              class="mx-2"
                            />
                          </h6>
                        ) : (
                          <h6 class="mb-1 h5 text-white d-flex justify-content-center align-items-center">
                            +{item?.amount }{"  "} <img src={Diamond} className="icon-20"  />
                          </h6>
                        )}
                        <div class="d-flex justify-content-between">
                          <div class="col text-center">
                            <h5 class="bodyNormalFont mb-0">Day {item?.day}</h5>
                          </div>
                        </div>
                      </div>
                      <div class="col-4 text-center">
                        {getCurrentDayItem(item?.day) && (
                          <div class="d-grid">
                            <button class="btn btn-sm bg-dark text-dark mx-2 my-1 d11-claim-btn">
                              claimed
                            </button>
                          </div>
                        )}
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div> */}
        </div>
      </div>

      {showAd && (
        <AdTab
          showAd={showAd}
          setShowAD={setShowAD}
          item={selectedItem}
          setUpdateCoinSuccess={setUpdateCoinSuccess}
        />
      )}
    </>
  );
};
export default EarnCoin;
