export const events = {
    MENU: {
      All_GAMES: "all_games_menu",
      WOE: "world_of_esprots_menu",
      DIAMOND_STORE: "diamond_store_menu",
      REWARD_STORE: "reward_store_menu",
      COIN_STORE: "coin_store_menu",
      LEADERBOARD: "leaderboard_menu",
      CRM: "crm_menu",
      PREMIUM: "premium_menu",
    },
    LOGIN: {
      LOGIN: "af_login",
      RESET_PIN: "af_reset_pin",
    },
    CONTEST_LIST: {
      JOIN_NOW: "af_contests_join_now",
      JOIN_CONTEST: "af_contests_joined",
    },
    join_contests:"join_contests",
    contest_join_now:"contest_join_now",
    game_sub_types:"game_sub_types",
    games:"games",
    Tournament_JoinNow:"Tournament_JoinNow",
    Tournament_Rules:"Tournament_Rules",
    LOGOUT: "af_logout",
    how_to_join:"how_to_join",
    change_username:"change_username",
    change_username_AddUpdate:"change_username_AddUpdate",
    my_contests:"my_contests",
    host_private_scrim:"host_private_scrim",
    CHANGE_PIN: "af_change_pin",
    DAILY_LOGIN_REWARD: "af_daily_login_reward_claim",
    UNSUBSCRIBE: "af_unscbscribe",
    COUNTRY_CODE: "af_country_code",
    APPLY_SIGNUP_CODE: "af_apply_signup_code",
    APPLY_PROMO_CODE: "af_apply_promo_code",
    REMOVE_SIGNUP_CODE: "remove_signup",
    sign_up_using_mobile: "sign_up_using_mobile",
    register:"register",
    apply_signup:"apply_signup",
    TERM_N_CONDITION: "af_term_condition_signup",
    PRIVACY_POLICY: "af_privacy_policy_signup",
    LOGIN_TAB: "af_login_tab",
    using_email:"using_email",
    register_button:"register_button",
    resend_signup:"resend_signup",
    otp_verified_signup:"otp_verified_signup",
    Sign_in_using_Gmail:"Sign_in_using_Gmail",
    avatar_selected:"avatar_selected",
    LOGIN_OTP_TAB: "af_login_otp",
    REGISTER_TAB: "af_register_tab",
    SIGNUP_RESEND_OTP: "af_signup_resend_otp",
    SIGNUP_OTP_VERIFIED: "af_signup_otp_verified",
    AVATAR_SELECT: "af_avatar_select",
    gamenamebox:"gamenamebox",
    SIGNUP_COMPLATE: "submit_singup_complete",
    BANNER: "banners",
    GAME_ALL_GAMES: "af_game_allgames",
    login_with_phone:"login_with_phone",
    Redeem_Rewards:"Redeem_Rewards",
    resend_button_login:"resend_button_login",
    login_with_email:"login_with_email",
    otp_verified_login:"otp_verified_login",
    FEATURE_TOURNAMENTS: "featured_tournaments",
    TOURNAMENT_TIMING_SELECTION: "Tournament_Timing_Selection",
    Next: "Next",
    JOIN_TOURNAMENT: "Join_Tournament",
    Buy_Packs : "Buy_Packs",
    BUY_BUNDLE: "af_buy_bundle",
    BUY_AVATAR: "af_buy_avatar",
    LEADERBOARD_TODAY_TAB: "af_leaderboard_today_tab",
    SUBSCRIPTION_PLAN: "Subcription_plan",
    PURCHASE: "Subscription_Purchase",
    PAYMENT_STATUS_POPUP: "Payment_Status",
    FREE_TOURNAMENT_NON_PREMIUM: "Free_tournamnet_non_premium",
    FREE_CONTEST_NON_PREMIUM: "Free_Contest_non_premium",
    REQUEST_CALL_BACK: "af_request_callback",
    SELECT_CALL_BACK_TIME: "Select_call_back_time",
    SCHEDULE_CALL: "Schedule_call",
    CHANGE_LANGUAGE:"af_change_language",

    FAV_GAMES:"favourite_game",
    REFERE:"refer_a_friend",
    JOIN_VIA_INVITE_CODE:"af_join_via_invite",
    COLLEGIATE_TAB:"af_collegiate_tab",
    STATISTIC_TAB:"af_statistic_tab",
    VIEW_ALL_LEVELS:"af_view_all_levels",
    PROFILE_EDIT:"af_profile_edit",
    PROFILE_SHARE:"af_profile_share",
    PROFILE_EDIT_SUBMIT:"af_profile_edit_submit",
    Chat:"Chat",
    Raise_a_Complaint:"Raise_a_Complaint",
    Today_Tab:"Today_Tab",
    Monthly_Tab:"Monthly_Tab",
    All_Tab:"All_Tab",
    Weekly_Tab:"Weekly_Tab",
    Search:"Search",
    My_Reward_Tab:"My_Reward_Tab",
    my_profile:"my_profile",
    legality:"legality",
    gamerji_point:"gamerji_point",
    Create_private_contest_Submit:"Create_private_contest_Submit",
    Summary_Submit:"Summary_Submit",
    Roomid_password_update:"Roomid_password_update",
    SS_Upload:"SS_Upload",
    Winner_upload:"Winner_upload",
    private_contest_Edit:"private_contest_Edit",
    Cancel_Contest:"Cancel_Contest",
    private_contest_Share_With_Friends:"private_contest_Share_With_Friends",
    How_to_Create_Contest:"How_to_Create_Contest",
    Create_a_Contest:"Create_a_Contest",
    Tournament_Share_With_Friends:"Tournament_Share_With_Friends",
    Tournament_Ratings:"Tournament_Ratings",
    Contest_Ratings:"Contest_Ratings",
    Tournament_Passsword:"Tournament_Passsword",
    tournaments:"tournaments",
    Contest_winners:"Contest_winners",
    Share_With_Friends:"Share_With_Friends",
    Contest_Passsword:"Contest_Passsword",
    Contest_Room_ID:"Contest_Room_ID",
    private_contest_Join_a_contest:"private_contest_Join_a_contest",
    mycontests_contests:"mycontests_contests",
    mycontests_tournaments:"mycontests_tournaments",
    mycontest_private:"mycontest_private",
    Packs_Tab:"Packs_Tab",
    Bundles_Tab:"Bundles_Tab",
    Avatars_Tab:"Avatars_Tab",
    Buy_Bundle:"Buy_Bundle",
    Pay_now:"Pay_now",
    Buy_Avatar:"Buy_Avatar",
    Reward_Tab:"Reward_Tab",
    My_Reward_Tab:"My_Reward_Tab",
    Redeem_Avatar:"Redeem_Avatar",
    Earn:"Earn",
    Claim_Reward:"Claim_Reward",
Copy_link:"Copy_link",
Coin_Transaction:"Coin_Transaction",
Diamond_Transaction:"Diamond_Transaction",
Ticket_box:"Ticket_box",
View_Insights:"View_Insights",
Members_Tab:"Members_Tab",
College_Tab:"College_Tab",
Premium_pop_up:"Premium_pop_up",
Contest_winners:"Contest_winners",
Tournamnet_Winners:"Tournamnet_Winners"


  };
  