import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import { Modal } from "react-bootstrap";

import {
  getAds,
  getDailyReward,
  getAccountDailyReward,
  updateCoin,
} from "../../../Utilities/controller";
import { func } from "../../../Utilities/logFunc";
import { url } from "../../../Utilities/url";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useProfileDataContext } from "../ProfileContext";

const AdTab = ({ showAd, setShowAD, item, setUpdateCoinSuccess }) => {
  var user_data = JSON.parse(localStorage.getItem("profile"));
  const [show, setShow] = useState(showAd);
  const [timerCount, setTimer] = useState(30);
  const { profileData, updateProfileData } = useProfileDataContext()

  const setCountDownTimer = () => {
    let updatedCount = timerCount - 1;
    if (updatedCount > 0) {
      setTimer(timerCount - 1);
    } else {
      setTimer(0);
      updateCointApiHandle();
    }
  };

  const updateCointApiHandle = async () => {
    try {
      // var country = localStorage.getItem("country");
      let data = {
        userID: profileData?.item?.user_id,
        adId: item?._id,
        country: user_data?.country?._id,
      };
      const res = await updateCoin(data);

      if (res?.data?.success) {
        setUpdateCoinSuccess(true);
        toast.success(res?.data?.data?.[0]?.msg);
        //  (false);
        // setShowAD(false);
      }
      func(res);
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
    }
  };

  useEffect(() => {
    if (timerCount !== 0) {
      setTimeout(() => {
        setCountDownTimer();
      }, 1000);
    }
  }, [timerCount]);

  const handleClose = () => {
    setShowAD(false);
    setShow(false);
  };

  useEffect(() => {}, []);
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      className="auth-modal modal fade "
      centered
    >
      <Modal.Body>
        {timerCount != 0 ? (
          <button type="button" className="btn-timer btn btn-sm bg-dark text-dark" disabled aria-label="Close">
            {timerCount}
          </button>
        ) : (
          <button
            type="button"
            className="btn-close"
            onClick={handleClose}
            aria-label="Close"
          ></button>
        )}
        <div className="row mt-5">
          <div className="col-sm-12">
            <div class="card popularVideoCard">
              <div class="card-body">
                <div class="card-img position-relative">
                  <div class="card-video">
                    <iframe
                      class="rounded10"
                      width="100%"
                      style={{ height: "400px" }}
                      src={item?.link}
                      title={item?.title}
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AdTab;
