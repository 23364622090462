import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

import { getJoinUserContestList } from "../../../Utilities/controller";
import { func } from "../../../Utilities/logFunc";
import { url } from "../../../Utilities/url";
import NotFound from "../../..//assets/images/noContestFound.png";
import moment from "moment";
import WinningBreakUp from "../Tournament/WinningBreakUp";
import Loader from "../../Common/Loader";
import Copy from '../../../assets/images/copy-icon-svg.svg';
import { useProfileDataContext } from "../ProfileContext";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {eventTracking} from "../../../firebaseAnalytics"; 
import {events} from "../../../Utilities/appEvents";
const ContestList = () => {
  const navigate = useNavigate();
  const [contestList, setContestList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const gameName = localStorage.getItem("activeGame");
  const gameId = localStorage.getItem("activeGameId");
  const [showWinningModal, setShowWinningModal] = useState(false);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const { profileData, updateProfileData,premiumUser } = useProfileDataContext()
  const { t } = useTranslation();
  // console.log(profileData, 'profileData')
  // const premiumUser = JSON.parse(localStorage.getItem("premiumUser"))
  const getContestList = async (pageNo) => {
    setLoading(true);
    try {


      let data = {
        search: {
          game: gameId,
          user: profileData?.item?.user_id,
          status: [
            "waiting",
            "started",
            "inprogress",
            "inProcess",
            "review",
            "cancelled",
            "completed",
          ],
        },
        pagination: {
          pageNo: pageNo,
          recordPerPage: 9,
          sortBy: "dateTime",
          sortDirection: "desc",
        },
      };

      const res = await getJoinUserContestList(data);

      if (res?.data?.data) {
        setContestList([...contestList, ...res?.data?.data]);

        setTotalPage(res?.data?.totalRecord);

      }
      func(res);
      setLoading(false);
    } catch (error) {
      func("avatar 13", error?.response?.data?.errors?.[0]?.msg);
      setLoading(false);
    }
  };

  useEffect(() => {
    getContestList(1);
  }, []);

  const loadMoreBtnClick = () => {
    let totalCount = parseInt(page) + 1;
    setPage(totalCount);
    getContestList(totalCount);
  };

  const findPercentage = (data) => {
    let totalPlayer = data?.totalPlayers || 0;
    let totalPlayerJoined = data?.totalJoinedPlayers || 0;

    let percentage = (totalPlayerJoined / totalPlayer) * 100;

    return percentage + "%";
  };

  const handleClickContestCard = (item) => {
    eventTracking(events.mycontests_contests, {
      GJ_GameName: item.contestData?.gameData[0]?.name,
      GJ_GameID: item.contestData?.gameData[0]?._id,
      GJ_ContestID: item?.contest})
     navigate("/home/contest-details/" + item?.contestData?._id);
  };
  const handlerWinning = (data) => {
    setSelectedTournament(data);
    try{
    eventTracking(events.Contest_winners, {
      GJ_GameName: localStorage.getItem("activeGame"),
      GJ_GameID: localStorage.getItem("activeGameId"),
      GJ_ContestID: data._id,
      GJ_GameTypeID:data.gameTypeAry._id,
    });}catch(e){}
    setShowWinningModal((prev) => true);
  };
  return (
    <>
      <>

        {
          contestList.length > 0 ? (
            <div class="row gx-3 gy-5">
              {contestList.map((item, ind) => (
                <div
                  key={ind}
                  class="col-lg-4 col-md-6"
                  onClick={() => handleClickContestCard(item)}
                >
                  <div class="card tournametParticipationCard tournamentSoloCard contestTournamentSoloCard mb-5">
                    <div class="card-header">
                      <div class="card-status">{item?.contestData?.status}</div>
                      <h4 class="card-title mb-0">{item?.contestData?.title}</h4>
                      <span class="rulesLink fs-5">
                        {t("contest.id")}: {item?.contestData?.shortCode}
                      </span>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                          <div class="card-content scrim-black">
                            <div class="row justify-content-between py-2 bg-dark text-black">
                              <div class={`${item?.contestData?.titles[1]?.isSelection && item?.contestData?.titles[1]?.name && item?.contestData?.titles[1]?.value ? "col-3": "col-4"}`}>
                                <label for="" class="d-block h6">
                                  {t("contest.date")}
                                </label>
                                <h6 class="bodyBoldFont mb-0">
                                  {moment(item?.contestData?.date).format(
                                    "DD/MM/YYYY"
                                  )}
                                </h6>
                              </div>
                              <div class={`${item?.contestData?.titles[1]?.isSelection && item?.contestData?.titles[1]?.name && item?.contestData?.titles[1]?.value ? "col-3 dividersX": "col-4 dividersX"} `}>
                                <label for="" class="d-block h6">
                                  {t("contest.time")}
                                </label>
                                <h6 class="bodyBoldFont mb-0">
                                  {moment(item?.contestData?.time).format(
                                    "hh:mm A"
                                  )}
                                </h6>
                              </div>
                              {item?.contestData?.titles[0]?.isSelection && item?.contestData?.titles[0]?.name && item?.contestData?.titles[0]?.value && 
                                <div class= {`${item?.contestData?.titles[1]?.isSelection && item?.contestData?.titles[1]?.name && item?.contestData?.titles[1]?.value ? "col-3 dividersRight": "col-4"} `} >
                                  <label for="" class="d-block h6 text-ellipsis_contest" >
                                    {/* {item?.contestData?.titles[0]?.name} */}
                                    {
                                      // item?.contestData?.titles[0]?.name?.length > 9
                                      //   ? item?.contestData?.titles[0]?.name?.substring(
                                      //       0,
                                      //       9 - 3
                                      //     ) + "..."
                                      //   :
                                      item?.contestData?.titles[0]?.name}
                                  </label>
                                  <h6 class="bodyBoldFont mb-0 text-ellipsis_contest" >
                                    {/* {item?.contestData?.titles[0]?.value} */}
                                    {
                                      // item?.contestData?.titles[0]?.value?.length > 9
                                      //   ? item?.contestData?.titles[0]?.value?.substring(
                                      //       0,
                                      //       9 - 3
                                      //     ) + "..."
                                      //   : 
                                      item?.contestData?.titles[0]?.value}
                                  </h6>
                                </div>
                              }
                              {item?.contestData?.titles[1]?.isSelection && item?.contestData?.titles[1]?.name && item?.contestData?.titles[1]?.value && 
                                <div class={`${item?.contestData?.titles[1]?.isSelection && item?.contestData?.titles[1]?.name && item?.contestData?.titles[1]?.value ? "col-3": "col-4"}`}>
                                  <label for="" class="d-block h6 text-ellipsis_contest" >
                                    {/* {item?.contestData?.titles[1]?.name} */}
                                    {
                                      // item?.contestData?.titles[1]?.name?.length > 9
                                      //   ? item?.contestData?.titles[1]?.name?.substring(
                                      //       0,
                                      //       9 - 3
                                      //     ) + "..."
                                      //   :
                                      item?.contestData?.titles[1]?.name}
                                  </label>
                                  <h6 class="bodyBoldFont mb-0 text-ellipsis_contest" >
                                    {/* {item?.contestData?.titles[1]?.value} */}
                                    {
                                      // item?.contestData?.titles[1]?.value?.length > 9
                                      //   ? item?.contestData?.titles[1]?.value?.substring(
                                      //       0,
                                      //       9 - 3
                                      //     ) + "..."
                                      //   :
                                      item?.contestData?.titles[1]?.value}
                                  </h6>
                                </div>
                              }
                            </div>
                            <div class="row justify-content-between py-2 mt-1 scrim">
                              <div class="col-4">
                                <label for="" class="d-block h6">
                                  {t("contest.prize_pool")}
                                </label>
                                <div class="d-flex align-items-center poolPrizeBox">
                                  <span class="icon me-2">
                                    <img
                                      className="icon-16"
                                      src={
                                        url?.imageUrl +
                                        item?.contestData?.currency?.[0]
                                          ?.outCurrency?.img?.default
                                      }
                                      alt=""
                                    />
                                  </span>
                                  <h6 class="bodyBoldFont mb-0">
                                    {item?.contestData?.prizePool}
                                  </h6>
                                </div>
                              </div>
                              {/* <div class="col-4 dividersX d-flex">
                            <div class="mx-auto">
                              <label for="" class="d-block h6">
                                Per Kill
                              </label>
                              <div class="d-flex align-items-center poolPrizeBox">
                                <span class="icon me-2">
                                  <img
                                    src={
                                      url?.imageUrl +
                                      item?.contestData?.currency?.[0]
                                        ?.outCurrency?.img?.default
                                    }
                                    alt=""
                                  />
                                </span>
                                <h6 class="bodyBoldFont mb-0">
                                  {item?.contestData?.killPoints || "-"}
                                </h6>
                              </div>
                            </div>
                          </div> */}
                              {item?.contestData?.winningModel == "perKill" ? (
                                <div class="col-4 dividersX d-flex">
                                  <div class="">
                                    <label for="" class="d-block h6">
                                      {t("contest.per_kill")}
                                    </label>
                                    <div class="d-flex align-items-center poolPrizeBox">
                                      <span class="icon me-2">
                                        {item?.contestData?.currency[0]
                                          ?.outCurrency?.code == "INR" ? (
                                          "₹"
                                        ) : (
                                          <img
                                            src={
                                              url.imageUrl +
                                              item?.contestData?.currency[0]
                                                ?.outCurrency?.img?.default
                                            }
                                            alt=""
                                          />
                                        )}
                                      </span>
                                      <h6 class="bodyBoldFont mb-0">
                                        {" "}
                                        {item?.contestData?.killPoints}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="col-4 dividersX"
                                  onClick={() =>
                                    handlerWinning(item?.contestData)
                                  }
                                >
                                  <label for="" className="d-block h5">
                                    {t("contest.winners")}
                                  </label>
                                  <div className="d-flex align-items-center poolPrizeBox">
                                    <h5 className="bodyBoldFont mb-0">
                                      {item?.contestData?.totalWinners}
                                    </h5>
                                    <i className="fas fa-chevron-down mx-2"></i>
                                  </div>
                                </div>
                              )}
                              <div class="col-4 d-flex">
                                <div class="">
                                  <label for="" class="d-block h6">
                                    {t("contest.join_using")}
                                  </label>
                                  <div class="d-flex align-items-center useCoinBox">
                                    <span class="icon me-2">
                                      {
                                        item?.contestData?.entryFee > 0 && !premiumUser ?
                                          <img
                                            class="icon-16"
                                            src={
                                              url?.imageUrl +
                                              item?.contestData?.currency?.[0]
                                                ?.inCurrency?.img?.default
                                            }
                                            alt=""
                                          /> : ""
                                      }

                                    </span>
                                    <h6 class="bodyBoldFont mb-0">
                                      {item?.contestData?.entryFee > 0 && !premiumUser ? item?.contestData?.entryFee : t("tournaments.free")}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row mt-2">
                              <div class="col-12">
                                <div
                                  class="progress"
                                  role="progressbar"
                                  aria-label="Basic example"
                                  aria-valuenow="25"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  <div
                                    class="progress-bar bg-dark"
                                    style={{
                                      width: findPercentage(item?.contestData),
                                    }}
                                  ></div>
                                </div>
                                <div class="d-flex justify-content-between mt-2">
                                  <h5>
                                    {t("contest.players_remaining", {
                                      players:
                                        item?.contestData?.totalPlayers -
                                        item?.contestData?.totalJoinedPlayers,
                                    })}
                                  </h5>
                                  <h5>
                                    {t("contest.players_joined", {
                                      players:
                                        item?.contestData?.totalJoinedPlayers,
                                    })}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer text-white">
                      <div class="bg-error pt-2 pb-1 box">
                        <div class="row justify-content-center py-2 mt-1">
                          <div class="col-4 text-left border-end px-4 ">
                            <label for="" class="d-block h6 mb-1">
                              {t("contest.room_id")}
                            </label>
                            <h6 class="bodyBoldFont mb-0 d-flex align-items-center justify-content-left">
                              <span id="copyText">
                                {item?.contestData?.roomId?.length > 25 ? (
                                <marquee>{item?.contestData?.roomId}</marquee>
                                ) : (
                                  item?.contestData?.roomId || "-"
                                )}
                              </span>
                              {item?.contestData?.roomId && (
                                <span class="icon ms-2" onclick="copyText();">
                                  <img
                                    className="icon-18"
                                    src="/assets/images/copy-icon-svg.svg"
                                    alt=""
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        item?.contestsList?.[0]?.roomId
                                      );
                                      toast.info(t("info_Copied"), {
                                        // position: "top-left",
                                        autoClose: 2000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                      });
                                    }}
                                  />
                                </span>
                              )}
                            </h6>
                          </div>
                          {/* {console.log(item?.column)} */}
                          <div class="col-4 text-left px-4" style={{ borderRight: `${item?.column ? "1px solid #fff" : ""}` }}>
                            <label for="" class="d-block h6 mb-10">
                              {t("contest.password")}
                            </label>
                            <h6 class="bodyBoldFont mb-0 d-flex align-items-center justify-content-left">
                              <span id="copyText1">
                                {item?.contestData?.roomPassword?.length > 25 ? (
                                <marquee>{item?.contestData?.roomPassword}</marquee>
                                ) : (
                                  item?.contestData?.roomPassword || "-"
                                )}
                              </span>
                              {item?.contestData?.roomPassword && (
                                <span class="icon ms-2" onclick="copyText1();">
                                  <img
                                    className="icon-18"
                                    src="/assets/images/copy-icon-svg.svg"
                                    alt=""
                                    onClick={() => {
                                      navigator.clipboard.writeText(
                                        item?.contestsList?.[0]?.roomId
                                      );
                                      toast.info(t("info_Copied"), {
                                        // position: "top-left",
                                        autoClose: 2000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                      });
                                    }}
                                  />
                                </span>
                              )}
                            </h6>
                          </div>
                          {
                            item?.column ?
                              <div class="col-2 text-left" >
                                <label for="" class="d-block h6 mb-10">
                                  Slot
                                </label>
                                <h6 class="bodyBoldFont mb-0 d-flex align-items-center justify-content-left">

                                  {item?.column}
                                </h6>
                              </div>
                              : ""
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              {contestList?.length < totalPage && (
                <div class="d-flex justify-content-center mt-5">
                  <button
                    class="btn btn-primary col-3 text-center mt-5"
                    onClick={() => loadMoreBtnClick()}
                  >
                    Load More
                  </button>
                </div>
              )}
            </div>
          ) : (
            <>
              <div class="text-center">
                <img className="img-fluid" src={NotFound} alt="" />
              </div>
            </>
          )}
      </>
      {showWinningModal && (
        <WinningBreakUp
          showWinningModal={showWinningModal}
          setShowWinningModal={setShowWinningModal}
          selectedTournament={selectedTournament}
        />
      )}
      {isLoading ?  <Loader /> : <></>}
    </>
  );
};

export default ContestList;
