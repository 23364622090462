import React from "react";
import { Outlet } from "react-router";

const WOEMain = () => {
  return (
    <div class="content">
      <div className="content-inner">

      <Outlet />
</div>
    </div>
  );
};

export default WOEMain;
