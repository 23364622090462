import React, { useEffect } from "react";
import LeaderBoardInfo from "./LeaderBoardInfo";
import { useLocation } from "react-router";
import { func } from "../../../Utilities/logFunc";

const LeaderBoard = () => {
  const location = useLocation()

  return (
    <div class="content">

<div className="content-inner">
      <div class="row gy-4">
        <LeaderBoardInfo />
      </div>
      </div>
    </div>
  );
};

export default LeaderBoard;
